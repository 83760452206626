import React from "react";
import styles from "./ProfileListingCard.module.scss";

type Props = {
  children: React.ReactNode;
};

const ProfileListingCard = ({ children }: Props) => {
  return <div className={styles["profile-listing-card"]}>{children}</div>;
};

export default ProfileListingCard;
