import { get, post, put } from "..";
import { CompanyType } from "../../types/employer/company.type";
import { ContentType } from "../../types/content.type";

export const getCompany = async () => {
  return get<ContentType<CompanyType[]>>("/employer/company");
};

export const createCompany = async (data: object) => {
  return post<ContentType<CompanyType[]>>(`/employer/company`, data);
};

export const updateCompany = async (id: number | undefined, data: object) => {
  return put<ContentType<CompanyType[]>>(`/employer/company/${id}`, data);
};

export const getInvoices = async (companyId: number) => {
  return get<any[]>(`employer/company/invoice/${companyId}`);
};

export const downloadInvoice = (invoiceId: number) => {
  return `${process.env.REACT_APP_API_URL}/employer/company/invoice/download/${invoiceId}`;
};
