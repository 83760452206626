import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import styles from "./SignUpCard.module.scss";
import Card from "../../common/Card";
import { AuthType } from "../../../types/auth/auth.type";
import {
  signUpByEmployer,
  signUpByJobSeeker,
} from "../../../store/actions/authAction";
import SignUpPersonalForm from "../../forms/SignUpPersonalForm";
import SignUpBusinessForm from "../../forms/SignUpBusinessForm";
import Button from "../../common/Button";
import { setAuthError } from "../../../store/slices/authSlice";

const SignUpCard = () => {
  const dispatch = useAppDispatch();
  const authType = useAppSelector((state: any) => state.ui.authType);
  const navigate = useNavigate();
  const isAuthenticated = useAppSelector(
    (state: any) => state.auth.isAuthenticated,
  );
  const [isBusiness, setIsBusiness] = useState<boolean>(authType === "hotel");
  const error = useAppSelector((state: any) => state.auth.error);

  const onSubmit = async (values: AuthType) => {
    if (isBusiness) {
      dispatch(signUpByEmployer(values));
    } else {
      dispatch(signUpByJobSeeker(values));
    }
  };

  useEffect(() => {
    dispatch(setAuthError(null));
  }, [isBusiness]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate({ pathname: `/de/profile` }, { replace: true });
    }
  }, [isAuthenticated]);
  const { t } = useTranslation();
  return (
    <Card
      className={styles.root}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      withClose
    >
      <div className={styles.header}>
        <div className={styles.left}>
          <div className={styles.title}>
            {t("signUp")} - {isBusiness ? "Business" : "Personal"}
          </div>
          <div className={styles.subtitle}>
            Sign in with your
            <Button
              variant="transparent"
              className={styles.button}
              onClick={() => setIsBusiness(false)}
            >
              Personal
            </Button>{" "}
            {t("or")}
            <Button
              variant="transparent"
              className={styles.button}
              onClick={() => setIsBusiness(true)}
            >
              Business
            </Button>
            {t("account")}
          </div>
        </div>
      </div>
      <div className={styles.error}>{error}</div>

      {isBusiness ? (
        <SignUpBusinessForm isBusiness={isBusiness} onSubmit={onSubmit} />
      ) : (
        <SignUpPersonalForm isBusiness={isBusiness} onSubmit={onSubmit} />
      )}
      <div className={styles.additional} />
    </Card>
  );
};

export default SignUpCard;
