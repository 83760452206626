import { post } from "../index";

export const postJobSeekerAuthLogin = async (data: any) => {
  return post<any>("/job-seeker/auth/login", data);
};

export const postJobSeekerSocialLogin = async (provider: string, data: any) => {
  return post<any>(`/job-seeker/auth/social-login/${provider}`, data);
};

export const postJobSeekerAuthSignUp = async (data: any) => {
  return post<any>("/job-seeker/auth/register", data);
};

export const postJobSeekerAuthForgotPassword = async (data: any) => {
  return post<any>("/job-seeker/auth/forgot-password", data);
};

export const postJobSeekerAuthResetPassword = async (data: any) => {
  return post<any>("/job-seeker/auth/reset-password", data);
};
