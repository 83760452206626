import React from "react";

const BuildingIcon: React.FC = () => {
  return (
    <>
      <path
        d="M8.38086 12L10.7909 14.42L15.6209 9.57996"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7509 2.44995C11.4409 1.85995 12.5709 1.85995 13.2709 2.44995L14.8509 3.80995C15.1509 4.06995 15.7109 4.27995 16.1109 4.27995H17.8109C18.8709 4.27995 19.7409 5.14995 19.7409 6.20995V7.90995C19.7409 8.29995 19.9509 8.86995 20.2109 9.16995L21.5709 10.7499C22.1609 11.4399 22.1609 12.5699 21.5709 13.2699L20.2109 14.8499C19.9509 15.1499 19.7409 15.7099 19.7409 16.1099V17.8099C19.7409 18.8699 18.8709 19.7399 17.8109 19.7399H16.1109C15.7209 19.7399 15.1509 19.9499 14.8509 20.2099L13.2709 21.5699C12.5809 22.1599 11.4509 22.1599 10.7509 21.5699L9.17086 20.2099C8.87086 19.9499 8.31086 19.7399 7.91086 19.7399H6.18086C5.12086 19.7399 4.25086 18.8699 4.25086 17.8099V16.0999C4.25086 15.7099 4.04086 15.1499 3.79086 14.8499L2.44086 13.2599C1.86086 12.5699 1.86086 11.4499 2.44086 10.7599L3.79086 9.16995C4.04086 8.86995 4.25086 8.30995 4.25086 7.91995V6.19995C4.25086 5.13995 5.12086 4.26995 6.18086 4.26995H7.91086C8.30086 4.26995 8.87086 4.05995 9.17086 3.79995L10.7509 2.44995Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
};

export default BuildingIcon;
