import { Outlet } from "react-router-dom";
import ProfileLayout from "../layouts/ProfileLayout";

const ProfilePage = () => {
  return (
    <ProfileLayout>
      <Outlet />
    </ProfileLayout>
  );
};

export default ProfilePage;
