import { useRef, useEffect } from "react";
import { useFormik } from "formik";
import { addMessage } from "api/chat/messages";
import Button from "../../common/Button";
import AbIcon from "../../../assets/icons/Ab.svg";
import AttachIcon from "../../../assets/icons/attach-circle.svg";
import PaperclipIcon from "../../../assets/icons/paperclip.svg";
import SendIcon from "../../../assets/icons/send.svg";
import styles from "./Conversation.module.scss";

const TextEditor = ({ fullName, chatId, handleMessage }: any) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const formik = useFormik({
    initialValues: {
      message: "",
    },
    onSubmit: (values) => {
      addMessage(values.message, chatId).then((response) => {
        handleMessage({
          title: response?.data?.message?.title,
          content: response?.data?.message?.content,
        });
        values.message = "";
      });
    },
  });
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
      textareaRef.current.select();
    }
  }, []);

  return (
    <div className={styles.editor}>
      <div className={styles.editor__top}>
        <span className={styles.editor__to}>To:</span>
        <span className={styles.editor__name}>{fullName}</span>
      </div>
      <div className={styles.editor__bottom}>
        <textarea
          className={styles.editor__textarea}
          ref={textareaRef}
          value={formik.values.message}
          onChange={formik.handleChange}
          name="message"
        />
        <div className={styles.editor__buttons}>
          <div className={styles.editor__tools}>
            <div
              className={styles["editor__tool-item"]}
              style={{ display: "none" }}
            >
              <img src={AbIcon} alt="Ab" />
            </div>
            <div
              className={styles["editor__tool-item"]}
              style={{ display: "none" }}
            >
              <img src={AttachIcon} alt="attach" />
            </div>
            <div
              className={styles["editor__tool-item"]}
              style={{ display: "none" }}
            >
              <img src={PaperclipIcon} alt="paperclip" />
            </div>
          </div>
          <Button variant="primary" onClick={formik.submitForm}>
            <div className={styles.editor__send}>
              <img src={SendIcon} alt="send" />
              <span style={{ marginLeft: "8px" }}>Send</span>
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TextEditor;
