const germanTranslation = {
  translation: {
    hello: "Hallo",
    reviews: "Bewertungen",
    seeTestimonial: "Bewertungen",
    hourly: "Stunde",
    monthly: "Monat",
    filterSearchTitle: "Search by title or keyword",
    filterSearchLocation: "Search by location",
    datePosted: "Veröffentlichung",
    location: "Adresse/Die Anschrift",
    livingRoomPhotos: "Wohnraumbilder",
    removeFromFavourites: "Remove From Favourites",
    saveForLater: "Speichern für später",
    businessManager: "Business Manager",
    appliedTo: "Applied To",
    uploadedOn: "Uploaded on",
    invoice: "Rechnungen",
    date: "Datum",
    amount: "Menge",
    paid: "Bezahlt",
    learnMore: "Mehr",
    seeMore: "Mehr",
    profile: "Profileinstellungen",
    signOut: "Ausloggen",

    // ======== HERO SECTION =========
    heroTitle: "Leben und Arbeiten unter einem Dach",
    heroDescription:
      "Wir bringen Hotels und Studierende zusammen, denn die eine Seite hat, was die andere Seite sucht: offene Stellen und freie Unterkünfte. dafür bekommen sie:  engagierte, flexible und zuverlässige Mitarbeiter.",
    heroHotelButton: "Für Hotels",
    heroPersonButton: "Für Personen",
    heroReviewsTitle: "Die Menschen lieben es",
    heroStatDescription: "Students who have found a job and housing",

    // ======== JOBS REEL SECTION =========
    jobsReelTitle: "Finde deinen Job und Unterkunft",

    // ======== SEARCh SECTION =========
    searchSectionTitle: "Vorteile für Mitarbeiter",
    affordable: "Preiswerte Unterkunft",
    flexibleHours: "Sparen durch weiteren Benefits",
    otherBenefits: "Other Benefits",
    freeMeals: "kostenlose Verpflegung",
    noCommuteTime: "Null Pendelzeit",
    highSecurty: "Höhe Sicherheit",
    andManyMore: "Andere Vorteile ..",
    searchInputPlaceholder: "Nach stichwort suchen",

    // ======== STEPS SECTION ========
    stepsSectionTitle: "Vorteile für Hotels",
    stepsSectionSubtitle:
      "Innerhalb kurzer Zeit wird die von Ihnen eingestellte Anzeige in den Suchergebnissen vieler Personen erscheinen..",
    stepsOneTitle: "Registriere Dein Unternehmen",
    stepsOneDescription: 'Klicke auf den Button "Jetzt Starten" unten',
    stepsTwoTitle: "Hotel Hinzufügen ",
    stepsTwoDescription: "Ein Hotel hinzufügen",
    stepsThreeTitle: "Anzeige Erstellen",
    stepsThreeDescription: "Füge deine Hoteljobs hinzu",
    stepsFourTitle: "Angebote Erhalten",
    stepsFourDescription: "Behalte eingehende Bewerbungen im Blick",
    stepsFiveTitle: "Starte den Rekrutierungsprozess",
    stepsFiveDescription: "Sortiere Kandidaten und stelle den Passenden ein!",

    // ======== FAQ SECTION =========
    faqs: "FAQs",
    studentHeroTitle: "Leben und Arbeiten unter einem Dach",
    faqTitle: "Oft gestellte Fragen",
    faqQuestion: "Is there a free trial available?",
    faqAnswer:
      " We help hotels to offer work and accommodation to students in a simple way. On the other hand we encourage students to search for a job and a place to live simultaneously on our platform.",
    faqFooterQuestion:
      "Hast du noch eine Frage oder möchtest du Partner werden?",

    // ======= BUTTONS =======
    contactUs: "Kontaktiere uns",
    applyNow: "Jetzt bewerben",
    viewDetails: "Mehr Erfahren",
    searchJobAndHousing: "Search job & housing",
    save: "Speichern",
    edit: "Speichern",
    delete: "Löschen",
    publish: "Veröffentlichen",
    pause: "Pause",
    addListing: "Neue Anzeige",
    startConversation: "Chat beginnen",
    downloadInvoice: "Download Rechnung",
    getStarted: "Jetzt Starten",

    // ======== STUDENT PROFILE PAGES =========
    profileSettings: "Profileinstellungen",
    appliedJobs: "Beworbene Stellen",
    savedJobs: "Gespeicherte Jobs",
    noJobs: "Sie haben den Job noch nicht gespeichert",

    // ======== EMPLOYER PROFILE PAGES =========
    bussinesInformation: "Business Information",
    myHotels: "Meine Hotels",
    myJobs: "Meine Anzeigen",
    recievedApplications: "Eingegangene Bewerbungen",
    invoices: "Rechnungen",
    pricing: "Preise",

    // ======== PROFILE SETTINGS =========
    firstName: "Vorname",
    lastName: "Nachname",
    changePassword: "Passwort ändern",
    email: "Email",
    telephone: "Telefonnummer",
    confirmPassword: "Passwort bestätigen",
    address: "Adresse",
    password: "Passwort",
    enterPassword: "Passwort eingeben",
    enterEmail: "E-mail eingeben",

    // ======== BUSINESS INFORMATION =========
    companyName: "Unternehmensname",
    businessPhoneNumber: "Unternehmens Telefonnummer",
    businessEmail: "Geschäfts E-mail",
    taxId: "Steuer ID",
    headOffice: "Hauptsitz",
    addHotel: "Hotel Hinzufügen",
    name: "Name",
    branchHotelName: "Name der Hotel-Filiale",
    enterBranchHotelName: "Name der Hotel-Filiale eingeben",
    branchHotelAddress: "Adresse der Hotel-Filiale",
    enterBranchHotelAddress: "Adresse der Hotel-Filiale",
    hotel: "hotel",
    updateHotel: "Speichern",
    editHotel: "Hotel-Filiale bearbeiten",

    // listing
    editListing: "Edit Listing",
    vacancyName: "Name der Stellenanzeige",
    vacancyNameLabel: "Name der vakanten Position",
    selectHotel: "Hotel auswählen",
    roomCost: "Zimmer Kosten",
    negotiablePrice: "Ankreuzen wenn der Preis für das Zimmer verhandelbar ist",
    startChat: "Student Can Start Communication When applies on job",
    salaryRate: "Stundenlohn",
    enterSalaryRate: "Stundenlohn",
    expiration: "Ablaufsdatum",
    description: "Beschreibung hinzufügen",
    addPhotos:
      "Füge die Bilder vom Wohnraum hinzu damit die Studenten es sehen können",
    addPhoto: "+ Bild hinzufügen",
    updateListing: "Anzeige veröffentlichen",
    publishListing: "Anzeige veröffentlichen",

    // page
    businessProfile: "Unternehmensprofil",
    personProfile: "Personenprofil",
    menu: "Menü",
    personSignIn: "Person Einloggen ",
    hotelSignIn: "Hotel Einloggen",
    personSignUp: "Person Registrierung",
    hotelSignUp: "Business Registrierung",
    signIn: "Einloggen",
    registration: "Registrierung",

    // modals sign in up
    signInWith: "Melden Sie sich mit Ihrem",
    or: "oder",
    account: "konto",
    orWith: "Oder mit",
    loginFacebook: "Mit Facebook anmelden",
    loginGoogle: "Mit Google anmelden",
    dnAccount: "Du hast noch kein Profil?",
    signUpPerson: "Anmeldung Student",
    signUpBusiness: "Anmeldung Hotel",
    modalSignIn: " Anmelden",
    signUp: "Registrierung",
    personName: "Person's name",
    surname: "Nachname",
    enterMail: "Enter your E-mail",
    phone: "Phone",
    enterPhone: "Enter your phone number",
    appliedSuccessfully: "Erfolgreich beworben",
    didNotAppliedSuccessfully: "Try again",
    aboutUs: "About us",
    forStudent: "Für student",
    forBusiness: "Für business",
    business_entity_maximum_qty: "Maximum Hotels",
    vacancies_maximum_qty: "Maximum Anzeigen",
    applications_per_vacancy_maximum_qty:
      "Maximale Anzahl an Kandidaten pro Anzeigen",

    COMPANY_HAS_NOT_LIMIT_VACANCY_ADD:
      "Sie haben das maximale Leerstandslimit erreicht. Erhöhen Sie Ihren Plan..",
    is_unlimitied: "Unbegrenzt",
  },
};

export default germanTranslation;
