import FaqContent from "components/faq/FaqContent";
import BussinesLandingSection from "components/sections/BussinesLanding/BussinesLandingCover/BussinesLandingSection";
import BussinesLandingVorteil from "components/sections/BussinesLanding/BussinesLandingVorteil/BussinesLandingVorteil";
import StepsSection from "components/sections/StepsSection";
import React from "react";

function BussinesLandingPage() {
  return (
    <>
      <BussinesLandingSection />
      <BussinesLandingVorteil />
      <StepsSection />
      <FaqContent />
    </>
  );
}

export default BussinesLandingPage;
