import React from "react";
import Button from "../../common/Button";
import styles from "./FooterHelp.module.scss";

const FooterHelp: React.FC = () => {
  return (
    <Button variant="transparent" iconSize={20} className={styles.root}>
      Copyright © Workn Live 2024
    </Button>
  );
};

export default FooterHelp;
