import React from "react";

const MenuIcon: React.FC = () => {
  return (
    <>
      <path
        d="M3.5 8.16669H24.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M3.5 14H24.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M3.5 19.8333H24.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </>
  );
};

export default MenuIcon;
