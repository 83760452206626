import { useEffect, useState } from "react";
import ProfileListingCard from "../../common/ProfileListingCard";
import InvoiceCard from "../../common/InvoiceCard";
import { getInvoices } from "../../../api/employer/company";
import { useAppSelector } from "../../../hooks";

const Invoices = () => {
  const user = useAppSelector((state: any) => state.auth.user);
  const [invoices, setInvoices] = useState<any>(null);
  const fetchData = () => {
    getInvoices(user?.companyId).then((response: any) => {
      setInvoices(response?.data?.items);
    });
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <ProfileListingCard>
      {invoices?.map((invoice: any) => (
        <InvoiceCard
          key={invoice.id}
          id={invoice?.id}
          amount={invoice?.amount}
          date={invoice?.created_at}
          status={invoice?.status}
        />
      ))}
      {!invoices && "Records not found"}
    </ProfileListingCard>
  );
};

export default Invoices;
