import React from "react";
import SaveForLaterButton from "components/common/SaveForLateButotn";
import styles from "./JobHeader.module.scss";
import Container from "../../common/Container";
import { VacancyDataItemType } from "../../../types/job-seeker/vacancy.type";

const JobHeader: React.FC<VacancyDataItemType> = ({
  id,
  title,
  subTitle,
  images,
  isInFavourite,
}) => {
  return (
    <div className={styles.root}>
      <Container className={styles.container}>
        <div className={styles.left}>
          <div className={styles.logo}>
            <img src={images} width="104" height="104" alt="brand" />
          </div>
          <div className={styles.info}>
            <div className={styles.hotel}>{subTitle}</div>
            <div className={styles.position}>{title}</div>
          </div>
        </div>

        <div className={styles.right}>
          <SaveForLaterButton
            isInFavourite={isInFavourite}
            id={id}
            className={styles.saveButton}
          />
        </div>
      </Container>
    </div>
  );
};

export default JobHeader;
