import React from "react";
import { Field, Form, Formik } from "formik";
import cn from "classnames";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./ResetPasswordForm.module.scss";
import Input from "../../common/Input";
import Button from "../../common/Button";
import { ForgotPasswordSchema } from "../../../schemas/ForgotPasswordSchema";

type Props = {
  isBusiness: boolean;
  onSubmit: any;
};

const ResetPasswordForm: React.FC<Props> = ({ isBusiness, onSubmit }) => {
  const { t } = useTranslation();
  const { token } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const initialValues = {
    token,
    email: email || "",
    password: "",
    password_confirmation: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ForgotPasswordSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched }) => (
        <Form>
          <div
            className={cn(
              styles.input,
              errors.email && touched.email ? styles.errored : "",
            )}
          >
            <Field
              name="email"
              label="Email"
              placeholder="Enter your email"
              disabled
              component={Input}
            />
            {errors.email && touched.email ? (
              <div className={styles.error}>{errors.email}</div>
            ) : null}
          </div>
          <div
            className={cn(
              styles.input,
              errors.password && touched.password ? styles.errored : "",
            )}
          >
            <Field
              name="password"
              type="password"
              label={t("password")}
              placeholder={t("password")}
              component={Input}
            />
            {errors.password && touched.password ? (
              <div className={styles.error}>{errors.password}</div>
            ) : null}
          </div>

          <div
            className={cn(
              styles.input,
              errors.password_confirmation && touched.password_confirmation
                ? styles.errored
                : "",
            )}
          >
            <Field
              name="password_confirmation"
              type="password"
              label={t("confirmPassword")}
              placeholder={t("confirmPassword")}
              component={Input}
            />
            {errors.password_confirmation && touched.password_confirmation ? (
              <div className={styles.error}>{errors.password_confirmation}</div>
            ) : null}
          </div>
          <Button
            variant={isBusiness ? "dark" : "primary"}
            size="lg"
            block
            submit
          >
            Update Password
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
