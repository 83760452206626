import React from "react";
import { useTranslation } from "react-i18next";
import ProfileListingCard from "../../common/ProfileListingCard";
import AddCard from "../../common/AddCard";
import { ReactComponent as HotelIcon } from "../../../assets/icons/hotel.svg";
import HotelCard from "../../common/HotelCard";
import { HotelType } from "../../../types/employer/hotel.type";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { toggleAddHotelModal } from "../../../store/slices/uiSlice";
import AddHotelModal from "../../modals/AddHotelModal";

type Props = {
  data: HotelType[];
  capabilities: any;
  onUpdate: any;
  onDelete: any;
};

const MyHotels = ({ data, capabilities, onUpdate, onDelete }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const showAddHotelModal = useAppSelector(
    (state: any) => state.ui.showAddHotelModal,
  );

  return (
    <>
      <ProfileListingCard>
        {data.map((item) => (
          <HotelCard
            key={item.id}
            data={item}
            onDelete={(hotel: any) => onDelete(hotel)}
          />
        ))}
        {capabilities?.can_create && (
          <AddCard
            // title="Add hotel"
            title={t("addHotel")}
            icon={HotelIcon}
            onClick={() => dispatch(toggleAddHotelModal(null))}
          />
        )}
      </ProfileListingCard>
      <AddHotelModal
        visible={showAddHotelModal}
        onUpdate={() => {
          onUpdate();
          dispatch(toggleAddHotelModal(null));
        }}
      />
    </>
  );
};

export default MyHotels;
