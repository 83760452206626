import cn from "classnames";
import UserAvatar from "../../common/UserAvatar";
import ArrowIcon from "../../../assets/icons/arrow-right.svg";
import styles from "./Conversation.module.scss";

type Props = {
  isGray?: boolean;
  message?: any;
};

const defaultProps: Props = {
  isGray: false,
  message: {},
};

const MessageCard = ({ isGray, message }: Props) => {
  return (
    <div
      className={cn(styles.message, {
        [styles["message--gray"]]: isGray,
      })}
    >
      <div className={styles.message__avatar}>
        <UserAvatar size="md" />
      </div>
      <div className={styles.message__avatar__mobile}>
        <div className={styles["message__avatar__mobile--left"]}>
          <UserAvatar size="md" />
          <span className={styles.message__name}>{message?.title}</span>
        </div>
        <img src={ArrowIcon} alt="details" />
      </div>
      <div className={styles.message__box}>
        <span
          className={cn([styles.message__name, styles.message__name__desktop])}
        >
          {message?.title}
        </span>
        <span className={styles.message__text}>{message?.content}</span>
      </div>
    </div>
  );
};

MessageCard.defaultProps = defaultProps;

export default MessageCard;
