import React from "react";
import { FieldProps } from "formik";
import styles from "./Input.module.scss";

interface InputProps extends FieldProps {
  type?: "text" | "email" | "password" | "number";
  label?: string;
}

const defaultProps: Partial<InputProps> = {
  type: "text",
  label: "",
};

const Input = ({ label, type, field, form, ...props }: InputProps) => {
  return (
    <>
      <label htmlFor={field.name}>{label}</label>
      <input className={styles.root} type={type} {...field} {...props} />
    </>
  );
};

Input.defaultProps = defaultProps;

export default Input;
