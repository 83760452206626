import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import styles from "./ResetPasswordCard.module.scss";
import Card from "../../common/Card";
import { AuthType } from "../../../types/auth/auth.type";
import {
  updatePasswordByEmployer,
  updatePasswordByJobSeeker,
} from "../../../store/actions/authAction";
import ResetPasswordForm from "../../forms/ResetPasswordForm";

const ResetPasswordCard = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  const authType = type === "jobSeeker" ? "person" : "hotel";
  const [isBusiness] = useState<boolean>(authType === "hotel");
  const error = useAppSelector((state: any) => state.auth.error);
  const success = useAppSelector((state: any) => state.auth.success);

  const onSubmit = async (values: AuthType) => {
    if (isBusiness) {
      dispatch(updatePasswordByEmployer(values, authType));
    } else {
      dispatch(updatePasswordByJobSeeker(values, authType));
    }
  };

  return (
    <Card
      className={styles.root}
      headerClassName={styles.header}
      bodyClassName={styles.body}
    >
      <div className={styles.header}>
        <div className={styles.left}>
          <div className={styles.title}>Reset Password</div>
        </div>
      </div>
      <div className={styles.error}>{error}</div>
      <div className={styles.success}>{success?.message}</div>

      <ResetPasswordForm isBusiness={isBusiness} onSubmit={onSubmit} />
      <div className={styles.additional} />
    </Card>
  );
};

export default ResetPasswordCard;
