import React from "react";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import cn from "classnames";
import styles from "./AddHotelForm.module.scss";
import Input from "../../common/Input";
import Button from "../../common/Button";
import AddHotelSchema from "../../../schemas/AddHotelSchema";

type Props = {
  hotel: {
    name: string;
    address: string;
  };
  onSubmit: any;
};

const AddHotelForm: React.FC<Props> = ({ hotel, onSubmit }) => {
  const { t } = useTranslation();
  const initialValues = {
    name: hotel?.name || "",
    address: hotel?.address || "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AddHotelSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched }) => (
        <Form>
          <div
            className={cn(
              styles.input,
              errors.name && touched.name ? styles.errored : "",
            )}
          >
            <Field
              name="name"
              label={t("branchHotelName")}
              placeholder={t("enterBranchHotelName")}
              component={Input}
            />
            {errors.name && touched.name ? (
              <div className={styles.error}>{errors.name}</div>
            ) : null}
          </div>
          <div
            className={cn(
              styles.input,
              errors.address && touched.address ? styles.errored : "",
            )}
          >
            <Field
              name="address"
              label={t("branchHotelAddress")}
              placeholder={t("enterBranchHotelAddress")}
              component={Input}
            />
            {errors.address && touched.address ? (
              <div className={styles.error}>{errors.address}</div>
            ) : null}
          </div>
          <Button variant="primary" size="lg" block submit>
            {hotel ? t("updateHotel") : t("addHotel")}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default AddHotelForm;
