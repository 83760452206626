import { get, post, put } from "../index";
import { EmployerType } from "../../types/employer/employer.type";

export const getMe = async () => {
  return get<EmployerType>("/employer/me");
};

export const getOther = async () => {
  return get<EmployerType>("/employer/other");
};

export const updatePassword = async (data: object) => {
  return post<EmployerType>("/employer/auth/reset-password", data);
};

export const updateJobSeeker = async (data: object) => {
  return put<EmployerType>("/job-seeker/update", data);
};

export const updateEmploeyer = async (data: object) => {
  return put<EmployerType>("/employer/update", data);
};
