import React from "react";
import StudentsLandingPage from "pages/StudentsLandingPage";
import BussinesLandingPage from "pages/BussinesLandingPage";
import { createBrowserRouter } from "react-router-dom";
import HomePage from "../pages/HomePage";
import JobPage from "../pages/JobPage";
import CatalogPage from "../pages/CatalogPage";
import ProfileSettingsPage from "../pages/profile/ProfileSettingsPage";
import ProfilePage from "../pages/ProfilePage";
import AppliedJobsPage from "../pages/profile/AppliedJobsPage";
import SavedJobsPage from "../pages/profile/SavedJobsPage";
import MyHotelsPage from "../pages/profile/MyHotelsPage";
import MyJobsPage from "../pages/profile/MyJobsPage";
import RecievedApplicationsPage from "../pages/profile/RecievedApplicationsPage";
import InvoicesPage from "../pages/profile/InvoicesPage";
import PricingPage from "../pages/profile/PricingPage";
import ConversationPage from "../pages/profile/ConversationPage";
import BusinessInformationPage from "../pages/profile/BusinessInformationPage";
import AddListingPage from "../pages/profile/AddListingPage";
import AuthMiddleware from "./middlewares/AuthMiddleware";
import AboutPage from "../pages/AboutPage";
import ContactPage from "../pages/ContactPage";
import TermsPage from "../pages/TermsPage";
import PrivacyPage from "../pages/PrivacyPage";
import LanguageMiddleware from "./middlewares/LanguageMiddleware";

export default createBrowserRouter([
  {
    path: ":lang",
    element: <LanguageMiddleware />,
    children: [
      {
        path: "student",
        index: true,
        element: <StudentsLandingPage />,
      },
      {
        path: "bussines",
        index: true,
        element: <BussinesLandingPage />,
      },
      {
        path: "",
        index: true,
        element: <HomePage />,
      },

      {
        path: "vacancies",
        element: <CatalogPage />,
      },

      {
        path: "vacancy/:id",
        element: <JobPage />,
      },
      {
        path: "about-us",
        element: <AboutPage />,
      },
      {
        path: "contact",
        element: <ContactPage />,
      },
      {
        path: "terms",
        element: <TermsPage />,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPage />,
      },
      {
        path: "password-reset/:token",
        element: <HomePage />,
      },
      {
        path: "profile",
        element: (
          <AuthMiddleware>
            <ProfilePage />
          </AuthMiddleware>
        ),
        children: [
          {
            path: "",
            element: <ProfileSettingsPage />,
          },
          {
            path: "settings",
            element: <ProfileSettingsPage />,
          },
          {
            path: "applied-jobs",
            element: <AppliedJobsPage />,
          },
          {
            path: "saved-jobs",
            element: <SavedJobsPage />,
          },
          {
            path: "business-information",
            element: <BusinessInformationPage />,
          },
          {
            path: "hotels",
            element: <MyHotelsPage />,
          },
          {
            path: "jobs",
            element: <MyJobsPage />,
          },
          {
            path: "jobs/add",
            element: <AddListingPage />,
          },
          {
            path: "jobs/:id/edit",
            element: <AddListingPage />,
          },
          {
            path: "applications",
            element: <RecievedApplicationsPage />,
          },
          {
            path: "invoices",
            element: <InvoicesPage />,
          },
          {
            path: "pricing",
            element: <PricingPage />,
          },
          {
            path: "applications/:applicationId/conversation/:chatId",
            element: <ConversationPage />,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <LanguageMiddleware />,
  },
]);
