import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import styles from "./ForgotPasswordCard.module.scss";
import Card from "../../common/Card";
import { AuthType } from "../../../types/auth/auth.type";
import {
  resetPasswordByEmployer,
  resetPasswordByJobSeeker,
} from "../../../store/actions/authAction";
import ForgotPasswordForm from "../../forms/ForgotPasswordForm";
import Button from "../../common/Button";
import { toggleForgotPasswordModal } from "../../../store/slices/uiSlice";

const ForgotPasswordCard = () => {
  const dispatch = useAppDispatch();
  const authType = useAppSelector((state: any) => state.ui.authType);
  const [isBusiness, setIsBusiness] = useState<boolean>(authType === "hotel");
  const error = useAppSelector((state: any) => state.auth.error);
  const success = useAppSelector((state: any) => state.auth.success);

  const onSubmit = async (values: AuthType) => {
    if (isBusiness) {
      dispatch(resetPasswordByEmployer(values));
    } else {
      dispatch(resetPasswordByJobSeeker(values));
    }
  };

  return (
    <Card
      className={styles.root}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      withClose
    >
      <div className={styles.header}>
        <div className={styles.left}>
          <div className={styles.title}>
            Reset? - {isBusiness ? "Business" : "Personal"}
          </div>
          <div className={styles.subtitle}>
            Reset password with your
            <Button
              variant="transparent"
              className={styles.button}
              onClick={() => {
                setIsBusiness(false);
                toggleForgotPasswordModal("person");
              }}
            >
              Personal
            </Button>{" "}
            or
            <Button
              variant="transparent"
              className={styles.button}
              onClick={() => {
                setIsBusiness(true);
                toggleForgotPasswordModal("hotel");
              }}
            >
              Business
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.error}>{error}</div>
      <div className={styles.success}>{success?.message}</div>

      <ForgotPasswordForm isBusiness={isBusiness} onSubmit={onSubmit} />
      <div className={styles.additional} />
    </Card>
  );
};

export default ForgotPasswordCard;
