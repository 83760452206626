import React from "react";
import cn from "classnames";
import styles from "./Container.module.scss";
import { ContainerProps } from "./Container.types";

const Container: React.FC<ContainerProps> = ({ children, className }) => {
  return <div className={cn(styles.root, className)}>{children}</div>;
};

export default Container;
