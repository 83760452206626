import React from "react";
import styles from "./HeaderLeft.module.scss";
import HeaderLogo from "../HeaderLogo";
import HeaderNavButton from "../HeaderNavButton";
import HeaderSearch from "../HeaderSearch";

type Props = {
  isBusiness: boolean;
};

const HeaderLeft: React.FC<Props> = ({ isBusiness }) => {
  return (
    <div className={styles.root}>
      <HeaderLogo isBusiness={isBusiness} />
      <HeaderNavButton isBusiness={isBusiness} />
      <div className={styles.search}>{!isBusiness && <HeaderSearch />}</div>
    </div>
  );
};

export default HeaderLeft;
