import React from "react";
import { Form, Formik } from "formik";
import cn from "classnames";
import styles from "./ApplyJobForm.module.scss";
import Button from "../../common/Button";
import { ApplyJobSchema } from "../../../schemas/ApplyJobSchema";
import AttachFile from "../../common/AttachFile";

type Props = {
  onSubmit: any;
};

const ApplyJobForm: React.FC<Props> = ({ onSubmit }) => {
  const initialValues = {
    file: [],
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ApplyJobSchema}
      onSubmit={onSubmit}
    >
      {({ setFieldValue, errors, touched }) => (
        <Form encType="multipart/form-data">
          <div
            className={cn(
              styles.input,
              errors.file && touched.file ? styles.errored : "",
            )}
          >
            <AttachFile
              className={styles.file}
              onSelect={(event: any) => setFieldValue("file", event)}
            />
            {errors.file && touched.file ? (
              <div className={styles.error}>{errors.file}</div>
            ) : null}
          </div>
          <Button variant="primary" size="lg" block submit>
            Jetzt bewerben
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default ApplyJobForm;
