import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import JobContent from "../components/jobs/JobContent";
import { getJobSeekerVacancy } from "../api/job-seeker/vacancies";
import { VacancyDataItemType } from "../types/job-seeker/vacancy.type";
import { useAppSelector } from "../hooks";

const JobPage = () => {
  const { id } = useParams();
  const isAuthenticated = useAppSelector(
    (state: any) => state.auth.isAuthenticated,
  );
  const [vacancy, setVacancy] = useState<VacancyDataItemType>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getJobSeekerVacancy(id);
        setVacancy(response?.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [isAuthenticated]);

  return <JobContent item={vacancy} />;
};

export default JobPage;
