import { useState, useEffect } from "react";
import SettingsForm from "../../components/profile/SettingsForm";
import { getMe } from "../../api/employer/me";
import { EmployerType } from "../../types/employer/employer.type";
import { getJobSeeker } from "../../api/jobSeeker/me";
import { useAppSelector } from "../../hooks";

const ProfileSettingsPage = () => {
  const isBusiness = useAppSelector((state: any) => state.auth.isBusiness);
  const [data, setData] = useState<EmployerType | null>(null);

  useEffect(() => {
    if (isBusiness)
      getMe().then((response) => {
        setData(response.data);
        localStorage.setItem(
          "user",
          JSON.stringify({
            firstName: response?.data?.first_name,
            companyId: response?.data?.company_id,
          }),
        );
      });
    else getJobSeeker().then((response) => setData(response.data));
    // console.log(data);
  }, []);

  return data && <SettingsForm user={data} />;
};

export default ProfileSettingsPage;
