import React from "react";
import styles from "./FooterMiddle.module.scss";
import Link from "../../common/Link";

const FooterMiddle: React.FC = () => {
  return (
    <nav className={styles.root}>
      <li className={styles.item}>
        <Link to="/terms" className={styles.link}>
          Terms of Use
        </Link>
      </li>
      <li className={styles.item}>and</li>
      <li className={styles.item}>
        <Link to="/privacy-policy" className={styles.link}>
          Privacy Policy
        </Link>
      </li>
    </nav>
  );
};

export default FooterMiddle;
