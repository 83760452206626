import React, { useEffect, useRef } from "react";
import { Field, Form, Formik } from "formik";
import cn from "classnames";
import styles from "./ForgotPasswordForm.module.scss";
import Input from "../../common/Input";
import Button from "../../common/Button";
import { ForgotPasswordSchema } from "../../../schemas/ForgotPasswordSchema";
import { useAppSelector } from "../../../hooks";

type Props = {
  isBusiness: boolean;
  onSubmit: any;
};

const ForgotPasswordForm: React.FC<Props> = ({ isBusiness, onSubmit }) => {
  const formikRef = useRef(null);
  const loading = useAppSelector((state: any) => state.auth.loading);
  const initialValues = {
    email: "",
  };

  useEffect(() => {
    if (!loading) {
      // @ts-ignore
      formikRef?.current?.resetForm();
    }
  }, [loading]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ForgotPasswordSchema}
      innerRef={formikRef}
      onSubmit={onSubmit}
    >
      {({ errors, touched }) => (
        <Form>
          <div
            className={cn(
              styles.input,
              errors.email && touched.email ? styles.errored : "",
            )}
          >
            <Field
              name="email"
              label="Email"
              placeholder="Enter your email"
              component={Input}
            />
            {errors.email && touched.email ? (
              <div className={styles.error}>{errors.email}</div>
            ) : null}
          </div>
          <Button
            variant={isBusiness ? "dark" : "primary"}
            size="lg"
            block
            disabled={loading}
            submit
          >
            Send link
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;
