import React from "react";
import styles from "./BussinesLandingVorteil.module.scss";
import Container from "../../../common/Container";

function BussinesLandingVorteil() {
  const data = [
    {
      header: "Junge und dynamische Fachkräfte.",
      paragraph:
        "Workn Live bietet Ihnen Zugang zu einer breiten Auswahl leistungsfähiger und motivierter Studenten, die eine wertvolle Ergänzung für Ihr Hotelteam darstellen.",
    },
    {
      header: "Erfolgsgarantie,Zahlung erst nach erfolgreicher Vermittlung.",
      paragraph:
        "Sie gehen keine finanziellen Verpflichtungen ein, bevor unser Service nicht erfolgreich war. Das heißt, sie müssen erst bezahlen, wenn wir erfolgreich eine Vermittlung zwischen ihnen und einem Kandidaten hergestellt haben.",
    },
    {
      header: "Geringe Fluktuation.",
      paragraph:
        'Der Mitarbeitermangel im Hotel- und Gastgewerbe ist kein temporäres, sondern ein strukturelles Problem. Mitarbeiter kommen und  verlassen die Arbeit schnell. Durch eine Win-Win-Situation bieten wir eine Lösung, da es für die Mitarbeiter schwieriger ist, ihr Zuhause zu verlassen als "nur einen Job“.',
    },
    {
      header: "Flexibilität/Reduzierte Ausfallzeiten.",
      paragraph:
        "Da der Student direkt im Hotel wohnt, kann er bei Bedarf schnell und flexibel auf unerwartete Situationen reagieren. z.B. unvorhergesehenen Spitzenzeiten oder in Notfallsituationen einspringen.\n" +
        "Mit Vorabbestätigung können sie bei Bedarf für verschiedene Abteilungen eingeplant werden.\n",
    },
    {
      header: "Mitarbeiterbindung",
      paragraph:
        "Das Wohnen direkt im Hotel stärkt die emotionale Bindung der Mitarbeiter zum Unternehmen. Sie erleben sich intensiver als Mitglieder der Hotelgemeinschaft und spüren eine tiefere Identifikation mit den Werten und Zielen des Hauses." +
        "Dadurch erlangen Mitarbeiter im Hotel eine ausgeglichene Balance zwischen Arbeit und Freizeit. Die ersparte Zeit und Energie des täglichen Pendelns kann nun zur Erholung und für persönliche Interessen verwendet werden.",
    },
    {
      header: "Kosteneinsparungen im Vergleich zu Leihfirmen.",
      paragraph: `Im Vergleich zu Leihfirmen senken Sie mit uns Ihre Kosten erheblich und bleiben dabei flexibel.`,
    },
    {
      header: "Wettbewerbsvorteil.",
      paragraph:
        "Durch die Unterbringungsmöglichkeit im Hotel wird die Rekrutierung optimiert. Dies ermöglicht Mitarbeitern eine direkte Wohnlösung, beschleunigt die unkomplizierte Integration ins Team und  eliminiert Herausforderungen. Diese Vorzüge ziehen potenzielle Kandidaten an.",
    },
  ];
  return (
    <div className={styles.container}>
      <Container className={styles.root}>
        <h1 className={styles.header}>Vorteile für Hotels</h1>
        <div className={styles.subContainer}>
          {data.map((item) => (
            <div className={styles.card}>
              <h1 className={styles.cardHeader}>{item.header}</h1>
              <p className={styles.paragraph}>{item.paragraph}</p>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
}

export default BussinesLandingVorteil;
