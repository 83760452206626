import React from "react";
import styles from "./ResetPasswordModal.module.scss";
import Modal from "../../common/Modal";
// import { useAppDispatch } from "../../../hooks";
// import { closeModals } from "../../../store/slices/uiSlice";
// import { setAuthError } from "../../../store/slices/authSlice";
import ResetPasswordCard from "../../cards/ResetPasswordCard";

export type Props = {
  visible: boolean;
};

const ForgotPasswordModal: React.FC<Props> = ({ visible }) => {
  // const dispatch = useAppDispatch();

  const onClose = () => {
    // dispatch(setAuthError(null));
    // dispatch(closeModals());
  };

  return (
    <Modal className={styles.root} visible={visible} onClose={() => onClose()}>
      <ResetPasswordCard />
    </Modal>
  );
};

export default ForgotPasswordModal;
