import React from "react";

const TickIcon: React.FC = () => {
  return (
    <>
      <path
        d="M7.5 16.6975L16.6975 7.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6975 16.6975L7.5 7.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
};

export default TickIcon;
