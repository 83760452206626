import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CatalogContentSimple from "../components/catalog/CatalogContent/CatalogContentSimple";
import { getJobSeekerVacancies } from "../api/job-seeker/vacancies";
import { VacanciesDataItemType } from "../types/job-seeker/vacancies.type";
import { useAppSelector } from "../hooks";

const CatalogPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const isAuthenticated = useAppSelector(
    (state: any) => state.auth.isAuthenticated,
  );
  const [vacancies, setVacancies] = useState<VacanciesDataItemType[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getJobSeekerVacancies(
          (queryParams.get("search") as string) || "",
        );
        setVacancies(response?.data?.items || []);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [isAuthenticated, location.search]);

  return <CatalogContentSimple items={vacancies} />;
};

export default CatalogPage;
