import React, { useState } from "react";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import { useTranslation } from "react-i18next";
import styles from "./FaqContent.module.scss";
import Container from "../../common/Container";
import Button from "../../common/Button";
import "./Accordion.scss";
import closeIcon from "../../../assets/icons/minus-cirlce.svg";
import openIcon from "../../../assets/icons/add-circle.svg";
import Link from "../../common/Link";

const FaqContent = () => {
  const { t } = useTranslation();
  const [isItemExpanded, setIsItemExpanded] = useState([false, false, false]);
  const faqData = [
    {
      id: 1,
      header: t("Was macht workn.live?"),
      content: t(
        "Workn.Live schafft eine Verbindung zwischen Studierenden und Hotels. Dadurch erhalten Studierende die Möglichkeit, in den Hotels zu wohnen, während die Hotels von den engagierten und intellektuellen Leistungen der Studierenden profitieren.\n",
      ),
    },
    {
      id: 2,
      header: t("Ist Workn.Live eine Zeitarbeits- oder Leiharbeitsfirma?"),
      content: t(
        "Nein, Workn.Live ist nicht als Zeitarbeits- oder Leiharbeitsfirma tätig. Wir agieren als Vermittlungsplattform, um Arbeitgeber und Arbeitnehmer zusammenzubringen  und so optimale Synergien zu erzeugen. Während wir uns bewusst zurückhalten und keine detaillierte Einmischung vornehmen, sind wir dennoch stets bereit, bei Herausforderungen unterstützend zur Seite zu stehen.",
      ),
    },
    {
      id: 3,
      header: t("Wie kann unser Hotel Partner von Workn.Live werden?"),
      content: t(
        "Registrieren Sie sich auf unserer Website als Unternehmen und laden Sie Ihr Angebot für Studenten hoch. Wir werden Ihnen die Bewerbungen von von uns geprüften Studenten zusenden. Die Kosten fallen erst nach erfolgreicher Rekrutierung des Bewerbers an.",
      ),
    },
    {
      id: 4,
      header: t("Wie läuft die Rekrutierung?"),
      content: t(
        "Nach der Veröffentlichung Ihrer Anzeige haben Studenten die Möglichkeit, Sie zu kontaktieren. Als Hotel können Sie auf geeignete Anfragen reagieren und Details direkt auf der Website im Chat klären.     \n" +
          "P.S.: Workn.Live greift nicht in Ihre Arbeitsverträge ein, steht jedoch bereit, Empfehlungen zur Vertragsgestaltung für beide Seiten vorzuschlagen.",
      ),
    },
    {
      id: 5,
      header: t(
        "Was geschieht, wenn Mitarbeiter unabsichtlich Hotel-Eigentum beschädigen?",
      ),
      content: t(
        "Während der Arbeitszeit sind alle Mitarbeiter gegen Schäden versichert. Für Zeiten außerhalb der Arbeit empfehlen wir, eine Haftpflichtversicherung im Vertrag festzulegen. So sind Arbeitgeber und Arbeitnehmer stets abgesichert und können unbesorgt agieren.",
      ),
    },
    {
      id: 6,
      header: t(
        "Auf welcher Position und wie viel müssen die Studenten arbeiten?",
      ),
      content: t(
        'Hotels bieten eine Vielzahl von Positionen im Gastgewerbe an, dazu gehören beispielsweise Rollen als Kellner, Rezeptionist, im Housekeeping oder als Aushilfe in der Küche. Ein besonderes Highlight, wovon erfahrene Studenten viel sprechen, ist der sogenannte "Mix-Aushilfe-Job". Was verbirgt sich dahinter? Hotels haben oft saisonabhängige Anforderungen in verschiedenen Bereichen. Ein flexibler Student, der in der Lage ist, sowohl im Service im Restaurant, in der Rezeption als auch im Housekeeping einzuspringen, ist hier besonders wertvoll. Dies bietet nicht nur den Hotels Flexibilität, sondern gibt den Studenten auch die Möglichkeit, eine abwechslungsreiche Erfahrung zu sammeln, sich nicht auf eine Rolle festzulegen und regelmäßig, sei es wöchentlich, monatlich oder saisonal, verschiedene Tätigkeiten auszuüben.\n' +
          "Dabei bevorzugen die meisten Studenten Arbeitsverhältnisse als Minijob oder im Rahmen eines Werkstudentenvertrags, was in der Regel 1 bis 3 Arbeitstage pro Woche bedeutet.",
      ),
    },
    {
      id: 7,
      header: t("Was passiert nach der Kündigung ?"),
      content: t(
        "Wir legen großen Wert darauf, bereits in unseren Vertragsempfehlungen eine transparente und offene Kommunikation zu fördern. Es ist essentiell, dass beide Parteien von Beginn an über ihre Rechte und Pflichten informiert sind. Daher empfehlen wir, sowohl Kündigungsfristen als auch Auszugsbedingungen schriftlich festzulegen. Selbstverständlich können extreme Situationen auftreten, die eine sofortige Kündigung und einen unmittelbaren Auszug rechtfertigen, wie zum Beispiel bei Diebstahl oder wenn das Verhalten eines Arbeitnehmers die Sicherheit im Hotel beeinträchtigt. In solchen Ausnahmefällen sollten die Arbeitsverträge klare Regelungen enthalten. Unser oberstes Ziel ist es, durch eindeutige Vertragsbedingungen sowohl den Arbeitgeber als auch den Arbeitnehmer zu schützen und so ein harmonisches Miteinander sicherzustellen.",
      ),
    },
    {
      id: 8,
      header: t("Gibt es Kosten für die Nutzung von Workn.Live?\n"),
      content: t(
        "Für Studenten ist die Nutzung von Workn.Live völlig kostenlos. Unternehmen hingegen zahlen individuell festgelegte Preise für unsere Dienstleistungen.",
      ),
    },
    {
      id: 9,
      header: t("Was bedeutet “die Unterkunft ist für Studenten kostenlos?"),
      content: t(
        'Kostenlose Unterkunft für Studenten" bedeutet, dass die Bezahlung für die Unterkunft nicht in monetärer Form, sondern durch erbrachte Arbeitsleistungen erfolgt. Statt beispielsweise 480€/Monat für die Unterkunft zu zahlen, entspricht dies 8 Arbeitsstunden/Woche  im ausgewählten Servicebereich (Angenommen Stundenlohn ist 15€).',
      ),
    },
    {
      id: 10,
      header: t(
        "Wann kann ich einziehen und wie funktioniert die Kündigung bzw. der Auszug?",
      ),
      content: t(
        "Grundsätzlich ist ein schneller Einzug oftmals möglich, sogar von einem Tag auf den anderen. Dennoch empfehlen wir, die Details rund um den Einzug sowie die Kündigungs- und Auszugsmodalitäten im Vorfeld direkt und unkompliziert mit den Hotels abzustimmen. Unsere Tipps wirst du per E-Mail erhalten, sobald du deine Bewerbung an ein Hotel schickst",
      ),
    },
  ];
  const handleItemToggle = (expanded: boolean, index: number) => {
    setIsItemExpanded((prev) =>
      prev.map((el, i) => (i !== index ? el : expanded)),
    );
  };

  return (
    <div className={styles.root}>
      <Container className={styles.container}>
        <div className={styles.sup}>{t("faqs")}</div>
        <div className={styles.title}>{t("faqTitle")}</div>
        <Accordion
          transition
          transitionTimeout={200}
          className={styles.accordion}
        >
          {faqData.map((item, index) => (
            <AccordionItem
              key={item.id}
              onClick={() => handleItemToggle(!isItemExpanded[index], index)}
              header={
                <div className={styles.flex}>
                  <p className={styles.faqHeader}>{item.header}</p>

                  <img
                    alt="button"
                    src={isItemExpanded[index] ? closeIcon : openIcon}
                  />
                </div>
              }
            >
              {item.content}
            </AccordionItem>
          ))}
        </Accordion>
        <div>
          <div className={styles.text}>{t("faqFooterQuestion")}</div>
        </div>
        <Link to="/contact" className={styles.link}>
          <Button variant="primary" size="lg" block className={styles.button}>
            {t("contactUs")}
          </Button>
        </Link>
      </Container>
    </div>
  );
};

export default FaqContent;
