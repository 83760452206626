import SelectArrowIcon from "../../../assets/icons/select-arrow.svg";
import styles from "./Select.module.scss";

type Props = {
  icon: string;
  name?: string;
  options?: { title: string; value: string }[];
  value?: string;
  onChange?: (value: { name: string; value: string }) => void;
};

const defaultProps: Partial<Props> = {
  name: "",
  options: [],
  value: "",
  onChange: () => {},
};

const Select = ({ icon, name, options, value, onChange }: Props) => {
  const style = {
    backgroundImage: `url(${icon}), url(${SelectArrowIcon})`,
  };

  const selectOption = (event: any) => {
    if (name) onChange?.({ name, value: event.target.value });
  };

  return (
    <select className={styles.select} style={style} onChange={selectOption}>
      {options?.map((option) => (
        <option value={option.value} selected={value === option.title}>
          {option.title}
        </option>
      ))}
    </select>
  );
};

Select.defaultProps = defaultProps;

export default Select;
