import React from "react";
import styles from "./Footer.module.scss";
import Container from "../../common/Container";
import FooterLeft from "../FooterLeft";
import FooterMiddle from "../FooterMiddle";
import FooterRight from "../FooterRight";

const Footer: React.FC = () => {
  return (
    <footer className={styles.root}>
      <Container className={styles.container}>
        <FooterLeft />
        <FooterMiddle />
        <FooterRight />
      </Container>
    </footer>
  );
};

export default Footer;
