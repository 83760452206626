import { post } from "../index";

export const postEmployerAuthLogin = async (data: any) => {
  return post<any>("/employer/auth/login", data);
};

export const postEmployerSocialLogin = async (provider: string, data: any) => {
  return post<any>(`/employer/auth/social-login/${provider}`, data);
};

export const postEmployerAuthSignUp = async (data: any) => {
  return post<any>("/employer/auth/register", data);
};

export const postEmployerAuthForgotPassword = async (data: any) => {
  return post<any>("/employer/auth/forgot-password", data);
};

export const postEmployerAuthResetPassword = async (data: any) => {
  return post<any>("/employer/auth/reset-password", data);
};
