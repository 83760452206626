import { Navigate, useLocation, useParams } from "react-router-dom";
import React from "react";
import DefaultLayout from "../../layouts/DefaultLayout";

const LanguageMiddleware: React.FC = () => {
  const { pathname, search } = useLocation();
  const { lang } = useParams();
  const fullPathWithSearch = pathname + search;
  const supportedLanguages = ["de"];
  const defaultLanguage = "de";

  if (!supportedLanguages.includes(lang as string)) {
    if (!pathname.includes(`/${defaultLanguage}/`)) {
      const to =
        pathname === "/"
          ? `/${defaultLanguage}`
          : `/${defaultLanguage}${fullPathWithSearch}`;

      return <Navigate to={to} replace />; // Pass replace prop directly
    }
  }

  return <DefaultLayout />;
};

export default LanguageMiddleware;
