import { useEffect, useState } from "react";
import { getCompany } from "../../api/employer/company";
import BusinessInformation from "../../components/profile/BusinessInformation";
import { CompanyType } from "../../types/employer/company.type";
import { getMe } from "../../api/employer/me";

const BusinessInformationPage = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [company, setCompany] = useState<CompanyType | null>(null);
  useEffect(() => {
    getMe().then((response) => {
      localStorage.setItem(
        "user",
        JSON.stringify({
          firstName: response?.data?.first_name,
          companyId: response?.data?.company_id,
        }),
      );
    });
    getCompany().then((response) => {
      if (response.data.items.length > 0) {
        setCompany(response.data.items[0]);
      }
      setLoading(false);
    });
  }, []);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{!loading && <BusinessInformation data={company} />}</>;
};

export default BusinessInformationPage;
