import React from "react";
import Section from "components/common/Section";
import RedVerifyIcon from "components/icons/VerifyIconred";
import ProfileList from "../ProfileList";
import styles from "./Profile.module.scss";
import ProfileCard from "./ProfileCard";
import { useAppSelector } from "../../../hooks";
import Icon from "../../common/Icon";

type Props = {
  children: React.ReactNode;
};

const Profile = ({ children }: Props) => {
  const isBusiness = useAppSelector((state: any) => state.auth.isBusiness);
  const Bussinesvorteils = [
    " Preiswerte Unterkunft",
    "Dauerhafte Miete",
    "Verfügbarkeit",
    "Reduzierte Ausfallzeiten",
    "Viele Bewerber",
    "Loyalität",
    "Mitarbeiterbindung",
    "Kostenkontrolle",
  ];
  const Mitarbeitervorteils = [
    "Preiswerte Unterkunft",
    "Flexibilität",
    "Geldsorgen",
    "Keine Haushaltskosten",
    "Essen und Trinken",
    "Bequemer Arbeitsweg",
    "Bequemlichkeit",
    "Andere Vorteile",
  ];
  const Vorteile = isBusiness ? Bussinesvorteils : Mitarbeitervorteils;
  const title = isBusiness ? "Vorteile für Hotels" : "Vorteile für Mitarbeiter";
  return (
    <>
      <div className={styles.profile}>
        <ProfileList isBusiness={isBusiness} />
        <ProfileCard>{children}</ProfileCard>
      </div>
      <Section
        className={styles.root}
        bodyClassName={styles.body}
        title={title}
        seeMore
      >
        <div className={styles.vorteilContainer}>
          {Vorteile.map((item) => (
            <div className={styles.tag} key={item}>
              <Icon width={24} height={24}>
                <RedVerifyIcon />
              </Icon>
              {item}
            </div>
          ))}
        </div>
      </Section>
    </>
  );
};

export default Profile;
