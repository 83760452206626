import i18n from "i18next";
import germanTranslation from "translations/de";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "de", // Set the fallback language
    debug: true, // Enable debug mode for i18next
    interpolation: {
      escapeValue: false, // React handles escaping
    },
    // Add your translations resources here
    resources: {
      de: {
        ...germanTranslation,
      },
      // Add more languages as needed
    },
  });

export default i18n;
