import React from "react";

const LoginIcon: React.FC = () => {
  return (
    <>
      <path
        d="M7.41675 6.30001C7.67508 3.30001 9.21675 2.07501 12.5917 2.07501H12.7001C16.4251 2.07501 17.9167 3.56668 17.9167 7.29168V12.725C17.9167 16.45 16.4251 17.9417 12.7001 17.9417H12.5917C9.24175 17.9417 7.70008 16.7333 7.42508 13.7833"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.6665 10H12.3998"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5417 7.20831L13.3334 9.99998L10.5417 12.7916"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
};

export default LoginIcon;
