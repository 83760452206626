import React from "react";
import Container from "../components/common/Container";

const PrivacyPage = () => {
  return (
    <Container className="p-y-middle h-fit">
      <h1>Datenschutzerklärung</h1>
      <p>
        Verantwortlicher für die Erfassung und Verarbeitung personenbezogener
        Daten ist WorknLive UG, der Website Inhaber von workn.live.
      </p>
      <p>
        Mittels dieser Datenschutzerklärung werden Art, Umfang und Zweck der von
        unserem Unternehmen auf workn.live erhobenen, genutzten und
        verarbeiteten personenbezogenen Daten festgelegt. Ferner wird über die
        Gründe der Erhebung von personenbezogenen Daten informiert. Bitte lesen
        Sie die Datenschutzerklärung, bevor Sie mit der Nutzung der Website
        workn.live fortfahren.
      </p>
      <p>
        Uns ist sehr am Datenschutz und Datensicherheit gelegen und daher
        versichern wir Ihnen, Ihre personenbezogenen Daten vertraulich zu
        behandeln.
      </p>
      <h2>Erfassung von Daten und Informationen:</h2>
      <p>
        Mit jedem Aufruf der Website workn.live werden automatisch eine Reihe
        von allgemeinen Daten und Informationen wie z. B. der vom zugreifenden
        System verwendete Gerätetyp sowie das dazugehörige Betriebssystem,
        verwendete Browsertypen und Versionen, die Internetprotokoll-Adresse
        (IP-Adresse), Datum und Uhrzeit (Zeitzone) des Zugriffs sowie bereits
        auf Ihrem Gerät installierte Cookies erfasst. Außerdem werden während
        Ihres Zugriffs auf die Website automatisch Informationen zu den einzeln
        aufgerufenen Seiten und Produkten, Suchwörtern und Verlinkungen, über
        welche Sie auf unsere Website gelangt sind sowie andere Daten zum
        Nutzungsverhalten und zur Interaktion mit unserer Website erfasst. Diese
        automatisch erfassten Informationen werden als Geräteinformationen
        bezeichnet. Darüber hinaus erfassen wir alle personenbezogenen Daten,
        die Sie uns unter freiwilliger Angabe beim Registrierungsvorgang zur
        Verfügung stellen (Vorname, Nachname, Adresse, Zahlungsinformationen
        usw.) und die für die Erfüllung der verfolgten Zwecke unsererseits
        notwendig sind.
      </p>
      <h2>Warum wir Ihre Daten erfassen?</h2>
      <p>
        Der Schutz personenbezogener Daten hat für uns oberste Priorität. Daher
        beschränkt sich der Umfang der erhobenen personenbezogenen Daten auf ein
        für die Funktionsfähigkeit der Dienste und Erfüllung der verfolgten
        Zwecke erforderliches Minimum. Die automatisch gesammelten Informationen
        dienen der Gefahrenabwehr im Falle von Angriffen auf unsere
        informationstechnologischen Systeme sowie der statistische Auswertung
        des Nutzungsverhaltens. Statistische Informationen werden nicht
        anderweitig verwendet und es werden keine Rückschlüsse über die
        betroffenen Personen geschlossen.
      </p>
      <p>
        Die Nutzung unserer Website ist grundsätzlich ohne jede Angabe
        personenbezogener Daten möglich. Sofern Sie jedoch bestimmte Dienste
        unseres Unternehmens in Anspruch nehmen möchten, könnte eine
        Verarbeitung personenbezogener Daten erforderlich werden. Solche
        personenbezogene Daten wie z. B. E-Mail-Adresse, Vorname, Nachname,
        Wohnsitz, Organisation, Telefonnummer können Sie uns durch Ausfüllen
        entsprechender Formulare zur Verfügung stellen. Wenn Sie uns Ihre
        persönlichen Daten nicht zur Verfügung stellen möchten, werden Sie
        möglicherweise einige der Services der Website wie z. B. unseren
        Newsletter nicht nutzen
      </p>{" "}
      <p>
        können und uns auch nicht direkt von der Website kontaktieren können.
        Benutzer, die sich nicht sicher sind, welche Daten unbedingt
        erforderlich sind, können uns gerne unter team@workn.live kontaktieren.
      </p>
      <h2>Ihre Rechte:</h2>
      <p>
        Als EU-Bürger/-in haben Sie folgende Rechte in Bezug auf
        personenbezogene Daten:{" "}
      </p>
      <ul>
        <li>Recht auf transparente Information;</li>
        <li>Auskunftsrecht;</li>
        <li>Recht auf Berichtigung;</li>
        <li>Recht auf Löschung (Recht auf "Vergessen werden");</li>
        <li>Recht auf Einschränkung der Verarbeitung;</li>
        <li>Recht auf Datenübertragbarkeit;</li>
        <li>Widerspruchsrecht;</li>
        <li>
          Rechte in Bezug auf automatisierte Entscheidungen einschließlich
          Profiling.
        </li>
      </ul>
      <p>
        Wenn Sie von einem dieser Rechte Gebrauch machen möchten, kontaktieren
        Sie uns bitte über die unten aufgeführten Kontaktdaten.
      </p>
      <p>
        Sofern Sie EU-Bürger/-in sind, möchten wir Sie zusätzlich darauf
        hinweisen, dass wir Ihre Daten verarbeiten, um eventuell bestehende
        Vertragspflichten Ihnen gegenüber erfüllen zu können (z. B. bei
        Produktbestellungen über unsere Website) oder um anderweitig unsere
        geschäftlichen Interessen wie oben aufgeführt zu vertreten. Bitte
        beachten Sie, dass Ihre Daten bei Bedarf auch außerhalb von Europa,
        einschließlich den USA und Kanada, genutzt werden könnten.
      </p>
      <h2>Verlinkungen zu anderen Websites:</h2>
      <p>
        Unsere Website kann Verlinkungen zu anderen Websites enthalten, deren
        Inhalte nicht unser geistiges Eigentum sind oder unserer Kontrolle
        obliegen. Bitte beachten Sie, dass wir keine Haftung für fremde Websites
        bzw. Datenschutzpraktiken Dritter übernehmen. Wir empfehlen Ihnen, beim
        Übergang von unserer auf eine andere Website unbedingt die
        Datenschutzerklärungen entsprechender Website zu lesen, welche ebenfalls
        personenbezogene Daten erfassen wird.
      </p>
      <h2>Informationssicherheit:</h2>
      <p>
        Wir schützen Ihre persönlichen Informationen vor unerlaubtem Zugriff,
        Verwendung oder Veröffentlichung und verwahren hierfür Ihre persönlichen
        Informationen, die Sie auf dem Server speichern, in einer kontrollierten
        sicheren Umgebung, in welcher der unerlaubte Zugriff, Verwendung und
        Veröffentlichung verhindert werden. Wir haben zahlreiche technische und
        organisatorische Maßnahmen umgesetzt, um einen möglichst lückenlosen
        Schutz der über diese Internetseite erfassten personenbezogenen Daten
        sicherzustellen. Jedoch kann ein absoluter Schutz nicht gewährleistet
        werden, da internetbasierte Datenübertragungen grundsätzlich
        Sicherheitslücken aufweisen.
      </p>
      <h2>Offenlegungspflicht:</h2>
      <p>
        Sollte es vom gesetzlichen Standpunkt erforderlich sein, z. B. im Falle
        einer Vorladung oder richterlichen Anordnung oder sollten wir es nach
        eigenem Ermessen für erforderlich halten, um unsere Rechte, Ihre
        Sicherheit oder die Sicherheit Dritter zu schützen, werden wir alle von
        uns erfassten Daten zur Strafverfolgung oder für gerichtliche Prozesse
        an entsprechende Regierungsbehörden weiterleiten, ohne Sie darüber zu
        informieren.
      </p>
      <h2>Kontaktdaten: </h2>
      <p>
        Wenn Sie mehr über Datenschutzrichtlinien erfahren möchten oder Fragen
        in Bezug auf Ihre individuellen Rechte und Ihre persönlichen Daten
        haben, schreiben Sie uns eine E-Mail an team@workn.live.
      </p>
    </Container>
  );
};
export default PrivacyPage;
