import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MyHotels from "../../components/profile/MyHotels";
import { deleteHotel, getHotels } from "../../api/employer/hotels";
import { HotelType } from "../../types/employer/hotel.type";

const MyHotelsPage = () => {
  const { t } = useTranslation();
  const [error, setError] = useState<boolean>(false);
  const [hotels, setHotels] = useState<HotelType[] | null>(null);
  const [capabilities, setCapabilities] = useState<object>({});

  const fetchData = () => {
    getHotels()
      .then((response: any) => {
        setHotels(response.data.items);
        setCapabilities(response.data.capabilities_meta);
      })
      .catch(() => {
        setError(true);
      });
  };

  const onDelete = (data: any) => {
    deleteHotel(data?.id).then(() => {
      fetchData();
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {hotels && (
        <MyHotels
          data={hotels}
          capabilities={capabilities}
          onUpdate={fetchData}
          onDelete={onDelete}
        />
      )}
      {error && (
        <div className="error-default">
          {t(
            "Geben Sie die Geschäftsinformationen ein, um ein Hotel hinzuzufügen",
          )}
        </div>
      )}
    </>
  );
};

export default MyHotelsPage;
