import { useAppSelector } from "hooks";
import BussinesMobileNav from "./mobileNavs/BussinesMobileNav";
import PersonMobileNav from "./mobileNavs/PersonMobileNav";
import DefaultMobileNav from "./mobileNavs/DefaultMobileNav";

const MobileNav = () => {
  const isAuthenticated = useAppSelector(
    (state: any) => state.auth.isAuthenticated,
  );
  const isBusiness = useAppSelector((state: any) => state.auth.isBusiness);
  const lang = useAppSelector((state: any) => state.ui.language);

  if (!isAuthenticated) {
    return <DefaultMobileNav lang={lang} />;
  }

  return isBusiness ? (
    <BussinesMobileNav lang={lang} />
  ) : (
    <PersonMobileNav lang={lang} />
  );
};

export default MobileNav;
