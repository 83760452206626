import React, { ReactElement } from "react";
import { Check } from "react-feather";
import { Checkbox as CheckboxPlugin } from "pretty-checkbox-react";
import "@djthoms/pretty-checkbox";
import "./Checkbox.scss";

type Props = {
  variant?: "fill" | "thick";
  checked?: boolean;
  label?: string;
  icon?: ReactElement<any, "img" | "svg"> | any;
  onChange?: (value: boolean) => void;
};

const defaultProps: Partial<Props> = {
  variant: "fill",
  checked: false,
  label: "",
  icon: <Check className="svg" data-type="svg" size={16} viewBox="0 0 35 35" />,
  onChange: () => {},
};

const Checkbox: React.FC<Props> = ({
  variant,
  checked,
  icon,
  label,
  onChange,
}) => {
  const handleOnChange = (val: any) => {
    onChange?.(val.target.checked);
  };
  return (
    <CheckboxPlugin
      icon={icon}
      iconType="svg"
      shape="curve"
      variant={variant}
      checked={checked}
      animation="smooth"
      className="checkbox"
      onChange={handleOnChange}
    >
      {label}
    </CheckboxPlugin>
  );
};

Checkbox.defaultProps = defaultProps;

export default Checkbox;
