import React, { FormEvent, useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import styles from "./JobSearchInput.module.scss";
import { Props } from "./JobSearchInput.types";
import Button from "../Button";
import Icon from "../Icon";
import SearchIcon from "../../icons/SearchIcon";

const JobSearchInput: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get("search") || "";
  const [inputValue, setInputValue] = useState<string>(searchQuery || "");
  const [debouncedInputValue, setDebouncedInputValue] = useState<string>(
    searchQuery || "",
  );

  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const search = (value: string) => {
    if (value) {
      if (location.pathname?.includes("vacancies")) {
        navigate({ search: `?search=${value}` });
      } else {
        navigate(`vacancies?search=${value}`);
      }
    }
  };

  useEffect(() => {
    setInputValue(queryParams.get("search") || "");
  }, [location]);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      if (inputValue) {
        setDebouncedInputValue(inputValue);
      }
    }, 3000); // 3 seconds debounce time

    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [inputValue]);

  useEffect(() => {
    if (debouncedInputValue) {
      search(debouncedInputValue);
    }
  }, [debouncedInputValue]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (inputValue) {
      search(inputValue);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={cn(styles.root, className)}>
        <div className={styles.keyword}>
          <div className={styles.icon}>
            <Icon width={18} height={18}>
              <SearchIcon />
            </Icon>
          </div>
          <input
            className={styles.input}
            placeholder={t("filterSearchTitle") || ""}
            value={inputValue}
            onChange={handleInputChange}
          />
        </div>
        <Button
          variant="primary"
          size="lg"
          className={styles.button}
          onClick={handleSubmit}
          submit
        >
          {t("searchJobAndHousing")}
        </Button>
      </div>
    </form>
  );
};

export default JobSearchInput;
