import React from "react";

const BuildingIcon: React.FC = () => {
  return (
    <>
      <path
        d="M7.58341 12.8334H2.91675C1.75008 12.8334 1.16675 12.2501 1.16675 11.0834V6.41675C1.16675 5.25008 1.75008 4.66675 2.91675 4.66675H5.83341V11.0834C5.83341 12.2501 6.41675 12.8334 7.58341 12.8334Z"
        stroke="currentColor"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.89757 2.33325C5.85091 2.50825 5.83341 2.70075 5.83341 2.91659V4.66658H2.91675V3.49992C2.91675 2.85825 3.44175 2.33325 4.08341 2.33325H5.89757Z"
        stroke="currentColor"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.16675 4.66675V7.58341"
        stroke="currentColor"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 4.66675V7.58341"
        stroke="currentColor"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.91675 9.91675H8.75008C8.42925 9.91675 8.16675 10.1792 8.16675 10.5001V12.8334H10.5001V10.5001C10.5001 10.1792 10.2376 9.91675 9.91675 9.91675Z"
        stroke="currentColor"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 7.58325V9.91658"
        stroke="currentColor"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83325 11.0834V2.91675C5.83325 1.75008 6.41659 1.16675 7.58325 1.16675H11.0833C12.2499 1.16675 12.8333 1.75008 12.8333 2.91675V11.0834C12.8333 12.2501 12.2499 12.8334 11.0833 12.8334H7.58325C6.41659 12.8334 5.83325 12.2501 5.83325 11.0834Z"
        stroke="currentColor"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
};

export default BuildingIcon;
