import { get, post } from "..";

export const getMessages = async (id: string | undefined) => {
  return get<any>(`/chat/${id}/fetch-messages`);
};

export const getVacancyApplication = async (id: string | undefined) => {
  return get<any>(`/employer/vacancy-applications/${id}`);
};

export const getAppliedApplication = async (id: string | undefined) => {
  return get<any>(`/job-seeker/vacancy-applications/${id}`);
};

export const addMessage = async (content: string, id: number) => {
  return post<any>(`/chat/${id}/add-message`, { content });
};

export const getChat = async (id: number) => {
  return get<any>(`/chat/${id}`);
};

export const getEmployerChat = async (id: string | undefined) => {
  return get<any>(`/employer/vacancy/${id}/chat`);
};

export const postEmployerChatInit = async (id: number | undefined) => {
  return post<any>(`/employer/vacancy-applications/${id}/chat/init`);
};

export const postJobSeekerChatInit = async (id: number | undefined) => {
  return post<any>(`/job-seeker/vacancy-applications/${id}/chat/init`);
};
