import React from "react";
import styles from "./ApplyJobModal.module.scss";
import Modal from "../../common/Modal";
import { useAppDispatch } from "../../../hooks";
import { toggleApplyJobModal } from "../../../store/slices/uiSlice";
import ApplyJobCard from "../../cards/ApplyJobCard";

export type Props = {
  visible: boolean;
};

const ApplyJobModal: React.FC<Props> = ({ visible }) => {
  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(toggleApplyJobModal(null));
  };

  return (
    <Modal className={styles.root} visible={visible} onClose={() => onClose()}>
      <ApplyJobCard />
    </Modal>
  );
};

export default ApplyJobModal;
