import React from "react";
import AddIcon from "../../../assets/icons/add.svg";
import styles from "./AddCard.module.scss";

type Props = {
  title: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  onClick: () => void;
};

const AddCard = (props: Props) => {
  const { icon: Icon, title, onClick } = props;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      onClick();
    }
  };

  return (
    <div
      className={styles.add}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
    >
      <div className={styles.add__box}>
        <img src={AddIcon} alt="add" className={styles.add__icon} />
        <div className={styles.add__text}>
          <Icon />
          <span>{title}</span>
        </div>
      </div>
    </div>
  );
};

export default AddCard;
