import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getEmleyerVacancy } from "../../api/employer/jobs";
import MyJobs from "../../components/profile/MyJobs";
import { JobType } from "../../types/employer/job.type";

const MyJobsPage = () => {
  const { t } = useTranslation();
  const [error, setError] = useState<boolean>(false);
  const [jobs, setJobs] = useState<JobType[] | null>(null);
  const [capabilities, setCapabilities] = useState<any>({});
  const fetchData = () => {
    getEmleyerVacancy()
      .then((response: any) => {
        setJobs(response.data.items);
        setCapabilities(response.data.capabilities_meta);
      })
      .catch(() => {
        setError(true);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {jobs && (
        <MyJobs data={jobs} capabilities={capabilities} onUpdate={fetchData} />
      )}
      {error && (
        <div className="error-default">
          {t(
            "Füllen Sie die Geschäftsinformationen aus, um eine freie Stelle hinzuzufügen",
          )}
        </div>
      )}
    </>
  );
};

export default MyJobsPage;
