export const singleFileUpload = (field: File) => {
  const formData = new FormData();

  formData.append("files[]", field);

  return formData;
};

export const multiFileUpload = () => {
  //
};
