import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import styles from "./BusinessInformation.module.scss";
import UserCard from "../../common/UserCard";
import Input from "../../common/Input";
import Button from "../../common/Button";
import { CompanyType } from "../../../types/employer/company.type";
import { createCompany, updateCompany } from "../../../api/employer/company";
import { getMe } from "../../../api/employer/me";

type Props = {
  data: CompanyType | null;
};

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
});

const BusinessInformation = ({ data }: Props) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState<{
    success: boolean;
    error: boolean;
    hasData: boolean;
    message: string | null;
  }>({
    success: false,
    error: false,
    hasData: !!data,
    message: null,
  });

  const submit = async (values: any) => {
    if (status?.hasData || data) {
      try {
        await updateCompany(data?.id, {
          legal_name: values.name,
          vat_code: values.taxId,
          address: values.office,
          business_email: values.email,
          business_phone_number: values.phone,
        }).then(() => {
          getMe().then((response) => {
            localStorage.setItem(
              "user",
              JSON.stringify({
                firstName: response?.data?.first_name,
                companyId: response?.data?.company_id,
              }),
            );
          });
        });
        setStatus({
          success: true,
          error: false,
          hasData: true,
          message: "Success",
        });
      } catch (error: any) {
        setStatus({
          success: false,
          error: true,
          hasData: status.hasData,
          message: error?.response?.data?.message,
        });
      }
    } else {
      try {
        await createCompany({
          legal_name: values.name,
          vat_code: values.taxId,
          address: values.office,
          business_email: values.email,
          business_phone_number: values.phone,
        }).then(() => {
          getMe().then((response) => {
            localStorage.setItem(
              "user",
              JSON.stringify({
                firstName: response?.data?.first_name,
                companyId: response?.data?.company_id,
              }),
            );
          });
        });
        setStatus({
          success: true,
          error: false,
          hasData: true,
          message: "Success",
        });
        window.location.reload();
      } catch (error: any) {
        setStatus({
          success: false,
          error: true,
          hasData: status.hasData,
          message: error?.response?.data?.message,
        });
      }
    }
  };

  return (
    <>
      {/* <span className={styles.user__title}>Profile Settings</span> */}
      <div className={styles.user__info}>
        {data?.legal_name && (
          <UserCard
            fullName={`${data?.legal_name}`}
            date={t("businessManager") || ""}
            arrangement="horizontally"
            shownElements="hotelOnly"
            hasHotel
          />
        )}
      </div>
      <div className={styles.user__form}>
        <Formik
          initialValues={{
            name: data?.legal_name || "",
            phone: data?.business_phone_number || "",
            email: data?.business_email || "",
            taxId: data?.vat_code || "",
            office: data?.address || "",
          }}
          onSubmit={submit}
          validationSchema={SignupSchema}
        >
          {({ errors, touched }) => (
            <Form>
              <div className={styles.user__input__row}>
                <div
                  className={cn([
                    styles.user__input,
                    styles["user__input--left"],
                  ])}
                >
                  <Field
                    name="name"
                    label={t("companyName")}
                    component={Input}
                  />
                  {errors.name && touched.name ? (
                    <div className={styles.error}>{errors.name}</div>
                  ) : null}
                </div>
                <div
                  className={cn([
                    styles.user__input,
                    styles["user__input--right"],
                  ])}
                >
                  <Field
                    name="phone"
                    label={t("businessPhoneNumber")}
                    component={Input}
                  />
                  {errors.phone && touched.phone ? (
                    <div className={styles.error}>{errors.phone}</div>
                  ) : null}
                </div>
              </div>
              <div
                className={cn([
                  styles.user__input__row,
                  styles["user__input__row-2nd"],
                ])}
              >
                <div
                  className={cn([
                    styles.user__input,
                    styles["user__input--left-2nd"],
                  ])}
                >
                  <Field
                    name="email"
                    label={t("businessEmail")}
                    type="email"
                    component={Input}
                  />
                  {errors.email && touched.email ? (
                    <div className={styles.error}>{errors.email}</div>
                  ) : null}
                </div>
                <div
                  className={cn([
                    styles.user__input,
                    styles["user__input--right-2nd"],
                  ])}
                >
                  <Field name="taxId" label={t("taxId")} component={Input} />
                  {errors.taxId && touched.taxId ? (
                    <div className={styles.error}>{errors.taxId}</div>
                  ) : null}
                </div>
              </div>
              <div className={styles.user__input__row__address}>
                <div
                  className={cn([
                    styles.user__input,
                    styles.user__input__address,
                  ])}
                >
                  <Field
                    name="office"
                    label={t("headOffice")}
                    component={Input}
                  />
                  {errors.office && touched.office ? (
                    <div className={styles.error}>{errors.office}</div>
                  ) : null}
                </div>
                <Button variant="dark" submit>
                  {t("save")}
                </Button>
              </div>

              {status?.success && (
                <div className={styles.success}>{status?.message}</div>
              )}
              {status?.error && (
                <div className={styles.error}>{status?.message}</div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default BusinessInformation;
