import LeftNavIcon from "../../../assets/icons/nav-left.svg";
import RightNavIcon from "../../../assets/icons/nav-right.svg";
import styles from "./SliderNavButtons.module.scss";

type Props = {
  goPrev?: () => void;
  goNext?: () => void;
};

const defaultProps: Props = {
  goPrev: () => {},
  goNext: () => {},
};

const SliderNavButtons = ({ goPrev, goNext }: Props) => {
  return (
    <div className={styles.nav}>
      <button type="button" className={styles.nav__btn} onClick={goPrev}>
        <img src={LeftNavIcon} alt="prev" />
      </button>
      <button type="button" className={styles.nav__btn} onClick={goNext}>
        <img src={RightNavIcon} alt="next" />
      </button>
    </div>
  );
};

SliderNavButtons.defaultProps = defaultProps;

export default SliderNavButtons;
