import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/partials/Header";
import SignInModal from "../components/modals/SignInModal";
import SignUpModal from "../components/modals/SignUpModal";
import ForgotPasswordModal from "../components/modals/ForgotPasswordModal";
import ApplyJobModal from "../components/modals/ApplyJobModal";
import Footer from "../components/partials/Footer";
import { useAppDispatch, useAppSelector } from "../hooks";
import { authSuccess } from "../store/slices/authSlice";
import Navigation from "../components/partials/Navigation";
import MobileNav from "../components/partials/MobileNav";
import ResetPasswordModal from "../components/modals/ResetPasswordModal";

const DefaultLayout: React.FC = () => {
  const dispatch = useAppDispatch();

  const showNavigation = useAppSelector(
    (state: any) => state.ui.showNavigation,
  );
  const showSignInModal = useAppSelector(
    (state: any) => state.ui.showSignInModal,
  );
  const showSignUpModal = useAppSelector(
    (state: any) => state.ui.showSignUpModal,
  );
  const showForgotPasswordModal = useAppSelector(
    (state: any) => state.ui.showForgotPasswordModal,
  );
  const showResetPasswordModal = useAppSelector(
    (state: any) => state.ui.showResetPasswordModal,
  );
  const showApplyJobModal = useAppSelector(
    (state: any) => state.ui.showApplyJobModal,
  );

  if (localStorage.getItem("token")) {
    dispatch(authSuccess(localStorage.getItem("token")));
  }

  if (showSignInModal || showSignUpModal) {
    document.body.style.overflowY = "hidden";
  } else {
    document.body.style.overflowY = "auto";
  }

  return (
    <div style={{ overflowX: "hidden" }}>
      <Header />
      <Outlet />
      <SignInModal visible={showSignInModal} />
      <SignUpModal visible={showSignUpModal} />
      <ForgotPasswordModal visible={showForgotPasswordModal} />
      <ResetPasswordModal visible={showResetPasswordModal} />
      <ApplyJobModal visible={showApplyJobModal} />
      <Navigation visible={showNavigation} />
      <Footer />
      <MobileNav />
    </div>
  );
};

export default DefaultLayout;
