import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./AddHotelCard.module.scss";
import Card from "../../common/Card";
import AddHotelForm from "../../forms/AddHotelForm";
import { postAddHotel, postUpdateHotel } from "../../../api/employer/hotels";
import { useAppSelector } from "../../../hooks";

export type Props = {
  company: any;
  onUpdate: any;
};

const AddHotelCard: React.FC<Props> = ({ company, onUpdate }) => {
  const { t } = useTranslation();
  const modalData = useAppSelector((state: any) => state.ui.modalData);
  const [status, setStatus] = useState<{
    success: boolean;
    error: boolean;
    message: string | null;
  }>({
    success: false,
    error: false,
    message: null,
  });

  const onSubmit = async (values: any) => {
    try {
      if (modalData) {
        await postUpdateHotel(modalData?.id, values).then(() => {
          onUpdate();
        });
      } else {
        await postAddHotel(company?.id, values).then(() => {
          onUpdate();
        });
      }
    } catch (error: any) {
      setStatus({
        success: false,
        error: true,
        message: error?.response?.data?.message,
      });
    }
  };

  return (
    <Card
      className={styles.root}
      headerClassName={styles.header}
      bodyClassName={styles.body}
    >
      <div className={styles.header}>
        <div className={styles.left}>
          <div className={styles.title}>
            {modalData ? t("editHotel") : t("addHotel")}
          </div>
        </div>
      </div>
      <div className={styles.error}>{status?.message}</div>

      <AddHotelForm hotel={modalData} onSubmit={onSubmit} />
    </Card>
  );
};

export default AddHotelCard;
