import React from "react";
import styles from "./FooterRight.module.scss";
import FooterHelp from "../FooterHelp";

const FooterRight: React.FC = () => {
  return (
    <div className={styles.root}>
      <FooterHelp />
    </div>
  );
};

export default FooterRight;
