import React from "react";
import Container from "../components/common/Container";

const AboutPage = () => {
  return (
    <Container className="p-y-middle h-fit">
      <h1>Über "Workn Live UG"</h1>
      <p>
        Workn.Live bildet die Brücke zwischen Hotels, die junge, dynamische
        Arbeitskräfte suchen, und Studenten, die bereit sind, sich mit
        Leidenschaft und Engagement einzubringen, um von den zahlreichen
        Vorteilen eines Hotelumfelds zu profitieren. Unser Ziel ist es, diese
        beiden Gruppen zusammenzubringen und so eine Win-Win-Situation zu
        kreieren: Hotels gewinnen engagierte Mitarbeiter, während Studenten die
        Annehmlichkeiten des Hotelalltags genießen können. Bei Workn.Live steht
        nicht nur Arbeit oder Wohnen im Vordergrund, sondern die perfekte
        Symbiose aus beidem
      </p>
    </Container>
  );
};

export default AboutPage;
