import React, { useState } from "react";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import styles from "./JobCard.module.scss";
import Card from "../../common/Card";
import HeartIcon from "../../icons/HeartIcon";
import Button from "../../common/Button";
import Icon from "../../common/Icon";
import BuildingIcon from "../../icons/BuildingIcon";
// import LevelIcon from "../../icons/LevelIcon";
import LocationIcon from "../../icons/LocationIcon";
import { VacanciesDataItemType } from "../../../types/job-seeker/vacancies.type";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  toggleApplyJobModal,
  toggleSignInModal,
} from "../../../store/slices/uiSlice";
import { toggleFavourite } from "../../../api/job-seeker/vacancies";
import HeartFullIcon from "../../icons/HeartFullIcon";
import Link from "../../common/Link";

const JobCard: React.FC<VacanciesDataItemType> = ({
  id,
  title,
  subTitle,
  roomCost,
  hourlyCost,
  address,
  images,
  publishDate,
  isInFavourite,
  isApply,
  canApply,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isAuthenticated = useAppSelector(
    (state: any) => state.auth.isAuthenticated,
  );
  const [isInSave, setIsInSave] = useState<boolean>(isInFavourite || false);

  const onApplyJob = (item: VacanciesDataItemType) => {
    if (!isAuthenticated) {
      dispatch(toggleSignInModal("person"));
    }

    if (isApply || !canApply) {
      return;
    }

    dispatch(toggleApplyJobModal(item));
  };

  const onAddToFavourites = async (item: VacanciesDataItemType) => {
    if (!isAuthenticated) {
      dispatch(toggleSignInModal("person"));
    }

    try {
      await toggleFavourite(item);
      setIsInSave(!isInSave);
    } catch (error: any) {
      //
    }
  };

  return (
    <Card
      headerClassName={styles.header}
      bodyClassName={styles.body}
      className={styles.root}
    >
      <div className={styles.header}>
        <div className={styles.logo}>
          <img src={images} width="52" height="52" alt="brand" />
        </div>

        <div className={styles.actions}>
          <Button
            variant="transparent"
            icon={isInSave ? <HeartFullIcon /> : <HeartIcon />}
            iconSize={30}
            className={styles.save}
            onClick={() => onAddToFavourites({ id, isInFavourite: isInSave })}
          />
        </div>
      </div>

      <div className={styles.title}>{title}</div>
      <div className={styles.subtitle}>{subTitle}</div>

      <div className={styles.details}>
        <div className={styles.item}>
          <Icon width={14} height={14}>
            <BuildingIcon />
          </Icon>
          {t("roomCost")} <span>€{roomCost}</span>{" "}
          <small>/{t("monthly")}</small>
        </div>
        {/* <div className={styles.item}> */}
        {/*   <Icon width={14} height={14}> */}
        {/*     <LevelIcon /> */}
        {/*   </Icon> */}
        {/*   Room Cost */}
        {/* </div> */}
        <div className={styles.item}>
          <Icon width={14} height={14}>
            <LocationIcon />
          </Icon>
          {address}
        </div>
      </div>

      <div className={styles.cost}>
        <div className={styles.value}>
          €{hourlyCost} <small>/{t("hourly")}</small>
        </div>

        <div className={styles.date}>
          <Moment format="DD MMM YYYY" unix>
            {publishDate}
          </Moment>
        </div>
      </div>

      <div className={styles.footer}>
        <Link to={`/vacancy/${id}`}>
          <Button
            variant="dark"
            size="lg"
            outline
            block
            className={styles.button}
          >
            {t("viewDetails")}
          </Button>
        </Link>

        <Button
          variant={isApply || !canApply ? "secondary" : "primary"}
          size="lg"
          block
          className={styles.button}
          onClick={() =>
            onApplyJob({
              publish_date: "",
              id,
              title,
              subTitle,
            })
          }
        >
          {isApply ? t("isApplied") : t("applyNow")}
        </Button>
      </div>
    </Card>
  );
};

export default JobCard;
