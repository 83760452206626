import React from "react";
import cn from "classnames";
import CloseIcon from "../../../assets/icons/close.svg";
import { useAppDispatch } from "../../../hooks";
import { closeModals } from "../../../store/slices/uiSlice";
import styles from "./Card.module.scss";
import { setAuthError } from "../../../store/slices/authSlice";

/**
 * Card Props
 */
type CardProps = {
  title?: string;
  subtitle?: string;
  className?: string;
  headerClassName?: string;
  titleClassName?: string;
  bodyClassName?: string;
  withClose?: boolean;
  children?: React.ReactNode;
};

const defaultProps: Partial<CardProps> = {
  title: undefined,
  subtitle: undefined,
  className: undefined,
  headerClassName: undefined,
  titleClassName: undefined,
  bodyClassName: undefined,
  withClose: false,
  children: undefined,
};

/**
 *
 * @param title
 * @param subtitle
 * @param className
 * @param headerClassName
 * @param titleClassName
 * @param bodyClassName
 * @param children
 * @constructor
 */
const Card: React.FC<CardProps> = ({
  title = defaultProps.title,
  subtitle = defaultProps.subtitle,
  className = defaultProps.className,
  headerClassName = defaultProps.headerClassName,
  titleClassName = defaultProps.titleClassName,
  bodyClassName = defaultProps.bodyClassName,
  children = defaultProps.children,
  withClose = defaultProps.withClose,
}) => {
  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(setAuthError(null));
    dispatch(closeModals());
  };

  return (
    <div className={cn(styles.root, className)}>
      {withClose && (
        <button className={styles.exit} type="button" onClick={onClose}>
          <img src={CloseIcon} alt="close" />
        </button>
      )}
      {title && (
        <div className={cn(styles.header, headerClassName)}>
          <div className={cn(styles.title, titleClassName)}>{title}</div>
          {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        </div>
      )}
      <div className={cn(styles.body, bodyClassName)}>{children}</div>
    </div>
  );
};

Card.defaultProps = defaultProps;

export default Card;
