import React from "react";

const LocationIcon: React.FC = () => {
  return (
    <>
      <path
        d="M7.00002 7.83415C8.00518 7.83415 8.82002 7.01931 8.82002 6.01415C8.82002 5.00899 8.00518 4.19415 7.00002 4.19415C5.99486 4.19415 5.18002 5.00899 5.18002 6.01415C5.18002 7.01931 5.99486 7.83415 7.00002 7.83415Z"
        stroke="currentColor"
        strokeWidth="0.8"
      />
      <path
        d="M2.11167 4.95252C3.26084 -0.0991451 10.745 -0.0933116 11.8883 4.95836C12.5592 7.92169 10.7158 10.43 9.1 11.9817C7.9275 13.1134 6.0725 13.1134 4.89417 11.9817C3.28417 10.43 1.44084 7.91586 2.11167 4.95252Z"
        stroke="currentColor"
        strokeWidth="0.8"
      />
    </>
  );
};

export default LocationIcon;
