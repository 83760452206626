import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks";
import cn from "classnames";
import React, { useState } from "react";
import styles from "./SettingsForm.module.scss";
import UserCard from "../../common/UserCard";
import Input from "../../common/Input";
import Button from "../../common/Button";
import { EmployerType } from "../../../types/employer/employer.type";
import { updateEmploeyer, updateJobSeeker } from "../../../api/employer/me";
import { setUser } from "../../../store/slices/authSlice";

type Props = {
  user: EmployerType;
};

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
});

const SettingsForm = ({ user }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isBusiness = useAppSelector((state: any) => state.auth.isBusiness);
  const [error, setError] = useState<any>(null);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const submitEmployer = (data: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    password: string;
    confirmPassword: string;
    address: string;
  }) => {
    const { password, confirmPassword, firstName, lastName, phone } = data;
    setIsSuccess(false);
    setError(null);
    updateEmploeyer({
      first_name: firstName,
      last_name: lastName,
      password,
      password_confirmation: confirmPassword,
      phone,
    })
      .then((resp) => {
        dispatch(setUser({ firstName: resp?.data?.first_name }));
        localStorage.setItem(
          "user",
          JSON.stringify({ firstName: resp?.data?.first_name }),
        );
        setIsSuccess(true);
      })
      .catch((err) => {
        setError(err?.response?.data?.message);
      });
  };

  const submitJobSeeker = (data: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    password: string;
    confirmPassword: string;
    address: string;
  }) => {
    const { password, confirmPassword, firstName, lastName, phone } = data;
    setIsSuccess(false);
    setError(null);
    updateJobSeeker({
      first_name: firstName,
      last_name: lastName,
      password,
      password_confirmation: confirmPassword,
      phone,
    })
      .then((resp) => {
        dispatch(setUser({ firstName: resp?.data?.first_name }));
        localStorage.setItem(
          "user",
          JSON.stringify({ firstName: resp?.data?.first_name }),
        );
        setIsSuccess(true);
      })
      .catch((err) => {
        setError(err?.response?.data?.message);
      });
  };

  return (
    <>
      <span className={styles.user__title}>{t("profileSettings")}</span>
      <div className={styles.user__info}>
        <UserCard
          fullName={`${user.first_name} ${user.last_name}`}
          date={`ID: ${user.id}`}
          arrangement="horizontally"
          shownElements="hotelOnly"
          hasHotel
        />
      </div>
      <div className={styles.user__form}>
        <Formik
          initialValues={{
            firstName: user.first_name,
            lastName: user.last_name,
            email: user.email,
            phone: user.phone_number,
            password: "",
            confirmPassword: "",
            address: "",
          }}
          onSubmit={isBusiness ? submitEmployer : submitJobSeeker}
          validationSchema={ValidationSchema}
        >
          {({ errors, touched }) => (
            <Form>
              <div className={styles.user__input__row}>
                <div className={styles.user__input}>
                  <Field
                    name="firstName"
                    label={t("firstName")}
                    component={Input}
                  />
                  {errors.firstName && touched.firstName ? (
                    <div>{errors.firstName}</div>
                  ) : null}
                </div>
                <div className={styles.user__input}>
                  <Field
                    name="lastName"
                    label={t("lastName")}
                    component={Input}
                  />
                  {errors.lastName && touched.lastName ? (
                    <div>{errors.lastName}</div>
                  ) : null}
                </div>
                <div className={styles.user__input}>
                  <Field
                    name="password"
                    label={t("changePassword")}
                    type="password"
                    component={Input}
                  />
                  {errors.password && touched.password ? (
                    <div>{errors.password}</div>
                  ) : null}
                </div>
              </div>
              <div className={styles.user__input__row}>
                <div className={styles.user__input}>
                  <Field
                    name="email"
                    label={t("email")}
                    type="email"
                    component={Input}
                  />
                  {errors.email && touched.email ? (
                    <div>{errors.email}</div>
                  ) : null}
                </div>
                <div className={styles.user__input}>
                  <Field
                    name="phone"
                    label={t("telephone")}
                    component={Input}
                  />
                  {errors.phone && touched.phone ? (
                    <div>{errors.phone}</div>
                  ) : null}
                </div>
                <div className={styles.user__input}>
                  <Field
                    name="confirmPassword"
                    label={t("confirmPassword")}
                    type="password"
                    component={Input}
                  />
                  {errors.confirmPassword && touched.confirmPassword ? (
                    <div>{errors.confirmPassword}</div>
                  ) : null}
                </div>
              </div>
              <div className={styles.user__input__row__address}>
                <div
                  className={cn([
                    styles.user__input,
                    styles.user__input__address,
                  ])}
                >
                  <Field
                    name="address"
                    label={t("address")}
                    component={Input}
                  />
                </div>
                <Button variant="dark" submit>
                  {t("save")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>

        {error && <div className={styles.error}>{error}</div>}
        {isSuccess && (
          <div className={styles.success}>Profile updated successfully</div>
        )}
      </div>
    </>
  );
};

export default SettingsForm;
