import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./Hero.module.scss";
import Container from "../Container";
import Button from "../Button";
import Link from "../Link";
import { useAppSelector } from "../../../hooks";

const Hero = () => {
  const { t } = useTranslation();
  const isAuthenticated = useAppSelector(
    (state: any) => state.auth.isAuthenticated,
  );
  const isBusiness = useAppSelector((state: any) => state.auth.isBusiness);
  return (
    <div className={styles.root}>
      <Container className={styles.container}>
        <div className={styles.innerContainer}>
          <div className={styles.content}>
            <div className={styles.title}>{t("studentHeroTitle")}</div>
            <div className={styles.description}>{t("heroDescription")}</div>
            <div className={styles.actions}>
              {isAuthenticated && !isBusiness && (
                <Link to="/student">
                  <Button
                    variant="primary"
                    size="lg"
                    outline
                    className={styles.button}
                  >
                    {t("heroPersonButton")}
                  </Button>
                </Link>
              )}

              {isAuthenticated && isBusiness && (
                <Link to="/bussines">
                  <Button
                    variant="dark"
                    size="lg"
                    outline
                    className={styles.button}
                  >
                    {t("heroHotelButton")}
                  </Button>
                </Link>
              )}

              {!isAuthenticated && (
                <>
                  <Link to="/student">
                    <Button
                      variant="primary"
                      size="lg"
                      outline
                      className={styles.button}
                    >
                      {t("heroPersonButton")}
                    </Button>
                  </Link>

                  <Link to="/bussines">
                    <Button
                      variant="dark"
                      size="lg"
                      outline
                      className={styles.button}
                    >
                      {t("heroHotelButton")}
                    </Button>
                  </Link>
                </>
              )}
            </div>
          </div>

          <img src="/images/dummy-1.svg" alt="alt" className={styles.image} />
        </div>
      </Container>
    </div>
  );
};

export default Hero;
