import React from "react";
import Container from "../components/common/Container";

const ContactPage = () => {
  return (
    <Container className="p-y-middle h-fit">
      <h2>Schreiben Sie uns eine E-Mail: team@workn.live</h2>
    </Container>
  );
};

export default ContactPage;
