import cn from "classnames";
import Moment from "react-moment";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./FileCard.module.scss";
import DownloadIcon from "../../../assets/icons/download.svg";
import CancelIcon from "../../../assets/icons/cancel.svg";

type Props = {
  withCancel?: boolean;
  withDate?: boolean;
  withDownload?: boolean;
  blob?: boolean;
  label?: string;
  format?: string;
  file?: any;
  date?: string;
  className?: string;
  onCancel?: any;
};

const defaultProps: Props = {
  withCancel: false,
  withDate: false,
  withDownload: false,
  blob: false,
  label: "",
  format: "",
  file: null,
  date: "",
  className: "",
  onCancel: () => {},
};

const FileCard = ({
  withCancel,
  withDate,
  withDownload,
  blob,
  label,
  format,
  file,
  date,
  className,
  onCancel,
}: Props) => {
  const { t } = useTranslation();

  const openFile = async () => {
    if (file) {
      if (blob) {
        try {
          const token = localStorage.getItem("token");
          const headers = new Headers();
          headers.append("Accept", "application/pdf");
          if (token) {
            headers.append("Authorization", `Bearer ${token}`);
          }
          const response = await fetch(file?.url, {
            method: "GET",
            headers: headers as HeadersInit,
          });

          if (response.ok) {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "invoice.pdf"; // Specify the filename with ".pdf" extension
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
          } else {
            console.error("Failed to download file.");
          }
        } catch (error) {
          console.error("Error downloading file:", error);
        }
      } else {
        window.open(file?.url, "_blank");
      }
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={cn(styles.file, className)}
      onClick={openFile}
      style={{ cursor: file ? "pointer" : "initial" }}
    >
      <div className={styles.file__pdf}>
        <span>{format}</span>
      </div>
      <div className={styles.file__info}>
        <span className={styles.file__name}>{label}</span>
        {withDate && (
          <span className={styles.file__date}>
            <span>{t("uploadedOn")} </span>
            <span>
              <Moment format="DD/MM/YYYY" unix>
                {date}
              </Moment>
            </span>
          </span>
        )}
      </div>
      {withDownload && (
        <img src={DownloadIcon} alt="" className={styles.file__icon} />
      )}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
      <img
        src={CancelIcon}
        alt=""
        className={cn(styles.file__cancel, {
          [styles["file__cancel--active"]]: withCancel,
        })}
        onClick={onCancel}
      />
    </div>
  );
};

FileCard.defaultProps = defaultProps;

export default FileCard;
