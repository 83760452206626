import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styles from "./Pricing.module.scss";
import Button from "../../common/Button";
import { postPlan } from "../../../api/jobSeeker/jobs";

type Props = {
  plan: any;
  company: any;
  onChange: any;
};

const PricingCard = ({ plan, company, onChange }: Props) => {
  // const [sent, setSent] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  // eslint-disable-next-line consistent-return
  const onSubmit = () => {
    if (plan.pending_request) {
      navigate({ pathname: `/de/profile/invoices` });
      return false;
    }

    postPlan(company?.id, { plan_id: plan.id }).then(() => {
      onChange();
    });
  };

  return (
    <div className={styles.pricing__card}>
      <div className={styles.pricing__card__inner}>
        <div className={styles.pricing__card__item}>
          <span className={styles.pricing__card__title}>{plan.key}</span>
          <span className={styles.pricing__card__text}>
            Dies sind die Details dieses Plans. Um einen Plan auszuwählen,
            klicken Sie unten auf die Schaltfläche „Plan starten“. Nachdem Sie
            den Plan ausgewählt haben, können Sie die Rechnung anzeigen und die
            Zahlung vornehmen.
          </span>
        </div>

        {plan?.features?.map((feature: any) => (
          <div className={styles.pricing__card__item}>
            <span className={styles.pricing__card__title}>
              {t(feature.feature_key)}:{"    "}
              {feature.feature_value
                ? feature.feature_value
                : t("is_unlimitied")}
            </span>
          </div>
        ))}
      </div>
      <div className={styles.pricing__card__button}>
        <Button
          variant={
            plan.is_active
              ? "success"
              : plan.pending_request
              ? "dark"
              : plan?.can_request
              ? "primary"
              : "secondary"
          }
          disabled={!plan?.can_request && !plan?.pending_request}
          onClick={onSubmit}
        >
          {plan.is_active
            ? "Aktiv"
            : plan.pending_request
            ? "Rechnungen anzeigen"
            : "Plan starten"}
        </Button>
      </div>
    </div>
  );
};

export default PricingCard;
