import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  IResolveParams,
  // LoginSocialFacebook,
  LoginSocialGoogle,
} from "reactjs-social-login";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import styles from "./SignInCard.module.scss";
import Card from "../../common/Card";
import SignInForm from "../../forms/SignInForm";
import Button from "../../common/Button";
import { AuthType } from "../../../types/auth/auth.type";
import {
  loginByEmployer,
  loginByJobSeeker,
  socialLoginByEmployer,
  socialLoginByJobSeeker,
} from "../../../store/actions/authAction";
import { toggleSignUpModal } from "../../../store/slices/uiSlice";
import { setAuthError } from "../../../store/slices/authSlice";

const SignInCard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useAppSelector(
    (state: any) => state.auth.isAuthenticated,
  );
  const authType = useAppSelector((state: any) => state.ui.authType);
  const [isBusiness, setIsBusiness] = useState<boolean>(authType === "hotel");

  const error = useAppSelector((state: any) => state.auth.error);

  const onSubmit = async (values: AuthType) => {
    if (isBusiness) {
      dispatch(loginByEmployer(values));
    } else {
      dispatch(loginByJobSeeker(values));
    }
  };

  const onClickSignUp = (type: string) => {
    dispatch(toggleSignUpModal(type));
  };

  const onResolveSocialAuth = (provider: any, data: any) => {
    if (data?.accessToken || data?.access_token) {
      if (isBusiness) {
        dispatch(
          socialLoginByEmployer(provider, {
            token: data.accessToken || data?.access_token,
          }),
        );
      } else {
        dispatch(
          socialLoginByJobSeeker(provider, {
            token: data.accessToken || data?.access_token,
          }),
        );
      }
    }
  };

  useEffect(() => {
    dispatch(setAuthError(null));
  }, [isBusiness]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate({ pathname: `/de/profile` }, { replace: true });
    }
  }, [isAuthenticated]);
  const { t } = useTranslation();
  return (
    <Card
      className={styles.root}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      withClose
    >
      <div className={styles.header}>
        <div className={styles.left}>
          <div className={styles.title}>
            {t("signIn")} - {isBusiness ? "Business" : "Personal"}
          </div>
          <div className={styles.subtitle}>
            {t("signInWith")}
            <Button
              variant="transparent"
              className={styles.button}
              onClick={() => setIsBusiness(false)}
            >
              Personal
            </Button>{" "}
            {t("or")}
            <Button
              variant="transparent"
              className={styles.button}
              onClick={() => setIsBusiness(true)}
            >
              Business
            </Button>
            {t("account")}
          </div>
        </div>
      </div>
      <div className={styles.error}>{error}</div>

      <SignInForm isBusiness={isBusiness} onSubmit={onSubmit} />

      <div className={styles.divider}>
        <div className={styles.value}>{t("orWith")}</div>
      </div>

      <div className={styles.social}>
        <LoginSocialGoogle
          client_id="773906710715-2se6v09456b6rk3dcm8brlp23dulri2n.apps.googleusercontent.com"
          redirect_uri="https://workn.perse.pro"
          scope="openid profile email"
          discoveryDocs="claims_supported"
          access_type="offline"
          onResolve={({ provider, data }: IResolveParams) => {
            onResolveSocialAuth(provider, data);
          }}
          onReject={(err) => {
            console.log(err);
          }}
        >
          <Button
            variant="light"
            outline
            size="lg"
            block
            className={styles.button}
          >
            {t("loginGoogle")}
          </Button>
        </LoginSocialGoogle>

        {/* <LoginSocialFacebook */}
        {/*   appId="209397695377638" */}
        {/*   scope="public_profile" */}
        {/*   isOnlyGetToken */}
        {/*   onResolve={({ provider, data }: IResolveParams) => { */}
        {/*     onResolveSocialAuth(provider, data); */}
        {/*   }} */}
        {/*   onReject={(err) => { */}
        {/*     console.log(err); */}
        {/*   }} */}
        {/* > */}
        {/*   <Button */}
        {/*     variant="light" */}
        {/*     outline */}
        {/*     size="lg" */}
        {/*     block */}
        {/*     className={styles.button} */}
        {/*   > */}
        {/*     {t("loginFacebook")} */}
        {/*   </Button> */}
        {/* </LoginSocialFacebook> */}
      </div>

      <div className={styles.divider}>
        <div className={styles.value}>{t("dnAccount")}</div>
      </div>

      <div className={styles.additional}>
        <Button
          variant="transparent"
          className={styles.button}
          onClick={() => onClickSignUp("person")}
        >
          {t("signUpPerson")}
        </Button>
        <Button
          variant="transparent"
          className={styles.button}
          onClick={() => onClickSignUp("hotel")}
        >
          {t("signUpBusiness")}
        </Button>
      </div>
    </Card>
  );
};

export default SignInCard;
