import { Dispatch } from "react";
import {
  authFailure,
  authStart,
  authSuccess,
  setAuthLoading,
  setIsBusiness,
  setUser,
} from "../slices/authSlice";
import {
  postEmployerAuthForgotPassword,
  postEmployerAuthLogin,
  postEmployerAuthResetPassword,
  postEmployerAuthSignUp,
  postEmployerSocialLogin,
} from "../../api/employer/auth";
import {
  postJobSeekerAuthForgotPassword,
  postJobSeekerAuthLogin,
  postJobSeekerAuthResetPassword,
  postJobSeekerAuthSignUp,
  postJobSeekerSocialLogin,
} from "../../api/job-seeker/auth";
import { closeModals, toggleSignInModal } from "../slices/uiSlice";
import { getJobSeekerMe } from "../../api/job-seeker/me";
import { getMe } from "../../api/employer/me";

export const loginByEmployer = (data: any) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(authStart());

    try {
      await postEmployerAuthLogin(data).then(async (response) => {
        dispatch(authSuccess(response?.data?.access_token));
        dispatch(setIsBusiness(true));
        localStorage.setItem("isBusiness", "true");
        localStorage.setItem("token", response?.data?.access_token);

        await getMe().then((user) => {
          localStorage.setItem(
            "user",
            JSON.stringify({
              firstName: user?.data?.first_name,
              companyId: user?.data?.company_id,
            }),
          );
          dispatch(
            setUser({
              firstName: user?.data?.first_name,
            }),
          );
        });
        dispatch(closeModals());
      });
    } catch (error: any) {
      dispatch(authFailure(error?.response?.data?.message));
    }
  };
};

export const socialLoginByEmployer = (provider: string, data: any) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(authStart());

    try {
      await postEmployerSocialLogin(provider, data).then(async (response) => {
        dispatch(authSuccess(response?.data?.access_token));
        dispatch(setIsBusiness(true));
        localStorage.setItem("isBusiness", "true");
        localStorage.setItem("token", response?.data?.access_token);

        await getMe().then((user) => {
          localStorage.setItem(
            "user",
            JSON.stringify({
              firstName: user?.data?.first_name,
              companyId: user?.data?.company_id,
            }),
          );
          dispatch(
            setUser({
              firstName: user?.data?.first_name,
            }),
          );
        });
        dispatch(closeModals());
      });
    } catch (error: any) {
      dispatch(authFailure(error?.response?.data?.message));
    }
  };
};

export const loginByJobSeeker = (data: any) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(authStart());

    try {
      await postJobSeekerAuthLogin(data).then(async (response) => {
        dispatch(authSuccess(response?.data?.access_token));
        dispatch(setIsBusiness(false));
        localStorage.setItem("isBusiness", "false");
        localStorage.setItem("token", response?.data?.access_token);

        await getJobSeekerMe().then((user) => {
          localStorage.setItem(
            "user",
            JSON.stringify({ firstName: user?.data?.first_name }),
          );
          dispatch(
            setUser({
              firstName: user?.data?.first_name,
            }),
          );
        });
        dispatch(closeModals());
      });
    } catch (error: any) {
      dispatch(authFailure(error?.response?.data?.message));
    }
  };
};

export const socialLoginByJobSeeker = (provider: string, data: any) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(authStart());

    try {
      await postJobSeekerSocialLogin(provider, data).then(async (response) => {
        dispatch(authSuccess(response?.data?.access_token));
        dispatch(setIsBusiness(false));
        localStorage.setItem("isBusiness", "false");
        localStorage.setItem("token", response?.data?.access_token);

        await getJobSeekerMe().then((user) => {
          localStorage.setItem(
            "user",
            JSON.stringify({ firstName: user?.data?.first_name }),
          );
          dispatch(
            setUser({
              firstName: user?.data?.first_name,
            }),
          );
        });
        dispatch(closeModals());
      });
    } catch (error: any) {
      dispatch(authFailure(error?.response?.data?.message));
    }
  };
};

export const signUpByEmployer = (data: any) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(authStart());

    try {
      const response = await postEmployerAuthSignUp(data);

      dispatch(setIsBusiness(true));
      dispatch(authSuccess(response?.data?.access_token));
      localStorage.setItem("isBusiness", "true");
      localStorage.setItem("token", response?.data?.access_token as string);

      await getMe().then((user) => {
        localStorage.setItem(
          "user",
          JSON.stringify({ firstName: user?.data?.first_name }),
        );
        dispatch(
          setUser({
            firstName: user?.data?.first_name,
          }),
        );
      });
      dispatch(closeModals());
    } catch (error: any) {
      dispatch(authFailure(error?.response?.data?.message));
    }
  };
};

export const signUpByJobSeeker = (data: any) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(authStart());

    try {
      const response = await postJobSeekerAuthSignUp(data);

      localStorage.setItem("isBusiness", "false");
      localStorage.setItem("token", response?.data?.access_token as string);

      dispatch(authSuccess(response));
      dispatch(closeModals());
    } catch (error: any) {
      dispatch(authFailure(error?.response?.data?.message));
    }
  };
};

export const resetPasswordByEmployer = (data: any) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setAuthLoading(true));
    dispatch(authStart());

    try {
      const response = await postEmployerAuthForgotPassword(data);

      dispatch(authSuccess(response));
      dispatch(setAuthLoading(false));
    } catch (error: any) {
      dispatch(authFailure(error?.response?.data?.message));
      dispatch(setAuthLoading(false));
    }
  };
};

export const resetPasswordByJobSeeker = (data: any) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setAuthLoading(true));
    dispatch(authStart());

    try {
      const response = await postJobSeekerAuthForgotPassword(data);

      dispatch(authSuccess(response));
      dispatch(setAuthLoading(false));
    } catch (error: any) {
      dispatch(authFailure(error?.response?.data?.message));
      dispatch(setAuthLoading(false));
    }
  };
};

export const updatePasswordByEmployer = (data: any, type: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(authStart());

    try {
      await postEmployerAuthResetPassword(data);

      dispatch(closeModals());
      dispatch(toggleSignInModal(type));
    } catch (error: any) {
      dispatch(authFailure(error?.response?.data?.message));
      dispatch(closeModals());
    }
  };
};

export const updatePasswordByJobSeeker = (data: any, type: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(authStart());

    try {
      await postJobSeekerAuthResetPassword(data);
      dispatch(closeModals());
      dispatch(toggleSignInModal(type));
    } catch (error: any) {
      dispatch(authFailure(error?.response?.data?.message));
    }
  };
};
