import React from "react";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import cn from "classnames";
import styles from "./SignInForm.module.scss";
import Input from "../../common/Input";
import Button from "../../common/Button";
import { SignInSchema } from "../../../schemas/SignInSchema";
import { toggleForgotPasswordModal } from "../../../store/slices/uiSlice";
import { useAppDispatch } from "../../../hooks";

type Props = {
  isBusiness: boolean;
  onSubmit: any;
};

const SignInForm: React.FC<Props> = ({ isBusiness, onSubmit }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const initialValues = {
    email: "",
    password: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SignInSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched }) => (
        <Form>
          <div
            className={cn(
              styles.input,
              errors.email && touched.email ? styles.errored : "",
            )}
          >
            <Field
              name="email"
              label="Email"
              placeholder={t("enterEmail")}
              component={Input}
            />
            {errors.email && touched.email ? (
              <div className={styles.error}>{errors.email}</div>
            ) : null}
          </div>
          <div
            className={cn(
              styles.input,
              errors.password && touched.password ? styles.errored : "",
            )}
          >
            <Field
              name="password"
              label={t("password")}
              type="password"
              placeholder={t("enterPassword")}
              component={Input}
            />
            {errors.password && touched.password ? (
              <div className={styles.error}>{errors.password}</div>
            ) : null}
          </div>
          <div className={styles.flex}>
            <Button
              variant="transparent"
              className={styles.forgot}
              onClick={() =>
                dispatch(
                  toggleForgotPasswordModal(isBusiness ? "hotel" : "person"),
                )
              }
            >
              {t("Passwort vergessen?")}
            </Button>
          </div>
          <Button
            variant={isBusiness ? "dark" : "primary"}
            size="lg"
            block
            submit
          >
            {t("modalSignIn")}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default SignInForm;
