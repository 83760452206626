import { createSlice } from "@reduxjs/toolkit";

interface UiState {
  language: string;
  showNavigation: boolean;
  authType: "person" | "hotel";
  showSignInModal: boolean;
  showSignUpModal: boolean;
  showForgotPasswordModal: boolean;
  showResetPasswordModal: boolean;
  showApplyJobModal: boolean;
  showAddHotelModal: boolean;
  disableClose: boolean;
  modalData: object | null;
}

const initialState: UiState = {
  language: localStorage.getItem("lang") || "en",
  showNavigation: false,
  authType: "person",
  showSignInModal: false,
  showSignUpModal: false,
  showForgotPasswordModal: false,
  showResetPasswordModal: false,
  showApplyJobModal: false,
  showAddHotelModal: false,
  disableClose: false,
  modalData: null,
};

const uiSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLanguage(state: UiState, actions) {
      state.language = actions.payload;
    },

    toggleNavigation(state: UiState) {
      state.showNavigation = !state.showNavigation;
    },

    closeNavigation(state: UiState) {
      state.showNavigation = false;
    },

    closeModals(state: UiState) {
      state.showSignInModal = false;
      state.showSignUpModal = false;
      state.showForgotPasswordModal = false;
      state.showResetPasswordModal = false;
      state.showApplyJobModal = false;
    },

    toggleSignInModal(state: UiState, actions) {
      state.authType = actions.payload;
      state.showSignInModal = !state.showSignInModal;
      state.showSignUpModal = false;
    },

    toggleSignUpModal(state: UiState, actions) {
      state.authType = actions.payload;
      state.showSignUpModal = !state.showSignUpModal;
      state.showSignInModal = false;
    },

    toggleForgotPasswordModal(state: UiState, actions) {
      state.authType = actions.payload;
      state.showForgotPasswordModal = !state.showForgotPasswordModal;
      state.showSignInModal = false;
    },

    showResetPasswordModal(state: UiState, actions) {
      state.authType = actions.payload;
      state.showResetPasswordModal = true;
      state.showSignInModal = false;
      state.disableClose = true;
    },

    toggleApplyJobModal(state: UiState, actions) {
      state.modalData = actions.payload;
      state.showApplyJobModal = !state.showApplyJobModal;
    },

    toggleAddHotelModal(state: UiState, actions) {
      state.modalData = actions.payload;
      state.showAddHotelModal = !state.showAddHotelModal;
    },
  },
});

export const {
  toggleNavigation,
  closeNavigation,
  closeModals,
  toggleSignInModal,
  toggleSignUpModal,
  toggleForgotPasswordModal,
  showResetPasswordModal,
  toggleApplyJobModal,
  toggleAddHotelModal,
  setLanguage,
} = uiSlice.actions;

export default uiSlice.reducer;
