import React from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
// import { useAppSelector } from "../../../hooks";

interface Props extends RouterLinkProps {
  className?: string;
  children: React.ReactNode;
}

const defaultProps: Partial<Props> = {
  className: undefined,
};

/**
 *
 * @param to
 * @param className
 * @param children
 * @constructor
 */
const Link: React.FC<Props> = ({
  to,
  className = defaultProps.className,
  children,
}: Props) => {
  // const lang = useAppSelector((state: any) => state.ui.language);
  const lang = "de";

  return (
    <RouterLink to={`/${lang}${to}`} className={className}>
      {children}
    </RouterLink>
  );
};

Link.defaultProps = defaultProps;

export default Link;
