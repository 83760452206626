import React from "react";
import cn from "classnames";
import styles from "./Success.module.scss";
import Icon from "../Icon";
import TickIcon from "../../icons/TickIcon";

type Props = {
  title?: string;
};

const defaultProps: Props = {
  title: "",
};

const Success: React.FC<Props> = ({ title }) => {
  return (
    <div className={cn(styles.root)}>
      <div className={styles.icon}>
        <Icon width={24} height={24}>
          <TickIcon />
        </Icon>
      </div>
      {title}
    </div>
  );
};

Success.defaultProps = defaultProps;

export default Success;
