import { get } from "../index";
import { EmployerType } from "../../types/employer/employer.type";

export const getJobSeekerMe = async () => {
  return get<EmployerType>("/job-seeker/me");
};

export const getOther = async () => {
  return get<EmployerType>("/job-seeker/other");
};
