import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./ProfileList.module.scss";

type Props = {
  isBusiness?: boolean;
};

const defaultProps: Props = {
  isBusiness: false,
};

type Route = {
  title: string;
  route: string;
  activeRoutes: string[];
};

const ProfileList = ({ isBusiness }: Props) => {
  const { t } = useTranslation();
  const studentRoutes: Route[] = [
    {
      title: t("profileSettings"),
      route: "settings",
      activeRoutes: ["/de/profile/settings", "/profile"],
    },
    {
      title: t("appliedJobs"),
      route: "applied-jobs",
      activeRoutes: ["/de/profile/applied-jobs"],
    },
    {
      title: t("savedJobs"),
      route: "saved-jobs",
      activeRoutes: ["/de/profile/saved-jobs"],
    },
  ];

  const businessRoutes: Route[] = [
    {
      title: t("profileSettings"),
      route: "settings",
      activeRoutes: ["/profile/settings", "/profile"],
    },
    {
      title: t("bussinesInformation"),
      route: "business-information",
      activeRoutes: ["/profile/business-information"],
    },
    {
      title: t("myHotels"),
      route: "hotels",
      activeRoutes: ["/profile/hotels"],
    },
    {
      title: t("myJobs"),
      route: "jobs",
      activeRoutes: ["/profile/jobs"],
    },
    {
      title: t("recievedApplications"),
      route: "applications",
      activeRoutes: [
        "/profile/applications",
        "/profile/applications/conversation",
      ],
    },
    {
      title: t("invoices"),
      route: "invoices",
      activeRoutes: ["/profile/invoices"],
    },
    {
      title: t("pricing"),
      route: "pricing",
      activeRoutes: ["/profile/pricing"],
    },
  ];

  const profilePages = !isBusiness ? studentRoutes : businessRoutes;

  const { pathname } = useLocation();

  // const isActive = (route: string) => {

  // };

  return (
    <div className={styles.settings}>
      <ul className={styles.settings__list}>
        {profilePages.map((page) => (
          <li className={styles.settings__item}>
            <NavLink
              to={`/de/profile/${page.route}`}
              className={
                page.activeRoutes.includes(pathname) ? styles.active : ""
              }
            >
              {page.title}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

ProfileList.defaultProps = defaultProps;

export default ProfileList;
