import { JobType } from "../../types/employer/job.type";
import { get, post } from "..";
import { ContentType } from "../../types/content.type";

export const getAppliedJobs = async () => {
  return get<ContentType<JobType[]>>("/job-seeker/vacancy-applications");
};

export const getSavedJobs = async () => {
  return get<ContentType<JobType[]>>("/job-seeker/vacancy/favorite");
};

export const getPlans = async (id: number) => {
  return get<ContentType<any>>(`/employer/company-plan/${id}`);
};

export const postPlan = async (id: number, data: any) => {
  return post<ContentType<any>>(`/employer/company/invoice/${id}`, data);
};
