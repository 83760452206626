import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./SearchSection.module.scss";
import Section from "../../common/Section";
import JobSearchInput from "../../common/JobSearchInput";
import Icon from "../../common/Icon";
import VerifyIcon from "../../icons/VerifyIcon";

const SearchSection = () => {
  const { t } = useTranslation();
  const items = [
    {
      title: "Unterkunft",
    },
    {
      title: "Kostenlose Verpflegung",
    },
    {
      title: "Zeit/Geld sparen",
    },
    {
      title: "Hotelprodukten",
    },
    {
      title: "Workn.live Unterstützung",
    },
    {
      title: "Andere Vorteile",
    },
  ];

  return (
    <Section
      className={styles.root}
      bodyClassName={styles.body}
      title={t("searchSectionTitle") || ""}
    >
      <div className={styles.content}>
        <div className={styles.content__items}>
          {items.map((item) => (
            <div className={styles.tag}>
              <Icon width={24} height={24}>
                <VerifyIcon />
              </Icon>
              {item?.title}
            </div>
          ))}
        </div>
        <JobSearchInput className={styles.search} />
      </div>
    </Section>
  );
};

export default SearchSection;
