import React from "react";
import Link from "../../common/Link";
import styles from "./HeaderLogo.module.scss";
import LogoIcon from "../../icons/LogoIcon";
import LogoWhiteIcon from "../../icons/LogoWiteIcon";

type Props = {
  isBusiness: boolean;
};

const HeaderLogo: React.FC<Props> = ({ isBusiness }) => {
  return (
    <Link to="/" className={styles.logo}>
      {isBusiness ? <LogoWhiteIcon /> : <LogoIcon />}
    </Link>
  );
};

export default HeaderLogo;
