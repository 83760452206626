import { NavLink } from "react-router-dom";
import BuildingIcon from "../../../../assets/icons/buildings.svg";
import ActiveBuildingIcon from "../../../../assets/icons/activebuildings.svg";
import TaskIcon from "../../../../assets/icons/task.svg";
import ActiveTaskIcon from "../../../../assets/icons/activetask.svg";
import PersonalCardIcon from "../../../../assets/icons/personalcard.svg";
import ActivePersonalCardIcon from "../../../../assets/icons/activepersonalcard.svg";
import styles from "../MobileNav.module.scss";

const BussinesMobileNav = ({ lang }: any) => {
  return (
    <div className={styles.nav}>
      <NavLink to={`/${lang}/profile/hotels`} end>
        {({ isActive }) =>
          isActive ? (
            <img src={ActiveBuildingIcon} alt="profile" />
          ) : (
            <img src={BuildingIcon} alt="profile" />
          )
        }
      </NavLink>
      <NavLink to={`/${lang}/profile/jobs`} end>
        {({ isActive }) =>
          isActive ? (
            <img src={ActiveTaskIcon} alt="search" />
          ) : (
            <img src={TaskIcon} alt="search" />
          )
        }
      </NavLink>
      <NavLink to={`/${lang}/profile`} end>
        {({ isActive }) =>
          isActive ? (
            <img src={ActivePersonalCardIcon} alt="home" />
          ) : (
            <img src={PersonalCardIcon} alt="home" />
          )
        }
      </NavLink>
    </div>
  );
};

export default BussinesMobileNav;
