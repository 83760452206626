import React from "react";
import cn from "classnames";
import styles from "./Header.module.scss";
import Container from "../../common/Container";
import HeaderLeft from "../HeaderLeft";
import HeaderRight from "../HeaderRight";
import { useAppSelector } from "../../../hooks";

const Header: React.FC = () => {
  const isBusiness = useAppSelector((state: any) => state.auth.isBusiness);
  const user = useAppSelector((state: any) => state.auth.user);

  return (
    <header
      className={cn(styles.root, {
        [styles.dark]: isBusiness,
      })}
    >
      <Container className={styles.container}>
        <HeaderLeft isBusiness={isBusiness} />
        <HeaderRight isBusiness={isBusiness} user={user} />
      </Container>
    </header>
  );
};

export default Header;
