import { NavLink } from "react-router-dom";
import HomeIcon from "../../../../assets/icons/home.svg";
import ActiveHomeIcon from "../../../../assets/icons/activehome.svg";
import SearchIcon from "../../../../assets/icons/search-normal.svg";
import ActiveSearchIcon from "../../../../assets/icons/active-search-normal.svg";
import ProfileIcon from "../../../../assets/icons/user.svg";
import ActiveProfileIcon from "../../../../assets/icons/activeUser.svg";
import styles from "../MobileNav.module.scss";
import {
  closeModals,
  toggleSignInModal,
} from "../../../../store/slices/uiSlice";
import { useAppDispatch } from "../../../../hooks";

const DefaultMobileNav = ({ lang }: any) => {
  const dispatch = useAppDispatch();

  const onClickSignIn = () => {
    dispatch(toggleSignInModal("person"));
  };

  const onCloseModals = () => {
    dispatch(closeModals());
  };

  return (
    <div className={styles.nav}>
      <NavLink to={`/${lang}`} onClick={onCloseModals} end>
        {({ isActive }) =>
          isActive ? (
            <img src={ActiveHomeIcon} alt="home" />
          ) : (
            <img src={HomeIcon} alt="home" />
          )
        }
      </NavLink>
      <NavLink to={`/${lang}/vacancies`} onClick={onCloseModals} end>
        {({ isActive }) =>
          isActive ? (
            <img src={ActiveSearchIcon} alt="home" />
          ) : (
            <img src={SearchIcon} alt="home" />
          )
        }
      </NavLink>
      <NavLink to={`/${lang}/`} onClick={onClickSignIn} end>
        {({ isActive }) =>
          isActive ? (
            <img src={ActiveProfileIcon} alt="home" />
          ) : (
            <img src={ProfileIcon} alt="home" />
          )
        }
      </NavLink>
    </div>
  );
};

export default DefaultMobileNav;
