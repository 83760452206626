import React from "react";
import cn from "classnames";
import styles from "./BackdropDropdown.module.scss";
import Backdrop from "../Backdrop";

interface Props {
  children: React.ReactNode;
  button: React.ReactNode;
  visible: boolean;
  // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
  bottom?: boolean;
  bodyClassName?: string;
  onClose: any;
}

const defaultProps: Partial<Props> = {
  bodyClassName: undefined,
};

/**
 *
 * @param children
 * @param button
 * @param visible
 * @param bottom
 * @param bodyClassName
 * @param onClose
 * @constructor
 */
const BackdropDropdown: React.FC<Props> = ({
  children,
  button,
  visible,
  bottom,
  bodyClassName,
  onClose,
}: Props) => (
  <div className={styles.root}>
    {button}
    {visible && (
      <>
        <div
          className={cn(styles.item, {
            [styles.bottom]: bottom,
          })}
        >
          <div className={cn(styles.body, bodyClassName)}>{children}</div>
        </div>
        <Backdrop onClick={onClose} />
      </>
    )}
  </div>
);

BackdropDropdown.defaultProps = defaultProps;

export default BackdropDropdown;
