import { NavLink } from "react-router-dom";
import PersonIcon from "../../../../assets/icons/user.svg";
import ActivePersonIcon from "../../../../assets/icons/activeUser.svg";
import ActiveBriefCaseIcon from "../../../../assets/icons/defaultBriefCase.svg";
import BriefCaseIcon from "../../../../assets/icons/briefcase.svg";
import HeartIcon from "../../../../assets/icons/heart.svg";
import ActiveHeartIcon from "../../../../assets/icons/activeHeart.svg";
import styles from "../MobileNav.module.scss";

const PersonMobileNav = ({ lang }: any) => {
  return (
    <div className={styles.nav}>
      <NavLink to={`/${lang}/profile`} end>
        {({ isActive }) =>
          isActive ? (
            <img src={ActivePersonIcon} alt="home" />
          ) : (
            <img src={PersonIcon} alt="profile" />
          )
        }
      </NavLink>
      <NavLink to={`/${lang}/profile/applied-jobs`} end>
        {({ isActive }) =>
          isActive ? (
            <img src={ActiveBriefCaseIcon} alt="active briefcase" />
          ) : (
            <img src={BriefCaseIcon} alt="briefcase" />
          )
        }
      </NavLink>

      <NavLink to={`/${lang}/profile/saved-jobs`} end>
        {({ isActive }) =>
          isActive ? (
            <img src={ActiveHeartIcon} alt="active saved" />
          ) : (
            <img src={HeartIcon} alt="saved" />
          )
        }
      </NavLink>
    </div>
  );
};

export default PersonMobileNav;
