import React from "react";
import Profile from "../components/profile/Index";

type Props = {
  children: React.ReactNode;
};

const ProfileLayout: React.FC<Props> = ({ children }) => {
  return <Profile>{children}</Profile>;
};

export default ProfileLayout;
