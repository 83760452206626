import React, { useEffect, useState } from "react";
import styles from "./AddHotelModal.module.scss";
import Modal from "../../common/Modal";
import { useAppDispatch } from "../../../hooks";
import { toggleAddHotelModal } from "../../../store/slices/uiSlice";
import AddHotelCard from "../../cards/AddHotelCard";
import { getCompany } from "../../../api/employer/company";
import { CompanyType } from "../../../types/employer/company.type";

export type Props = {
  visible: boolean;
  onUpdate: any;
};

const AddHotelModal: React.FC<Props> = ({ visible, onUpdate }) => {
  const dispatch = useAppDispatch();
  const [company, setCompany] = useState<CompanyType | null>(null);

  const onClose = () => {
    dispatch(toggleAddHotelModal(null));
  };

  useEffect(() => {
    getCompany().then((response) => {
      setCompany(response.data.items[0]);
    });
  }, []);

  return (
    <Modal className={styles.root} visible={visible} onClose={() => onClose()}>
      <AddHotelCard company={company} onUpdate={onUpdate} />
    </Modal>
  );
};

export default AddHotelModal;
