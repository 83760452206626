import React from "react";
import Container from "components/common/Container";
import InputsGroup from "components/common/IputsGroup/InputsGroup";
import backgroundImage from "../../../../assets/images/StudentsLandingBackground@3.png";
import styles from "./StudentsLandingSection.module.scss";

function StudentsLandingCover() {
  return (
    <div className={styles.container}>
      <img
        src={backgroundImage}
        alt="background"
        className={styles.backgroundImage}
      />
      <Container className={styles.center}>
        <div className={styles.contentContainer}>
          <p className={styles.header}>
            Finden Sie ganz einfach Job und Wohnung gleichzeitig
          </p>
          <InputsGroup />
        </div>
      </Container>
    </div>
  );
}

export default StudentsLandingCover;
