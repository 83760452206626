import React from "react";
import styles from "./CatalogContent.module.scss";
import Container from "../../common/Container";
import JobCard from "../../cards/JobCard";
import JobSearchInput from "../../common/JobSearchInput";
import { VacanciesDataItemType } from "../../../types/job-seeker/vacancies.type";
import { stringTruncate } from "../../../helpers/string";

export type Props = {
  items: VacanciesDataItemType[];
};

const CatalogContent: React.FC<Props> = ({ items }) => {
  return (
    <div className={styles.root}>
      <Container className={styles.container}>
        <JobSearchInput />
        <div className={styles.body}>
          <div className={styles.items}>
            {items.map((item: VacanciesDataItemType) => (
              <JobCard
                key={item.id}
                id={item.id}
                title={stringTruncate(item?.title || "", 26)}
                subTitle={item?.business_entity?.name}
                images={item?.images?.[0]?.url || "/images/placeholder.jpg"}
                roomCost={item.room_daily_cost}
                hourlyCost={item.hourly_rate}
                address={item?.business_entity?.address}
                publishDate={item?.publish_date}
                isInFavourite={item?.capabilities_meta?.is_in_favorite}
                isApply={item?.capabilities_meta?.is_apply}
                canApply={item?.capabilities_meta?.can_apply}
              />
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CatalogContent;
