import { createSlice } from "@reduxjs/toolkit";

interface AuthState {
  isAuthenticated: boolean;
  isBusiness: boolean;
  loading: boolean;
  token: string | null;
  user: object;
  error: string | null;
  success: string | null;
}

const initialState: AuthState = {
  loading: false,
  isAuthenticated: false,
  isBusiness: JSON.parse(localStorage.getItem("isBusiness") || "false"),
  token: localStorage.getItem("token") || null,
  user: JSON.parse(localStorage.getItem("user") || "{}"),
  error: null,
  success: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authStart(state: AuthState) {
      state.isAuthenticated = false;
      state.token = null;
      state.error = null;
    },

    authSuccess(state: AuthState, actions) {
      state.isAuthenticated = !!state.token;
      state.token = actions.payload;
      state.error = null;
      state.success = actions.payload;
    },

    authFailure(state: AuthState, actions) {
      state.isAuthenticated = false;
      state.token = null;
      state.error = actions.payload;
    },

    setUser(state: AuthState, actions) {
      state.user = actions.payload;
    },

    setIsBusiness(state: AuthState, actions) {
      state.isBusiness = actions.payload;
    },

    authOut(state: AuthState) {
      state.user = {};
      state.isAuthenticated = false;
      state.isBusiness = false;
      state.token = null;
      state.error = null;
      state.success = null;
    },

    setAuthError(state: AuthState, actions) {
      state.error = actions.payload;
    },

    setAuthLoading(state: AuthState, actions) {
      state.loading = actions.payload;
    },
  },
});

export const {
  authStart,
  authSuccess,
  authFailure,
  setUser,
  setIsBusiness,
  authOut,
  setAuthError,
  setAuthLoading,
} = authSlice.actions;

export default authSlice.reducer;
