import React from "react";
import { useLocation } from "react-router-dom";
import cn from "classnames";
import styles from "./Profile.module.scss";

type Props = {
  children: React.ReactNode;
};

const ProfileCard = ({ children }: Props) => {
  const { pathname } = useLocation();

  return (
    <div
      className={cn(styles.profile__card, {
        [styles["profile__card--fixed-height"]]:
          pathname === "/profile/pricing",
        [styles.profile__card__unset]:
          pathname === "/profile/applications/conversation",
      })}
    >
      {children}
    </div>
  );
};

export default ProfileCard;
