import React from "react";
import cn from "classnames";
import styles from "./Modal.module.scss";
import Backdrop from "../Backdrop";

export type Props = {
  visible: boolean;
  children: React.ReactNode;
  className: string;
  onClose: any;
};

const Modal: React.FC<Props> = ({ visible, children, className, onClose }) => {
  return (
    <>
      {visible && (
        <>
          <div className={cn(styles.root, className)}>{children}</div>
          <Backdrop onClick={() => onClose()} />
        </>
      )}
      <div />
    </>
  );
};

export default Modal;
