import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./RichTextEditor.scss";

type Props = {
  contentValue?: string;
  onChange?: (value: string) => void;
};

const defaultProps: Props = {
  contentValue: "",
  onChange: () => {},
};

const RichTextEditor = ({ contentValue, onChange }: Props) => {
  const [content, setContent] = useState<string>(contentValue || "");

  const handleChange = (value: string) => {
    setContent(value);
    onChange?.(value);
  };

  return (
    <ReactQuill
      style={{ height: "300px" }}
      value={content}
      onChange={handleChange}
    />
  );
};

RichTextEditor.defaultProps = defaultProps;

export default RichTextEditor;
