import React, { useState } from "react";
import { VacanciesDataItemType } from "types/job-seeker/vacancies.type";
import { toggleFavourite } from "api/job-seeker/vacancies";
import cs from "classnames";
import { useTranslation } from "react-i18next";
import Button from "../Button";
import styles from "./SaveForLateButton.module.scss";
import { toggleSignInModal } from "../../../store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks";

type Props = {
  isInFavourite: boolean | undefined;
  id: number | undefined;
  className: any;
};
function SaveForLaterButton({ isInFavourite, id, className }: Props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isAuthenticated = useAppSelector(
    (state: any) => state.auth.isAuthenticated,
  );
  const [isInSave, setIsInSave] = useState<boolean>(isInFavourite || false);

  const onAddToFavourites = async (item: VacanciesDataItemType) => {
    if (!isAuthenticated) {
      dispatch(toggleSignInModal("person"));
    }

    try {
      await toggleFavourite(item);
      setIsInSave(!isInSave);
    } catch (error: any) {
      //
    }
  };

  return (
    <Button
      variant="dark"
      onClick={() => onAddToFavourites({ id, isInFavourite: isInSave })}
      className={cs(styles.button, className)}
    >
      {isInSave ? t("removeFromFavourites") : t("saveForLater")}
    </Button>
  );
}

export default SaveForLaterButton;
