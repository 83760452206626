import { get } from "../index";
import { EmployerType } from "../../types/employer/employer.type";

export const getJobSeeker = async () => {
  return get<EmployerType>("/job-seeker/me");
};

export const getBusinessInformation = () => {
  return get("/job-seeker/vacancy-applications");
};
