import React, { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import cs from "classnames";
import { useNavigate } from "react-router-dom";
import SearchIcon from "../../../assets/icons/search-normal.svg";
// import locationIcon from "../../../assets/icons/location.svg";
import styles from "./InputsGroup.module.scss";

function InputsGroup({ className }: any) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const inputs = [
    { placeholder: "Suchen und finden", icon: SearchIcon },
    // { placeholder: "Search by location", icon: locationIcon },
  ];
  const [inputValue, setInputValue] = useState<string>("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const search = (e: FormEvent) => {
    e.preventDefault();
    if (inputValue) navigate(`/de/vacancies?search=${inputValue}`);
  };
  return (
    <div className={cs(styles.inputContainer, className)}>
      <div className={styles.divider} />
      <form onSubmit={search}>
        <div className={styles.flex}>
          {inputs.map((item) => (
            <div className={styles.inputWrapper}>
              <img src={item.icon} alt="icon" className={styles.inputIcon} />
              <input
                type="text"
                placeholder={item.placeholder}
                className={styles.input}
                key={item.placeholder}
                onChange={handleInputChange}
              />
            </div>
          ))}
        </div>
      </form>
      <button type="button" onClick={search} className={styles.redButton}>
        <p className={styles.buttonLabel}> {t("Suche starten")}</p>
      </button>
    </div>
  );
}

export default InputsGroup;
