import React, { useState } from "react";
import cn from "classnames";
import styles from "./AttachFile.module.scss";
import Icon from "../Icon";
import AttachmentIcon from "../../icons/AttachmentIcon";
import FileCard from "../FileCard";
import { stringTruncate } from "../../../helpers/string";

type Props = {
  className?: string;
  onSelect?: any;
};

const defaultProps: Props = {
  className: "",
  onSelect: () => {},
};

const AttachFile: React.FC<Props> = ({ className, onSelect }) => {
  const [label, setLabel] = useState<string>("");

  const onChange = (event: any) => {
    onSelect(event.currentTarget.files[0]);
    setLabel(event.currentTarget.files[0].name);
  };

  const onCancel = () => {
    setLabel("");
  };

  return (
    <div className={cn(styles.root, className)}>
      {label ? (
        <FileCard
          className={styles.card}
          label={stringTruncate(label, 20)}
          format="PDF"
          withCancel
          onCancel={onCancel}
        />
      ) : (
        <>
          <label htmlFor="attachFileButton" className={styles.label}>
            <Icon width={14} height={16}>
              <AttachmentIcon />
            </Icon>
            Attach Your Resume
          </label>
          <input
            type="file"
            id="attachFileButton"
            className={styles.input}
            onChange={onChange}
          />
        </>
      )}
    </div>
  );
};

AttachFile.defaultProps = defaultProps;

export default AttachFile;
