import React, { useEffect, useState } from "react";
import SaveForLaterButton from "components/common/SaveForLateButotn";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import styles from "./JobContent.module.scss";
import JobHeader from "../JobHeader";
import Container from "../../common/Container";
import Button from "../../common/Button";
import Icon from "../../common/Icon";
import NoteIcon from "../../icons/NoteIcon";
import { VacancyDataItemType } from "../../../types/job-seeker/vacancy.type";
import BuildingIcon from "../../icons/BuildingIcon";
import LocationIcon from "../../icons/LocationIcon";
import { VacanciesDataItemType } from "../../../types/job-seeker/vacancies.type";
import {
  toggleApplyJobModal,
  toggleSignInModal,
} from "../../../store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks";

export type Props = {
  item: VacancyDataItemType | undefined | null;
};

const JobContent: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [canApplyInvocie, setCanApplyInvocie] = useState<any>(
    item?.capabilities_meta?.can_apply || false,
  );
  const isAuthenticated = useAppSelector(
    (state: any) => state.auth.isAuthenticated,
  );

  const onApplyJob = (vacancy: VacanciesDataItemType) => {
    if (!isAuthenticated) {
      dispatch(toggleSignInModal("person"));
    } else {
      dispatch(toggleApplyJobModal(vacancy));
      setCanApplyInvocie(false);
    }
  };

  useEffect(() => {
    setCanApplyInvocie(item?.capabilities_meta?.can_apply);
  }, [item]);

  return (
    <>
      {item?.id && (
        <JobHeader
          id={item?.id}
          title={item?.title}
          subTitle={item?.business_entity?.name}
          images={item?.images?.[0]?.url || "/images/placeholder.jpg"}
          isInFavourite={item?.capabilities_meta?.is_in_favorite || false}
        />
      )}

      {item?.id && (
        <div className={styles.root}>
          <Container className={styles.container}>
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: item?.description || "" }}
            />
            <div className={styles.details}>
              <Button
                variant={!canApplyInvocie ? "secondary" : "primary"}
                block
                disabled={isAuthenticated && !canApplyInvocie}
                onClick={() =>
                  onApplyJob({
                    id: item?.id,
                    title: item?.title,
                    subTitle: item?.business_entity?.name,
                  })
                }
              >
                {item.capabilities_meta?.is_apply
                  ? t("isApplied")
                  : t("applyNow")}
              </Button>
              <SaveForLaterButton
                isInFavourite={item?.capabilities_meta?.is_in_favorite || false}
                id={item?.id}
                className={styles.saveButton}
              />

              <div className={styles.item}>
                <div className={styles.title}>
                  <Icon width={20} height={20}>
                    <NoteIcon />
                  </Icon>
                  {t("datePosted")}
                </div>
                <div className={styles.subtitle}>
                  <Moment format="MM/DD/YYYY" unix>
                    {item?.publish_date}
                  </Moment>
                </div>
              </div>

              <div className={styles.item}>
                <div className={styles.title}>
                  <Icon width={20} height={18}>
                    <LocationIcon />
                  </Icon>
                  {t("location")}
                </div>
                <div className={styles.subtitle}>
                  {item?.business_entity?.address}
                </div>
              </div>

              <div className={styles.item}>
                <div className={styles.title}>
                  <Icon width={20} height={20}>
                    <BuildingIcon />
                  </Icon>
                  {t("roomCost")}
                </div>
                <div className={styles.subtitle}>
                  <span>€{item?.room_daily_cost}</span>
                  <small>/{t("monthly")}</small>
                </div>
              </div>
            </div>
          </Container>

          <Container className={styles.photos}>
            <div className={styles.title}>{t("livingRoomPhotos")}</div>

            <div className={styles.body}>
              {item?.images?.map((image: any) => (
                <img src={image?.url} alt={item?.title} />
              ))}
            </div>
          </Container>
        </div>
      )}
    </>
  );
};

export default JobContent;
