import React from "react";
import cn from "classnames";
import Moment from "react-moment";
import styles from "./UserCard.module.scss";
import UserAvatar from "../UserAvatar";

type User = {
  name: string;
  image?: string;
  id?: string;
};

interface Props {
  shownElements?: "nameOnly" | "hotelOnly" | "idOnly" | "appliedOnly" | "";
  arrangement?: "vertically" | "horizontally";
  avatarSize?: "sm" | "md" | "lg";
  data?: any;
  date?: string;
  fullName?: string;
  image?: string;
  hasHotel?: boolean;
}

interface ChildProps extends Partial<Props> {
  children: React.ReactNode;
}

const defaultProps: Required<Props> = {
  arrangement: "horizontally",
  avatarSize: "lg",
  shownElements: "",
  data: {} as User,
  date: "",
  fullName: "",
  image: "",
  hasHotel: false,
};

const CardName: React.FC<ChildProps> = ({ children }) => (
  <span className={styles.card__name}>{children}</span>
);

const CardProfession: React.FC<ChildProps> = ({ shownElements, children }) =>
  !shownElements ? (
    <span className={styles.card__profession}>{children}</span>
  ) : null;

const CardId: React.FC<ChildProps> = ({ shownElements, children }) =>
  !shownElements ? <span className={styles.card__id}>{children}</span> : null;

const Hotel: React.FC<ChildProps> = ({ shownElements, children }) =>
  shownElements === "hotelOnly" ? (
    <span className={styles.card__id}>{children}</span>
  ) : null;

// const Id: React.FC<ChildProps> = ({ shownElements, children }) =>
//   shownElements === "idOnly" ? (
//     <span className={styles.card__id}>{children}</span>
//   ) : null;

const Applied: React.FC<ChildProps> = ({ shownElements, children }) =>
  shownElements === "appliedOnly" ? (
    <span className={styles.card__id}>{children}</span>
  ) : null;

const UserCard = ({
  shownElements,
  avatarSize,
  arrangement = defaultProps.arrangement,
  data,
  date,
  fullName,
  hasHotel,
  image,
}: Props) => {
  const className = cn(styles.card, {
    [styles[arrangement]]: arrangement,
    [styles.card__md__texts]: avatarSize === "md",
  });

  return (
    <div className={className}>
      <UserAvatar
        src={image || null}
        size={avatarSize}
        centered={arrangement === "vertically"}
      />
      <div className={styles.card__info}>
        <CardName>{fullName}</CardName>
        <CardProfession>
          {hasHotel
            ? date
            : shownElements !== "appliedOnly" &&
              date && (
                <Moment format="DD/MM/YYYY" unix>
                  {date}
                </Moment>
              )}
        </CardProfession>
        <CardProfession shownElements={shownElements}>
          Business manager
        </CardProfession>
        <CardId shownElements={shownElements}>40321453</CardId>
        {!hasHotel && (
          <Hotel shownElements={shownElements}>
            {data?.business_entity?.name}
          </Hotel>
        )}
        {/* <Id shownElements="idOnly"> */}
        {/*   ID: {data?.id} */}
        {/* </Id> */}
        <Applied shownElements={shownElements}>
          Applied on{" "}
          <Moment format="DD/MM/YYYY" unix>
            {date}
          </Moment>
        </Applied>
      </div>
    </div>
  );
};

UserCard.defaultProps = defaultProps;

export default UserCard;
