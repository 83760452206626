import React from "react";
import cn from "classnames";
import styles from "./Error.module.scss";
import Icon from "../Icon";
import CancelIcon from "../../icons/CancelIcon";

type Props = {
  title?: string;
};

const defaultProps: Props = {
  title: "",
};

const Error: React.FC<Props> = ({ title }) => {
  return (
    <div className={cn(styles.error)}>
      <div className={cn(styles.icon)}>
        <Icon width={24} height={24}>
          <CancelIcon />
        </Icon>
      </div>
      {title}
    </div>
  );
};

Error.defaultProps = defaultProps;

export default Error;
