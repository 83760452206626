import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ProfileListingCard from "../../common/ProfileListingCard";
import JobCard from "../../common/JobCard";
import AddCard from "../../common/AddCard";
import { ReactComponent as BriefCaseIcon } from "../../../assets/icons/briefcase.svg";
import { JobType } from "../../../types/employer/job.type";
import { deleteJob, pauseJob, publishJob } from "../../../api/employer/jobs";

type Props = {
  data: JobType[];
  capabilities: any;
  onUpdate: any;
};

const MyJobs = ({ data, capabilities, onUpdate }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const addJob = () => {
    navigate("/de/profile/jobs/add");
  };

  const onPause = (job: any) => {
    if (job.status === "paused" || job.status === "draft") {
      publishJob(job.id).then(() => {
        onUpdate();
      });
    } else {
      pauseJob(job.id).then(() => {
        onUpdate();
      });
    }
  };

  const onDelete = (job: any) => {
    deleteJob(job.id).then(() => {
      onUpdate();
    });
  };

  return (
    <ProfileListingCard>
      {data.map((item) => (
        <JobCard
          key={item.id}
          data={item}
          fullName={item?.business_entity?.name}
          dateApplied={`ID: ${item?.id}`}
          hasHotel
          datePosted={item?.created_at}
          dateExpiration={item?.expiration_date}
          images={item?.images?.[0]?.url}
          isEditable
          onPause={onPause}
          onDelete={onDelete}
        />
      ))}
      {capabilities?.can_create && (
        <AddCard
          title={t("addListing") || ""}
          icon={BriefCaseIcon}
          onClick={addJob}
        />
      )}
    </ProfileListingCard>
  );
};

export default MyJobs;
