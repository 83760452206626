import React, { FormEvent, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./HeaderSearch.module.scss";
import Button from "../../common/Button";
import SearchIcon from "../../icons/SearchIcon";

const HeaderSearch: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState<string>("");
  const [debouncedInputValue, setDebouncedInputValue] = useState<string>("");

  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const search = (value: string) => {
    if (value) {
      navigate(`vacancies?search=${value}`);
    } else {
      navigate(`vacancies?search=`);
    }
  };

  useEffect(() => {
    if (!location.pathname.includes("vacancies")) {
      setInputValue("");
    }
  }, [location]);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      if (inputValue) {
        setDebouncedInputValue(inputValue);
      }
    }, 3000); // 3 seconds debounce time

    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [inputValue]);

  useEffect(() => {
    if (debouncedInputValue) {
      search(debouncedInputValue);
    }
  }, [debouncedInputValue]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (inputValue) {
      search(inputValue);
    }
  };

  return (
    <div className={styles.root}>
      <form onSubmit={handleSubmit}>
        <input
          type="search"
          className={styles.input}
          placeholder={t("searchInputPlaceholder") || undefined}
          value={inputValue}
          onChange={handleInputChange}
        />
        <Button
          variant="primary"
          icon={<SearchIcon />}
          iconSize={18}
          className={styles.button}
          submit
        />
      </form>
    </div>
  );
};

export default HeaderSearch;
