import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { authOut } from "store/slices/authSlice";
import cs from "classnames";
import React, { useEffect } from "react";
import styles from "./Navigation.module.scss";
import Backdrop from "../../common/Backdrop";
import user from "../../../assets/icons/activeUser.svg";
import briefCase from "../../../assets/icons/defaultBriefCase.svg";
import hotels from "../../../assets/icons/activebuildings.svg";
import task from "../../../assets/icons/activetask.svg";
import personalCard from "../../../assets/icons/activepersonalcard.svg";
import invoices from "../../../assets/icons/document-text.svg";
import receipt from "../../../assets/icons/receipt-item.svg";
import signOut from "../../../assets/icons/login.svg";
import teacherIcon from "../../../assets/icons/teacher.svg";
import buildingsIcon from "../../../assets/icons/buildings.svg";
import crownIcon from "../../../assets/icons/crown.svg";
import messageIcon from "../../../assets/icons/message.svg";
import facebookIcon from "../../../assets/icons/facebook.svg";
import instagramIcon from "../../../assets/icons/instagram.svg";
import linkedinIcon from "../../../assets/icons/linkedin.svg";
import Button from "../../common/Button";
import {
  closeNavigation,
  toggleNavigation,
  toggleSignInModal,
  toggleSignUpModal,
} from "../../../store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks";

export type Props = {
  visible: boolean;
};

const Navigation: React.FC<Props> = ({ visible }) => {
  const location = useLocation();
  const isBusiness = useAppSelector((state: any) => state.auth.isBusiness);
  const isAuthenticated = useAppSelector(
    (state: any) => state.auth.isAuthenticated,
  );
  const lang = useAppSelector((state: any) => state.ui.language);
  const dispatch = useAppDispatch();
  const profileLink = `${lang}/profile`;
  const onClickSignOut = () => {
    dispatch(authOut());
    localStorage.removeItem("token");
    localStorage.removeItem("isBusiness");
    localStorage.removeItem("user");
  };
  const { t } = useTranslation();
  const Bussineslinks = [
    {
      alt: "user",
      icon: user,
      label: t("profile"),
      href: "/profile",
    },
    {
      alt: "briefcase",
      icon: briefCase,
      label: t("bussinesInformation"),
      href: `/${profileLink}/business-information`,
    },
    {
      alt: "building",
      icon: hotels,
      label: t("myHotels"),
      href: `/${profileLink}/hotels`,
    },
    {
      alt: "task",
      icon: task,
      label: t("myJobs"),
      href: `/${profileLink}/jobs`,
    },
    {
      alt: "presonal card",
      icon: personalCard,
      label: t("recievedApplications"),
      href: `/${profileLink}/applications`,
    },
    {
      alt: "invoices",
      icon: invoices,
      label: t("invoices"),
      href: `/${profileLink}/invoices`,
    },
    {
      alt: "receipt",
      icon: receipt,
      label: t("pricing"),
      href: `/${profileLink}/pricing`,
    },
    // { alt: "sign out", icon: signOut, label: "Sign Out", href: "/en" },
  ];
  const studentLinks = [
    {
      alt: "user",
      icon: user,
      label: t("profile"),
      href: "/de/profile",
    },
    {
      alt: "briefcase",
      icon: briefCase,
      label: t("appliedJobs"),
      href: `/${profileLink}/applied-jobs`,
    },
    {
      alt: "building",
      icon: hotels,
      label: t("savedJobs"),
      href: `/${profileLink}/saved-jobs`,
    },
  ];

  const remainingLinks = [
    {
      alt: "student",
      icon: teacherIcon,
      label: t("Für student"),
      href: `/${lang}/student`,
    },
    {
      alt: "hotel",
      icon: buildingsIcon,
      label: t("Für business"),
      href: `/${lang}/bussines`,
    },
    {
      alt: "about us",
      icon: crownIcon,
      label: t("Über uns"),
      href: `/${lang}/about-us`,
    },
    {
      alt: "contact us",
      icon: messageIcon,
      label: t("Kontaktiere uns"),
      href: `/${lang}/contact`,
    },
  ];

  const socialLinks = [
    {
      alt: "facebook",
      icon: facebookIcon,
      label: "Facebook",
      href: `https://facebook.com`,
    },
    {
      alt: "instagram",
      icon: instagramIcon,
      label: "Instagram",
      href: `https://instagram.com`,
    },
    {
      alt: "linkedin",
      icon: linkedinIcon,
      label: "Linkedin",
      href: `https://linkedin.com`,
    },
  ];

  let links: any[] = [];
  if (isAuthenticated) {
    links = isBusiness ? Bussineslinks : studentLinks;
  }

  let remainingFilteredLinks = remainingLinks;

  if (isAuthenticated) {
    remainingFilteredLinks = remainingLinks.filter((link) =>
      isBusiness ? link.alt !== "student" : link.alt !== "hotel",
    );
  }

  useEffect(() => {
    dispatch(closeNavigation());
  }, [location]);

  return (
    <div className={styles.root}>
      {visible && (
        <>
          <div className={styles.item}>
            {!isAuthenticated ? (
              <div className={styles.header}>{t("menu")}</div>
            ) : (
              <div className={styles.header}>
                {isBusiness ? t("businessProfile") : t("personProfile")}
              </div>
            )}

            {isBusiness ? (
              <Link to="/de/profile/jobs">
                <Button className={styles.button} variant="primary">
                  + {t("addListing")}
                </Button>
              </Link>
            ) : (
              <br />
            )}

            {isAuthenticated ? (
              <div className={cs(styles.linksContainer, styles.borderBottom)}>
                {links.map((item, index) => (
                  <div
                    tabIndex={index}
                    role="menuitem"
                    key={item.label}
                    className={styles.linkContainer}
                    onClick={() => dispatch(toggleNavigation())}
                    onKeyDown={() => console.log("key pressed")}
                  >
                    <img src={item.icon} alt={item.alt} />
                    <Link to={item.href} className={styles.link}>
                      {item.label}
                    </Link>
                  </div>
                ))}
                <div
                  tabIndex={-10}
                  role="menuitem"
                  key="sign out"
                  className={styles.linkContainer}
                  onClick={() => {
                    dispatch(toggleNavigation());
                    onClickSignOut();
                  }}
                  onKeyDown={() => console.log("key pressed")}
                  style={{ padding: 0, margin: 0 }}
                >
                  <img src={signOut} alt="sign out" />
                  <p className={cs(styles.link, styles.margin)}>
                    {t("signOut")}
                  </p>
                </div>
              </div>
            ) : (
              <div
                className={cs(styles.linksContainer, styles.borderBottom)}
                style={{ gap: "16px" }}
              >
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div
                  className={styles.linkContainer}
                  onClick={() => {
                    dispatch(toggleNavigation());
                    dispatch(toggleSignInModal("person"));
                  }}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.41675 6.30001C7.67508 3.30001 9.21675 2.07501 12.5917 2.07501H12.7001C16.4251 2.07501 17.9167 3.56668 17.9167 7.29168V12.725C17.9167 16.45 16.4251 17.9417 12.7001 17.9417H12.5917C9.24175 17.9417 7.70008 16.7333 7.42508 13.7833"
                      stroke="#EF4C5E"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1.6665 10H12.3998"
                      stroke="#EF4C5E"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.5417 7.20831L13.3334 9.99998L10.5417 12.7916"
                      stroke="#EF4C5E"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <Link to="/de" className={styles.link}>
                    {t("personSignIn")}
                  </Link>
                </div>

                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div
                  className={styles.linkContainer}
                  onClick={() => {
                    dispatch(toggleNavigation());
                    dispatch(toggleSignInModal("person"));
                  }}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.41675 6.30001C7.67508 3.30001 9.21675 2.07501 12.5917 2.07501H12.7001C16.4251 2.07501 17.9167 3.56668 17.9167 7.29168V12.725C17.9167 16.45 16.4251 17.9417 12.7001 17.9417H12.5917C9.24175 17.9417 7.70008 16.7333 7.42508 13.7833"
                      stroke="#353131"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1.6665 10H12.3998"
                      stroke="#353131"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.5417 7.20831L13.3334 9.99998L10.5417 12.7916"
                      stroke="#353131"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <Link to="/de" className={styles.link}>
                    {t("hotelSignIn")}
                  </Link>
                </div>

                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div
                  className={styles.linkContainer}
                  onClick={() => {
                    dispatch(toggleNavigation());
                    dispatch(toggleSignUpModal("person"));
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#EF4C5E"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                    />
                  </svg>

                  <Link to="/de" className={styles.link}>
                    {t("personSignUp")}
                  </Link>
                </div>

                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div
                  className={styles.linkContainer}
                  onClick={() => {
                    dispatch(toggleNavigation());
                    dispatch(toggleSignUpModal("hotel"));
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#353131"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                    />
                  </svg>

                  <Link to="/de" className={styles.link}>
                    {t("hotelSignUp")}
                  </Link>
                </div>
              </div>
            )}
            <br />
            <div
              className={cs(styles.linksContainer, styles.borderBottom)}
              style={{ gap: "16px" }}
            >
              {remainingFilteredLinks.map((link, i) => (
                <div
                  tabIndex={i}
                  role="menuitem"
                  key={link.label}
                  className={styles.linkContainer}
                >
                  <img src={link.icon} alt={link.alt} />
                  <Link to={link.href} className={styles.link}>
                    <span>{link.label}</span>
                  </Link>
                </div>
              ))}
            </div>
            <br />
            <div className={styles.linksContainer} style={{ gap: "16px" }}>
              {socialLinks.map((link, i) => (
                <div
                  tabIndex={i}
                  role="menuitem"
                  key={link.label}
                  className={styles.linkContainer}
                >
                  <img src={link.icon} alt={link.alt} />
                  <a
                    href={link.href}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.link}
                  >
                    <span>{link.label}</span>
                  </a>
                </div>
              ))}
            </div>
          </div>
          <Backdrop onClick={() => dispatch(toggleNavigation())} />
        </>
      )}
    </div>
  );
};

export default Navigation;
