import cn from "classnames";
import styles from "./UserAvatar.module.scss";

type Props = {
  size?: "lg" | "md" | "sm";
  src?: string | null;
  centered?: boolean;
};

type NonNullableProps = {
  [K in keyof Props]-?: NonNullable<Props[K]>;
};

const defaultProps: Required<NonNullableProps> = {
  size: "lg",
  src: "/images/placeholder.jpg",
  centered: false,
};

const UserAvatar = ({ size, src, centered = defaultProps.centered }: Props) => {
  const className = cn(styles.avatar, {
    [size !== undefined ? styles[size] : ""]: !!size,
    [styles.centered]: centered,
  });

  const imageSrc = src === null ? defaultProps.src : src;

  return (
    <div className={className}>
      <img className={styles.avatar__img} alt="" src={imageSrc} />
    </div>
  );
};

UserAvatar.defaultProps = defaultProps;

export default UserAvatar;
