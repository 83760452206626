import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import uiSlice from "./slices/uiSlice";

const reducer = {
  ui: uiSlice,
  auth: authReducer,
};

const store = configureStore({
  reducer,
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
