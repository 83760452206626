import { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import moment from "moment";
import NoteIcon from "../../../assets/icons/note-text.svg";
import styles from "./DatePickerCard.module.scss";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "./DatePicker.scss";

type Props = {
  dateValue?: any;
  onChange?: (date: string) => void;
};

const defaultProps: Props = {
  dateValue: "",
  onChange: () => {},
};

const DatePickerCard = ({ dateValue, onChange }: Props) => {
  const [value, setValue] = useState(dateValue || new Date());

  const getDate = (date: Date): string => {
    return moment(date).format("YYYY-MM-DD HH:mm");
  };

  const setDate = (date: any) => {
    if (date !== null) {
      setValue(date);
      onChange?.(getDate(date));
      console.log(getDate(date));
    }
  };

  useEffect(() => {
    setValue(dateValue);
  }, [dateValue]);

  return (
    <div className={styles.date}>
      <div className={styles.date__picker}>
        <DatePicker
          value={value}
          onChange={(date) => setDate(date)}
          format="dd/MM/yyyy"
          minDate={new Date()}
          showLeadingZeros
        />
      </div>
      <div className={styles.date__icon}>
        <img src={NoteIcon} alt="date" />
      </div>
    </div>
  );
};

DatePickerCard.defaultProps = defaultProps;

export default DatePickerCard;
