import React from "react";

const HeartFullIcon: React.FC = () => {
  return (
    <path
      d="M14.8265 24.7466C14.3732 24.9066 13.6265 24.9066 13.1732 24.7466C9.3065 23.4266 0.666504 17.9199 0.666504 8.58657C0.666504 4.46657 3.9865 1.13324 8.07984 1.13324C10.5065 1.13324 12.6532 2.30657 13.9998 4.11991C15.3465 2.30657 17.5065 1.13324 19.9198 1.13324C24.0132 1.13324 27.3332 4.46657 27.3332 8.58657C27.3332 17.9199 18.6932 23.4266 14.8265 24.7466Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="currentColor"
    />
  );
};

export default HeartFullIcon;
