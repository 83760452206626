import React from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import styles from "./Section.module.scss";
import { Props } from "./Section.types";
import Container from "../Container";
import Button from "../Button";
import Link from "../Link";
import SliderNavButtons from "../SliderNavButtons";

const Section: React.FC<Props> = ({
  children,
  className,
  bodyClassName,
  showNav,
  title,
  seeMore,
  goPrev,
  goNext,
}) => {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.root, className)}>
      <Container className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>
            {title}
            <Link to="/vacancies" className={!seeMore ? styles.dis : ""}>
              <Button variant="transparent" className={styles.button}>
                {t("seeMore")}
              </Button>
            </Link>
          </div>
          {showNav && <SliderNavButtons goPrev={goPrev} goNext={goNext} />}
        </div>
        <div className={cn(styles.body, bodyClassName)}>{children}</div>
      </Container>
    </div>
  );
};

export default Section;
