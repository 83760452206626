import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styles from "./StepsSection.module.scss";
import Container from "../../common/Container";
import Icon from "../../common/Icon";
import VerifyIcon from "../../icons/VerifyIcon";
import Card from "../../common/Card";
import Button from "../../common/Button";
import { useAppSelector, useAppDispatch } from "../../../hooks";
import { toggleSignInModal } from "../../../store/slices/uiSlice";

const Tags: React.FC = () => {
  const { t } = useTranslation();
  const items = [
    {
      title: "Junge und dynamische Fachkräfte.",
    },
    {
      title: "Zahlen Sie, wenn Sie Ergebnisse erzielen",
    },
    {
      title: "Geringe Fluktuation",
    },
    {
      title: "Flexibilität/Reduzierte Ausfallzeiten",
    },
    {
      title: "Mitarbeiterbindung",
    },
    {
      title: "Kosteneinsparungen im Vergleich zu Leihfirmen",
    },
    {
      title: "Wettbewerbsvorteil",
    },
  ];

  return (
    <div className={styles.tags}>
      <div className={styles.tags__inner}>
        {items.map((item) => (
          <div className={styles.tag}>
            <Icon width={24} height={24}>
              <VerifyIcon />
            </Icon>
            {item?.title}
          </div>
        ))}
      </div>
      <div className={styles.tags__more}>
        <span>{t(" ")}</span>
      </div>
    </div>
  );
};

const StepsSection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isAuthenticated = useAppSelector(
    (state: any) => state.auth.isAuthenticated,
  );

  const lang = useAppSelector((state: any) => state.ui.language);

  const getStarted = () => {
    if (isAuthenticated) navigate(`/${lang}/profile`);
    else dispatch(toggleSignInModal("person"));
  };

  return (
    <div className={styles.root}>
      <Container className={styles.container}>
        <div className={styles.left}>
          <div className={styles.title}>{t("stepsSectionTitle")}</div>
          <div className={styles.subtitle}>{t("stepsSectionSubtitle")}</div>
          <div className={styles.tags__desktop}>
            <Tags />
          </div>
        </div>
        <div className={styles.right}>
          <Card bodyClassName={styles.card}>
            <div className={styles.timeline}>
              <div className={styles.item}>
                <div className={styles.title}>{t("stepsOneTitle")}</div>
                <div className={styles.subtitle}>
                  {t("stepsOneDescription")}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.title}>{t("stepsTwoTitle")}</div>
                <div className={styles.subtitle}>
                  {t("stepsTwoDescription")}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.title}>{t("stepsThreeTitle")}</div>
                <div className={styles.subtitle}>
                  {t("stepsThreeDescription")}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.title}>{t("stepsFourTitle")}</div>
                <div className={styles.subtitle}>
                  {t("stepsFourDescription")}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.title}>{t("stepsFiveTitle")}</div>
                <div className={styles.subtitle}>
                  {t("stepsFiveDescription")}
                </div>
              </div>
            </div>

            <Button
              onClick={getStarted}
              variant="primary"
              size="lg"
              block
              className={styles.button}
            >
              {t("getStarted")}
            </Button>
          </Card>
        </div>
        <div className={styles.tags__mobile}>
          <Tags />
        </div>
      </Container>
    </div>
  );
};

export default StepsSection;
