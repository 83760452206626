import React from "react";
import cn from "classnames";
import Button from "../../common/Button";
import MenuIcon from "../../icons/MenuIcon";
import styles from "./HeaderNavButton.module.scss";
import { useAppDispatch } from "../../../hooks";
import { toggleNavigation } from "../../../store/slices/uiSlice";

type Props = {
  isBusiness: boolean;
};

const HeaderNavButton: React.FC<Props> = ({ isBusiness }) => {
  const dispatch = useAppDispatch();

  return (
    <Button
      variant="transparent"
      icon={<MenuIcon />}
      className={cn(styles.root, isBusiness ? styles.business : "")}
      onClick={() => dispatch(toggleNavigation())}
    />
  );
};

export default HeaderNavButton;
