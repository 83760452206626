import React, { useRef, useLayoutEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import Section from "../../common/Section";
import { VacanciesDataItemType } from "../../../types/job-seeker/vacancies.type";
import JobCard from "../../cards/JobCard";
import styles from "./JobReel.module.scss";
import "./Slider.scss";
import { stringTruncate } from "../../../helpers/string";

export type Props = {
  items: VacanciesDataItemType[];
};

const JobReel: React.FC<Props> = ({ items }) => {
  const { t } = useTranslation();
  const getElement = (
    index: number,
    qty: number,
    prev = false,
  ): HTMLElement => {
    return document.querySelector(
      `[data-index="${index + (!prev ? qty : qty - 1)}"]`,
    ) as HTMLElement;
  };

  const setOpacity = (index: number) => {
    const screenWidth = window.innerWidth;
    let slidesToShow = 0;
    if (screenWidth >= 1200) slidesToShow = 4;
    else if (screenWidth < 1200 && screenWidth >= 992) slidesToShow = 3;
    else if (screenWidth < 992 && screenWidth >= 768) slidesToShow = 2;
    else if (screenWidth < 768) slidesToShow = 1;

    if (getElement(index, slidesToShow, true))
      getElement(index, slidesToShow, true).style.opacity = "1";
    if (getElement(index, slidesToShow))
      getElement(index, slidesToShow).style.opacity = "0.4";
    if (index === items.length - slidesToShow) {
      if (getElement(index, -1)) getElement(index, -1).style.opacity = "0.4";
    } else if (getElement(index, 0)) {
      getElement(index, 0).style.opacity = "1";
    }
  };

  useLayoutEffect(() => {
    setOpacity(0);
  });

  const settings = {
    speed: 500,
    slidesToShow: 3.99,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3.2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2.2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.2,
          infinite: true,
        },
      },
    ],
    beforeChange: (_: any, index: number) => {
      const fixedIndex = +index.toFixed();
      setOpacity(fixedIndex);
    },
  };

  const sliderRef = useRef<Slider | null>(null);

  const goNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };
  const goPrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  return (
    <Section
      className={styles.root}
      bodyClassName={styles.body}
      title={t("jobsReelTitle") || ""}
      goPrev={goPrev}
      goNext={goNext}
      showNav
      seeMore
    >
      <Slider ref={sliderRef} {...settings}>
        {items.map((item: VacanciesDataItemType) => (
          <JobCard
            key={item.id}
            id={item.id}
            title={stringTruncate(item?.title || "", 26)}
            subTitle={item?.business_entity?.name}
            images={item?.images?.[0]?.url || "/images/placeholder.jpg"}
            roomCost={item.room_daily_cost}
            hourlyCost={item.hourly_rate}
            address={item?.business_entity?.address}
            publishDate={item?.publish_date}
            isInFavourite={item?.capabilities_meta?.is_in_favorite}
            isApply={item?.capabilities_meta?.is_apply}
            canApply={item?.capabilities_meta?.can_apply}
          />
        ))}
      </Slider>
    </Section>
  );
};

export default JobReel;
