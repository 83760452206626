import React from "react";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import cn from "classnames";
import styles from "./SignUpPersonalForm.module.scss";
import Input from "../../common/Input";
import Button from "../../common/Button";
import { SignUpSchema } from "../../../schemas/SignUpSchema";

type Props = {
  isBusiness: boolean;
  onSubmit: any;
};

const SignUpPersonalForm: React.FC<Props> = ({ isBusiness, onSubmit }) => {
  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    password_confirmation: "",
  };
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SignUpSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched }) => (
        <Form>
          <div
            className={cn(
              styles.input,
              styles.grid,
              errors.email && touched.email ? styles.errored : "",
            )}
          >
            <div>
              <Field
                name="first_name"
                label={t("personName")}
                placeholder={t("name")}
                component={Input}
              />
              {errors.first_name && touched.first_name ? (
                <div className={styles.error}>{errors.first_name}</div>
              ) : null}
            </div>

            <div>
              <Field
                name="last_name"
                label="Person's name"
                placeholder={t("surname")}
                component={Input}
              />
              {errors.last_name && touched.last_name ? (
                <div className={styles.error}>{errors.last_name}</div>
              ) : null}
            </div>
          </div>

          <div
            className={cn(
              styles.input,
              errors.email && touched.email ? styles.errored : "",
            )}
          >
            <Field
              name="email"
              label="Email"
              placeholder={t("enterMail")}
              component={Input}
            />
            {errors.email && touched.email ? (
              <div className={styles.error}>{errors.email}</div>
            ) : null}
          </div>
          <div
            className={cn(
              styles.input,
              errors.phone && touched.phone ? styles.errored : "",
            )}
          >
            <Field
              name="phone"
              label={t("phone")}
              placeholder={t("enterPhone")}
              component={Input}
            />
            {errors.phone && touched.phone ? (
              <div className={styles.error}>{errors.phone}</div>
            ) : null}
          </div>
          <div
            className={cn(
              styles.input,
              errors.password && touched.password ? styles.errored : "",
            )}
          >
            <Field
              name="password"
              type="password"
              label={t("password")}
              placeholder={t("password")}
              component={Input}
            />
            {errors.password && touched.password ? (
              <div className={styles.error}>{errors.password}</div>
            ) : null}
          </div>

          <div
            className={cn(
              styles.input,
              errors.password_confirmation && touched.password_confirmation
                ? styles.errored
                : "",
            )}
          >
            <Field
              name="password_confirmation"
              type="password"
              label={t("confirmPassword")}
              placeholder={t("confirmPassword")}
              component={Input}
            />
            {errors.password_confirmation && touched.password_confirmation ? (
              <div className={styles.error}>{errors.password_confirmation}</div>
            ) : null}
          </div>
          <Button
            variant={isBusiness ? "dark" : "primary"}
            size="lg"
            block
            submit
          >
            {t("signUp")}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default SignUpPersonalForm;
