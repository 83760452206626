import Moment from "react-moment";
import styles from "./DateCard.module.scss";
import DateIcon from "../../../assets/icons/date.svg";

type Props = {
  title: string;
  date: string | undefined;
};

const DateCard = ({ title, date }: Props) => {
  return (
    <div className={styles.date}>
      <img src={DateIcon} className={styles.date__icon} alt="" />
      <div className={styles.date__box}>
        <span className={styles.date__type}>{title}</span>
        <span className={styles.date__numbers}>
          <Moment format="MM/DD/YYYY" unix>
            {date}
          </Moment>
        </span>
      </div>
    </div>
  );
};

export default DateCard;
