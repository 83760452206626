import { del, get, post } from "../index";
import { VacanciesDataItemType } from "../../types/job-seeker/vacancies.type";

export const getJobSeekerVacancies = async (searchVal: string = "") => {
  return get<any>(`/vacancy?search=${searchVal}`);
};

export const getJobSeekerVacancy = async (id: string | undefined) => {
  return get<any>(`/vacancy/${id}`);
};

export const postApplyJob = async (id: number, data: any) => {
  return post<any>(`/job-seeker/vacancy-applications/${id}/apply`, data);
};

export const toggleFavourite = async (item: VacanciesDataItemType) => {
  return item?.isInFavourite
    ? del<any>(`/job-seeker/vacancy/${item.id}/favorite`)
    : post<any>(`/job-seeker/vacancy/${item.id}/favorite`);
};
