import { Navigate } from "react-router-dom";
import React from "react";
import { useAppSelector } from "../../hooks";

type Props = {
  children: any;
};

const AuthMiddleware: React.FC<Props> = ({ children }) => {
  const user = useAppSelector((state: any) => state.auth.isAuthenticated);

  if (!user) {
    return <Navigate to="/de" replace />;
  }

  return children;
};

export default AuthMiddleware;
