import React from "react";
import styles from "./FooterLeft.module.scss";
import Link from "../../common/Link";

const FooterLeft: React.FC = () => {
  return (
    <nav className={styles.root}>
      <li className={styles.item}>
        <Link to="/" className={styles.link}>
          Home
        </Link>
      </li>
      <li className={styles.item}>
        <Link to="/about-us" className={styles.link}>
          About Us
        </Link>
      </li>
      <li className={styles.item}>
        <Link to="/contact" className={styles.link}>
          Contact Us
        </Link>
      </li>
    </nav>
  );
};

export default FooterLeft;
