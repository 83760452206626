import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Hero from "../components/common/Hero";
// import StatisticsBar from "../components/statistics/StatisticsBar";
import FaqContent from "../components/faq/FaqContent";
import SearchSection from "../components/sections/SearchSection";
import StepsSection from "../components/sections/StepsSection";
import { VacanciesDataItemType } from "../types/job-seeker/vacancies.type";
import { getJobSeekerVacancies } from "../api/job-seeker/vacancies";
import JobReel from "../components/jobs/JobReel";
import { useAppDispatch, useAppSelector } from "../hooks";
import { showResetPasswordModal } from "../store/slices/uiSlice";

const HomePage = () => {
  const dispatch = useAppDispatch();
  const { token } = useParams();
  const isAuthenticated = useAppSelector((state: any) => state.auth.user);
  const [vacancies, setVacancies] = useState<VacanciesDataItemType[]>([]);

  if (token) {
    dispatch(showResetPasswordModal("person"));
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getJobSeekerVacancies();
        setVacancies(response?.data?.items?.slice(0, 4) || []);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [isAuthenticated]);
  return (
    <>
      <Hero />
      {/* <StatisticsBar /> */}

      <JobReel items={vacancies} />
      <SearchSection />
      <StepsSection />
      <FaqContent />
    </>
  );
};

export default HomePage;
