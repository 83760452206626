import React from "react";

type IconProps = {
  width: number | undefined;
  height: number | undefined;
  children: React.ReactNode;
};

const Icon = ({ width, height, children }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {children}
    </svg>
  );
};

export default Icon;
