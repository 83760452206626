import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../common/Button";
import styles from "./HeaderAccount.module.scss";
import LoginIcon from "../../icons/LoginIcon";
import BackdropDropdown from "../../common/BackdropDropdown";
import { useAppDispatch } from "../../../hooks";
import {
  toggleSignInModal,
  toggleSignUpModal,
} from "../../../store/slices/uiSlice";

const HeaderAccount: React.FC = () => {
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState<boolean>(false);
  const isMobile = window.innerWidth <= 768;

  const onClickButton = () => {
    setVisible(!visible);
  };
  const { t } = useTranslation();

  return (
    <BackdropDropdown
      visible={visible}
      bodyClassName={styles.body}
      button={
        isMobile ? (
          <Button
            variant="primary"
            className={styles.root}
            onClick={onClickButton}
          >
            {t("signIn")}
          </Button>
        ) : (
          <Button
            variant="transparent"
            icon={<LoginIcon />}
            iconSize={20}
            className={styles.root}
            onClick={onClickButton}
          >
            {t("signIn")}
          </Button>
        )
      }
      onClose={setVisible}
    >
      <ul className={styles.list}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
        <li
          onClick={() => {
            setVisible(false);
            dispatch(toggleSignInModal("person"));
          }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.41675 6.30001C7.67508 3.30001 9.21675 2.07501 12.5917 2.07501H12.7001C16.4251 2.07501 17.9167 3.56668 17.9167 7.29168V12.725C17.9167 16.45 16.4251 17.9417 12.7001 17.9417H12.5917C9.24175 17.9417 7.70008 16.7333 7.42508 13.7833"
              stroke="#EF4C5E"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1.6665 10H12.3998"
              stroke="#EF4C5E"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.5417 7.20831L13.3334 9.99998L10.5417 12.7916"
              stroke="#EF4C5E"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <strong>Person</strong>
          {t("signIn")}
        </li>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
        <li
          onClick={() => {
            setVisible(false);
            dispatch(toggleSignInModal("hotel"));
          }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.41675 6.30001C7.67508 3.30001 9.21675 2.07501 12.5917 2.07501H12.7001C16.4251 2.07501 17.9167 3.56668 17.9167 7.29168V12.725C17.9167 16.45 16.4251 17.9417 12.7001 17.9417H12.5917C9.24175 17.9417 7.70008 16.7333 7.42508 13.7833"
              stroke="#353131"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1.6665 10H12.3998"
              stroke="#353131"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.5417 7.20831L13.3334 9.99998L10.5417 12.7916"
              stroke="#353131"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <strong>Business</strong>
          {t("signIn")}
        </li>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
        <li
          onClick={() => {
            setVisible(false);
            dispatch(toggleSignUpModal("person"));
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#EF4C5E"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
            />
          </svg>
          <strong>Person</strong>
          {t("registration")}
        </li>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
        <li
          onClick={() => {
            setVisible(false);
            dispatch(toggleSignUpModal("hotel"));
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#353131"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
            />
          </svg>
          <strong>Business</strong>
          {t("registration")}
        </li>
      </ul>
    </BackdropDropdown>
  );
};

export default HeaderAccount;
