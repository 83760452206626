import React from "react";
import NoteIcon from "../../../assets/icons/note-text.svg";
import styles from "./CostCard.module.scss";

type Props = {
  value?: string;
  onChange?: (value: string) => void;
};

const defaultProps: Props = {
  value: "",
  onChange: () => {},
};

const CostCard = ({ value, onChange }: Props) => {
  const updateCost = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.value);
  };

  return (
    <div className={styles.cost}>
      <div className={styles.cost__title}>
        <img src={NoteIcon} alt="note" />
        <span>Monthly Payment</span>
      </div>
      <div className={styles.cost__info}>
        <span>€</span>
        <input
          type="text"
          value={value}
          placeholder="00.00"
          onInput={updateCost}
        />
      </div>
    </div>
  );
};

CostCard.defaultProps = defaultProps;

export default CostCard;
