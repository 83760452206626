import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import JobCard from "../../common/JobCard/JobCard";
import styles from "./SavedJobs.module.scss";
import { JobType } from "../../../types/employer/job.type";
import { getSavedJobs } from "../../../api/jobSeeker/jobs";
import Link from "../../common/Link";

const SavedJobs = () => {
  const [jobs, setJobs] = useState<JobType[]>([]);

  useEffect(() => {
    getSavedJobs().then((response) => setJobs(response.data.items));
  }, []);
  const { t } = useTranslation();
  return (
    <div className={styles.jobs}>
      {jobs.length === 0 ? (
        <p>{t("noJobs")}</p>
      ) : (
        jobs.map((item) => (
          <Link to={`/vacancy/${item.id}`} className={styles.item}>
            <JobCard
              key={item.id}
              data={item}
              datePosted={item?.publish_date}
              dateExpiration={item?.expiration_date}
              title={item?.title}
              canApply={item?.capabilities_meta?.can_apply}
              isSaved
            />
          </Link>
        ))
      )}
    </div>
  );
};

export default SavedJobs;
