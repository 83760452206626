import styles from "./InputError.module.scss";

type Props = {
  label?: string;
  visible?: boolean;
};

const defaultProps: Props = {
  label: "",
  visible: false,
};

const InputError = ({ label, visible }: Props) => {
  return visible ? <span className={styles.error}>{label}</span> : null;
};

InputError.defaultProps = defaultProps;

export default InputError;
