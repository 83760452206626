import React from "react";
import styles from "./Backdrop.module.scss";

export type Props = {
  onClick: any;
};

const Backdrop: React.FC<Props> = ({ onClick }) => {
  return (
    <div className={styles.root} aria-hidden="true" onClick={() => onClick()} />
  );
};

export default Backdrop;
