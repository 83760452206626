import { del, get, post, put } from "..";
import { JobType } from "../../types/employer/job.type";
import { ContentType } from "../../types/content.type";

export const getJobs = async () => {
  return get<ContentType<JobType[]>>("/employer/vacancy-applications");
};

export const getEmleyerVacancy = async () => {
  return get<ContentType<JobType[]>>("/employer/vacancy");
};

export const getJob = async (id: number) => {
  return get<ContentType<JobType[]>>(`/employer/vacancy/${id}`);
};

export const addJob = (data: object) => {
  return post("/employer/vacancy", data);
};

export const updateJob = (id: number, data: object) => {
  return post(`/employer/vacancy/${id}`, data);
};

export const pauseJob = (id: number) => {
  return put(`/employer/vacancy/${id}/pause`);
};

export const publishJob = (id: number) => {
  return put(`/employer/vacancy/${id}/publish`);
};

export const deleteJob = (id: number) => {
  return del(`/employer/vacancy/${id}`);
};
