import React from "react";

const AttachmentIcon: React.FC = () => {
  return (
    <path
      d="M13.0627 6.65036C13.2369 6.44689 13.5645 6.4072 13.7937 6.56128C14.0233 6.71568 14.0681 7.00604 13.8939 7.2092L7.61997 14.5219C6.91256 15.3439 5.89095 15.8355 4.81538 15.9653C3.75639 16.0931 2.64168 15.8702 1.71987 15.2658C1.68751 15.2484 1.65726 15.228 1.62959 15.2051C0.725059 14.5788 0.183396 13.6849 0.0391648 12.7451C-0.105067 11.8059 0.147074 10.8179 0.828736 10.0012C0.847426 9.97369 0.869643 9.94775 0.894328 9.924L8.43987 1.12981C9.01785 0.528778 9.65896 0.151531 10.372 0.0368255C11.0918 -0.0791302 11.851 0.0758941 12.6554 0.54253L12.6896 0.564095L12.6988 0.570347L12.6998 0.570972C13.0835 0.833513 13.3702 1.12606 13.5666 1.44111C13.7775 1.78022 13.8826 2.14434 13.89 2.52503C13.8995 3.0373 13.7623 3.46361 13.5419 3.85524C13.3392 4.21623 13.0634 4.54316 12.7689 4.88633L5.77951 13.0335C5.484 13.378 5.0573 13.5839 4.60873 13.638C4.17992 13.6899 3.72783 13.6024 3.34944 13.3645C3.31559 13.3467 3.28385 13.3255 3.25458 13.3005C2.89382 13.0404 2.67765 12.6769 2.61876 12.2956C2.55846 11.899 2.66813 11.4802 2.96294 11.1364L9.00163 4.09746C9.17548 3.8943 9.50309 3.85461 9.73266 4.0087C9.96188 4.1631 10.0067 4.45345 9.83281 4.65661L3.79412 11.6955C3.67423 11.8355 3.62979 12.0081 3.65483 12.1725C3.67987 12.3356 3.77473 12.4903 3.93201 12.5963L3.93377 12.5979C4.09105 12.7029 4.28501 12.7419 4.46979 12.7197C4.65422 12.6976 4.82878 12.6138 4.94833 12.4744L11.9377 4.32718C12.1938 4.0287 12.4339 3.74397 12.6042 3.44142C12.7569 3.17013 12.8518 2.87946 12.8458 2.5394C12.8416 2.31281 12.7774 2.09402 12.6494 1.88774C12.5238 1.68615 12.3341 1.49362 12.0746 1.31453C11.51 0.988537 11.0061 0.875707 10.5561 0.947906C10.0969 1.02198 9.65684 1.29265 9.23367 1.73147L1.69413 10.52C1.16163 11.1404 0.964504 11.8993 1.07523 12.6216C1.18385 13.3295 1.58939 14.0018 2.26612 14.4725C2.29256 14.4869 2.31795 14.5038 2.34158 14.5232C3.03523 14.9764 3.87594 15.1436 4.67644 15.047C5.47589 14.9507 6.23478 14.5904 6.76551 13.9899C6.78244 13.9662 6.80183 13.9437 6.82369 13.9221L13.0627 6.65036Z"
      fill="currentColor"
    />
  );
};

export default AttachmentIcon;
