import React from "react";
import Container from "components/common/Container";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../../../../assets/images/bussinesLandingBackground@3.png";
import styles from "./BussinesLandingSection.module.scss";
import { useAppSelector, useAppDispatch } from "../../../../hooks";
import { toggleSignInModal } from "../../../../store/slices/uiSlice";

function BussinesLandingSection() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isAuthenticated = useAppSelector(
    (state: any) => state.auth.isAuthenticated,
  );
  const isBusiness = useAppSelector((state: any) => state.auth.isBusiness);

  const lang = useAppSelector((state: any) => state.ui.language);

  const handleClick = () => {
    if (isAuthenticated) navigate(`/${lang}/profile`);
    else dispatch(toggleSignInModal("person"));
  };
  return (
    <div className={styles.container}>
      <img
        src={backgroundImage}
        alt="background"
        className={styles.backgroundImage}
      />
      <Container>
        <div className={styles.contentContainer}>
          <h1 className={styles.header}>
            Biete Hoteljob und <br /> Wohnung gleichzeitig an
          </h1>
          <h3 className={styles.paragraph}>
            Wenn Sie ein Hotel oder eine Hotelkette leiten und Menschen suchen,
            die Sie so einstellen können, dass Sie ihnen auch eine Unterkunft
            bieten, ist diese Plattform genau das Richtige für Sie. Probieren
            Sie es aus, Sie verlieren nichts!
          </h3>
          <div className={styles.buttonContainer}>
            {isAuthenticated && isBusiness && (
              <>
                <button
                  onClick={handleClick}
                  type="button"
                  className={styles.darkButton}
                >
                  <p className={styles.buttonLabel}> + Hotel hinzufügen</p>
                </button>
                <button
                  onClick={handleClick}
                  type="button"
                  className={styles.redButton}
                >
                  <p className={styles.buttonLabel}> + Eintrag hinzufügen</p>
                </button>
              </>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}

export default BussinesLandingSection;
