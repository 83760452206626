import { useEffect, useState } from "react";
import PricingCard from "./PricingCard";
import styles from "./Pricing.module.scss";
import { getPlans } from "../../../api/jobSeeker/jobs";
import { getCompany } from "../../../api/employer/company";

const Pricing = () => {
  const [company, setCompany] = useState<any>(null);
  const [plans, setPlans] = useState<any>([]);

  const fetchData = () => {
    getCompany().then((response) => {
      if (response.data.items.length > 0) {
        setCompany(response.data.items[0]);

        getPlans(response.data.items[0].id).then((r) => setPlans(r.data));
      }
    });
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.pricing}>
      {plans.map((plan: any) => (
        <PricingCard plan={plan} company={company} onChange={fetchData} />
      ))}
    </div>
  );
};

export default Pricing;
