import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import Button from "../Button";
import FileCard from "../FileCard";
import styles from "./InvoiceCard.module.scss";
import { downloadInvoice } from "../../../api/employer/company";

type Props = {
  id: number;
  amount: number;
  date: number;
  status: string;
};
const InvoiceCard = ({ id, amount, date, status }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.invoice}>
      <div>
        <div className={styles.invoice__item}>
          <span className={styles.invoice__name}>{t("invoice")} ID:</span>
          <span className={styles.invoice__value}>#{id}</span>
        </div>
        <div className={styles.invoice__item}>
          <span className={styles.invoice__name}>{t("date")}:</span>
          <span className={styles.invoice__value}>
            <Moment format="MM/DD/YYYY" unix>
              {date}
            </Moment>
          </span>
        </div>
        <div className={styles.invoice__item}>
          <span className={styles.invoice__name}>{t("amount")}:</span>
          <span className={styles.invoice__value}>{amount}</span>
        </div>
      </div>
      <div className={styles.invoice__button}>
        <Button variant={status === "pending" ? "dark" : "success"}>
          {t(status)}
        </Button>
      </div>
      <div className={styles.invoice__file}>
        <FileCard
          format="PDF"
          label="invoice.pdf"
          file={{ url: `${downloadInvoice(id)}` }}
          withDownload
          blob
        />
      </div>
    </div>
  );
};

export default InvoiceCard;
