import React, { useState, useEffect } from "react";
import AddImageCard from "../../cards/AddImageCard";
import styles from "./ImageUploader.module.scss";
import Button from "../Button";
import CancelIcon from "../../icons/CancelIcon";
import Icon from "../Icon";

type Image = {
  file: File;
  url: string;
  id?: number;
};

type Props = {
  value?: object[];
  onChange?: (images: File[]) => void;
  // eslint-disable-next-line react/require-default-props
  onDeleteChange: (image: number | undefined) => void;
};

const defaultProps: Props = {
  value: [],
  onChange: () => {},
  // eslint-disable-next-line react/default-props-match-prop-types
  onDeleteChange: () => {},
};

const ImageUploader = ({ value, onChange, onDeleteChange }: Props) => {
  const [images, setImages] = useState<any[]>(value || []);

  const uploadImage = (data: Image) => {
    setImages((prevImages) => prevImages && [...prevImages, data]);
  };

  const removeImage = (data: Image) => {
    setImages(images.filter((image: Image) => image?.id !== data?.id));
    onDeleteChange(data?.id);
  };

  useEffect(() => {
    onChange?.(images.map((image) => image.file));
  }, [images]);

  return (
    <div className={styles.uploader}>
      <AddImageCard onChange={uploadImage} />
      <div className={styles.uploader__gallery}>
        {images?.map((image) => (
          <div className={styles.uploader__image}>
            <img src={image.url} alt="upload" />
            <Button
              variant="primary"
              className={styles.uploader__delete}
              onClick={() => removeImage(image)}
            >
              <Icon width={24} height={24}>
                <CancelIcon />
              </Icon>
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

ImageUploader.defaultProps = defaultProps;

export default ImageUploader;
