import * as Yup from "yup";

export const SignUpSchema = Yup.object().shape({
  first_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Required"),
  password_confirmation: Yup.string().required("Required"),
  phone: Yup.number().required("Required"),
});

export default SignUpSchema;
