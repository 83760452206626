import { useTranslation } from "react-i18next";
import styles from "./HotelCard.module.scss";
import UserCard from "../UserCard";
import LocationIcon from "../../../assets/icons/location.svg";
// import MailIcon from "../../../assets/icons/mail.svg";
// import PhoneIcon from "../../../assets/icons/phone.svg";
import { HotelType } from "../../../types/employer/hotel.type";
import { toggleAddHotelModal } from "../../../store/slices/uiSlice";
import { useAppDispatch } from "../../../hooks";

type Props = {
  data: HotelType;
  onDelete: any;
};

const HotelCard = ({ data, onDelete }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const showDelete = false;

  return (
    <div className={styles.hotel}>
      <UserCard
        fullName={data?.name || ""}
        date={`ID: ${data?.id}`}
        avatarSize="md"
        shownElements="idOnly"
        hasHotel
        data={data}
      />
      <div className={styles.hotel__details}>
        <div className={styles.hotel__item}>
          <img src={LocationIcon} alt="location" />
          <span>{data.address}</span>
        </div>
        {/* <div className={styles.hotel__item}> */}
        {/*   <img src={MailIcon} alt="mail" /> */}
        {/*   <span>hamburgmercurehotel@gmail.com</span> */}
        {/* </div> */}
        {/* <div className={styles.hotel__item}> */}
        {/*   <img src={PhoneIcon} alt="mail" /> */}
        {/*   <span>+49 2192390458</span> */}
        {/* </div> */}
      </div>
      <div className={styles.hotel__edit}>
        <button
          type="button"
          onClick={() => {
            dispatch(toggleAddHotelModal(data));
          }}
        >
          {t("edit")}
        </button>

        {showDelete && (
          <button type="button" onClick={() => onDelete(data)}>
            {t("delete")}
          </button>
        )}
      </div>
    </div>
  );
};

export default HotelCard;
