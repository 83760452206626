import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import UserCard from "../UserCard";
import FileCard from "../FileCard";
import DateCard from "../DateCard";
import Button from "../Button";
import MessageIcon from "../../../assets/icons/messages.svg";
import LocationIcon from "../../../assets/icons/location.svg";
import styles from "./JobCard.module.scss";
import { JobType } from "../../../types/employer/job.type";
import Link from "../Link";
import { toggleApplyJobModal } from "../../../store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  postEmployerChatInit,
  postJobSeekerChatInit,
} from "../../../api/chat/messages";

type Props = {
  isSaved?: boolean;
  isEditable?: boolean;
  canApply?: boolean;
  hasHotel?: boolean;
  data: JobType;
  onPause: any;
  onDelete: any;
  title?: string;
  fullName?: string;
  dateApplied?: string;
  datePosted?: string;
  dateExpiration?: string;
  images?: string;
  // eslint-disable-next-line react/require-default-props
  onFetch?: any;
};

const defaultProps: Partial<Props> = {
  isSaved: false,
  isEditable: false,
  hasHotel: false,
  canApply: false,
  title: "",
  fullName: "",
  dateApplied: "",
  datePosted: "",
  dateExpiration: "",
  images: "",
};

const JobCard = ({
  data,
  isSaved,
  isEditable,
  canApply,
  hasHotel,
  dateApplied,
  datePosted,
  dateExpiration,
  onPause,
  onDelete,
  title,
  fullName,
  images,
  onFetch,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isBusiness = useAppSelector((state: any) => state.auth.isBusiness);
  const [chatId, setChatId] = useState<any>(null);
  const [canApplyInvocie, setCanApplyInvocie] = useState<any>(
    canApply || false,
  );
  const showDelete = false;

  const onGoToChat = async (e: any) => {
    e.preventDefault();
    if (!data?.chat) {
      if (isBusiness) {
        await postEmployerChatInit(data?.id).then((response) => {
          setChatId(response?.data?.chat?.id);
          onFetch();
        });
      } else {
        await postJobSeekerChatInit(data?.id).then((response) => {
          setChatId(response?.data?.chat?.id);
          onFetch();
        });
      }
    } else {
      navigate(
        {
          pathname: `/de/profile/applications/${data?.id}/conversation/${
            data?.chat?.id || chatId
          }`,
        },
        { replace: true },
      );
    }
  };

  const onApply = (e: any) => {
    e.preventDefault();
    if (canApply) {
      dispatch(
        toggleApplyJobModal({
          id: data.id,
          title: data.title,
          subTitle: data.business_entity.name,
        }),
      );
      setCanApplyInvocie(false);
    }
  };

  useEffect(() => {
    if (chatId) {
      navigate(
        {
          pathname: `/de/profile/applications/${data?.id}/conversation/${
            data?.chat?.id || chatId
          }`,
        },
        { replace: true },
      );
    }
  }, [chatId]);

  // const convertDate = (date: string): string => {
  //   const newDate = new Date(+date * 1000);
  //   return `${newDate.getMonth()}/${newDate
  //     .getDate()
  //     .toFixed()}/${newDate.getFullYear()}`;
  // };

  return (
    <div className={styles.job}>
      <UserCard
        fullName={fullName}
        avatarSize="md"
        data={data}
        shownElements="hotelOnly"
        hasHotel={hasHotel}
        image={images}
      />
      {!isEditable ? (
        <div className={styles.job__applied}>
          <span className={styles.job__appliedto}>{t("appliedTo")}:</span>
          <span className={styles.job__name}>{title}</span>
        </div>
      ) : (
        <div className={styles.job__applied}>
          <span className={styles.job__appliedto}>{t("appliedTo")}:</span>
          <span className={styles.job__name}>{data.title}</span>
        </div>
      )}
      {!isSaved && !isEditable && (
        <div
          className={styles.job__file}
          style={{ display: data?.files?.[0] ? "block" : "none" }}
        >
          <FileCard
            format="PDF"
            file={data?.files?.[0]}
            label={`${data?.files?.[0]?.name}.pdf`}
            date={dateApplied}
            withDate
          />
        </div>
      )}
      {isEditable && (
        <div className={styles.job__location}>
          <img src={LocationIcon} alt="location" />
          <div className={styles.job__location__info}>
            <span className={styles.job__location__info__title}>
              {t("location")}
            </span>
            <span className={styles.job__location__info__address}>
              {data?.business_entity?.address}
            </span>
          </div>
        </div>
      )}
      <div className={styles.job__date}>
        <DateCard title="Date Posted" date={datePosted} />
        <DateCard title="Expiration Date" date={dateExpiration} />
      </div>
      {!isSaved && !isEditable && (
        <div className={styles.job__conversation}>
          <div className={styles.job__conversation__inner}>
            {(isBusiness ||
              data?.vacancy_data?.can_student_start_chat ||
              data?.chat) && (
              <Button variant="transparent" onClick={onGoToChat}>
                <img src={MessageIcon} alt="message" />
                {data?.chat ? t("seeConversation") : t("startConversation")}
              </Button>
            )}
          </div>
        </div>
      )}
      {isSaved && !isEditable && (
        <div className={styles.job__apply}>
          <Button
            variant={!canApplyInvocie ? "secondary" : "primary"}
            onClick={(e) => onApply(e)}
          >
            {!canApplyInvocie ? t("isApplied") : t("applyNow")}
          </Button>
        </div>
      )}
      {isEditable && (
        <div className={styles.job__edit}>
          <Link to={`/profile/jobs/${data.id}/edit`}>
            <button type="button">{t("edit")}</button>
          </Link>
          <button type="button" onClick={() => onPause(data)}>
            {data?.status === "paused" || data?.status === "draft"
              ? t("publish")
              : t("pause")}
          </button>
          {showDelete && (
            <button
              type="button"
              className={styles.delete}
              onClick={() => onDelete(data)}
            >
              {t("delete")}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

JobCard.defaultProps = defaultProps;

export default JobCard;
