import { Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import moment from "moment";
import cn from "classnames";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../../common/Input";
// import CheckboxCard from "../../cards/CheckboxCard";
import Select from "../../common/Select";
import HotelIcon from "../../../assets/icons/hotel-black.svg";
// import ClockIcon from "../../../assets/icons/clock.svg";
// import NoteIcon from "../../../assets/icons/note-text.svg";
// import RadarIcon from "../../../assets/icons/radar.svg";
import DatePickerCard from "../../common/DatePickerCard";
import CostCard from "../../common/CostCard";
import styles from "./ListingForm.module.scss";
import Checkbox from "../../common/Checkbox";
import RichTextEditor from "../../common/RichTextEditor";
import ImageUploader from "../../common/ImageUploader";
import Button from "../../common/Button";
import { getHotels } from "../../../api/employer/hotels";
import { addJob, getJob, updateJob } from "../../../api/employer/jobs";
import InputError from "../../common/InputError";
// import { HotelType } from "../../../types/employer/hotel.type";

// const ValidationSchema = Yup.object().shape({
//   title: Yup.string()
//     .min(2, "Too Short!")
//     .max(50, "Too Long!")
//     .required("Required"),
//   salary: Yup.string().required("Required"),
// });

type CustomForm = {
  hotel: string;
  cost: string;
  description: string;
  images: File[];
  isNegotiable: boolean;
  canStartChat: boolean;
  date: string;
};

// type CustomErrors<T> = {
//   [K in keyof T]: boolean;
// };

const ListingForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);
  const [deletedImages, setDeletedImages] = useState<any>([]);
  const [data, setData] = useState<any>({
    title: "",
  });
  const [isNegotiableChecked, setIsNegotiableChecked] = useState(false);
  const [isStartChatChecked, setIsStartChatChecked] = useState(false);
  const [expirationDate, setExpirationDate] = useState(new Date());
  const [errors, setErrors] = useState<any>({});
  const [customForm, setCustomForm] = useState<CustomForm>({
    hotel: "",
    cost: data?.room_daily_cost || "",
    description: "",
    images: [],
    isNegotiable: false,
    canStartChat: false,
    date: "",
  });

  // const [customErrors] = useState<CustomErrors<any>>({
  //   hotel: false,
  //   cost: false,
  //   description: false,
  //   images: false,
  //   isNegotiable: false,
  //   date: false,
  // });

  const [hotelOptions, setHotelOptions] = useState<
    {
      title: string;
      value: string;
    }[]
  >([]);

  const selectOption = (value: { name: any; value: string }) => {
    setCustomForm({ ...customForm, ...{ [value.name]: value.value } });
  };

  const setCost = (value: string) => {
    setCustomForm({ ...customForm, ...{ cost: value } });
  };

  const setDescription = (value: string) => {
    setCustomForm({ ...customForm, ...{ description: value } });
  };

  const setImages = (images: File[]) => {
    setCustomForm({ ...customForm, ...{ images } });
  };

  const setRemovedImages = (image: number | undefined) => {
    setDeletedImages([...deletedImages, image]);
  };

  const setNegotiable = (value: boolean) => {
    setIsNegotiableChecked(!isNegotiableChecked);
    setCustomForm({ ...customForm, ...{ isNegotiable: value } });
  };

  const setCanStartChat = (value: boolean) => {
    setIsStartChatChecked(!isStartChatChecked);
    setCustomForm({ ...customForm, ...{ canStartChat: value } });
  };

  const setDate = (value: any) => {
    setExpirationDate(value);
    setCustomForm({ ...customForm, ...{ date: value } });
  };

  // const customValidation = () => {
  //   let isSuccessfull = true;
  //   Object.entries(customForm).forEach(([key, value]) => {
  //     if (typeof value !== "boolean" && !value.length) {
  //       setCustomErrors((prevErrors) => ({ ...prevErrors, [key]: true }));
  //       isSuccessfull = false;
  //     } else if (typeof value !== "boolean" && value.length) {
  //       setCustomErrors((prevErrors) => ({ ...prevErrors, [key]: false }));
  //     }
  //   });
  //   // for (const [key, value] of Object.entries(customForm)) {
  //   //   if (typeof value !== "boolean" && !value.length) {
  //   //     setCustomErrors({ ...customErrors, [key]: true });
  //   //     isSuccessfull = false;
  //   //   }
  //   // }
  //
  //   return isSuccessfull;
  // };

  const submit = (values: {
    title: string;
    // location: string;
    salary: string;
  }) => {
    const formData = new FormData();

    customForm.images.forEach((file) => {
      if (file) {
        formData.append("images[]", file);
      }
    });
    formData.append("business_entity_id", customForm.hotel);
    formData.append("title", values.title);
    formData.append("description", customForm.description);
    formData.append("qty", "1");
    formData.append(
      "publish_date",
      moment(new Date()).format("YYYY-MM-DD HH:mm"),
    );
    formData.append(
      "expiration_date",
      moment(expirationDate).format("YYYY-MM-DD HH:mm"),
    );
    formData.append("can_student_start_chat", isStartChatChecked.toString());
    formData.append("room_cost_is_negotiable", isNegotiableChecked.toString());
    formData.append("hourly_rate", values.salary);
    formData.append("room_daily_cost", customForm.cost);
    formData.append("daily_hours", "1");
    formData.append("status", "active");

    if (data.title) {
      formData.append("_method", "PUT");
      deletedImages.forEach((file: any) => {
        if (file) {
          formData.append("images_to_delete[]", file);
        }
      });
      updateJob(data.id, formData)
        .then(() => {
          navigate("/de/profile/jobs", { replace: true });
        })
        .catch((e) => {
          setError(e?.response?.data?.message);
          setErrors(e?.response?.data?.errors);
        });
    } else {
      addJob(formData)
        .then(() => {
          navigate("/de/profile/jobs", { replace: true });
        })
        .catch((e) => {
          setError(e?.response?.data?.message);
          setErrors(e?.response?.data?.errors);
        });
    }
  };

  useEffect(() => {
    if (id) {
      getJob(Number(id)).then((job: any) => {
        setData(job.data);

        getHotels().then((response) => {
          setHotelOptions(
            response.data.items.map((item) => ({
              title: item.name,
              value: item.id,
            })),
          );

          setCustomForm({
            ...customForm,
            hotel:
              response?.data?.items?.find(
                (h: any) => h?.name === job?.data?.business_entity?.name,
              )?.id || "",
            description: job.data.description,
            date: job.data.expiration_date,
            cost: job.data.room_daily_cost,
          });

          if (job?.data?.room_cost_is_negotiable) {
            setIsNegotiableChecked(true);
          }

          if (job?.data?.can_student_start_chat) {
            setIsStartChatChecked(true);
          }

          if (job?.data?.expiration_date) {
            setExpirationDate(
              // eslint-disable-next-line no-unsafe-optional-chaining
              new Date(job?.data?.expiration_date * 1000),
            );
          }
        });

        setTimeout(() => {
          setLoading(false);
        }, 300);
      });
    } else {
      getHotels().then((response) => {
        setHotelOptions(
          response.data.items.map((item) => ({
            title: item.name,
            value: item.id,
          })),
        );
        setLoading(false);
      });
    }
  }, []);
  //   const levels = ["Begginer", "Experienced", "Professional"];

  const { t } = useTranslation();
  return !loading ? (
    <div className={styles.form}>
      <span className={styles.form__title}>
        {id ? t("editListing") : t("addListing")}
      </span>
      <Formik
        initialValues={{
          title: data?.title || "",
          //   location: "",
          salary: data?.hourly_rate || "",
          cost: data?.room_daily_cost || "",
        }}
        onSubmit={submit}
      >
        {() => (
          <Form encType="multipart/form-data">
            <div className={styles.form__inner}>
              <div className={styles.form__inputs}>
                <div className={styles.form__input}>
                  <Field
                    name="title"
                    label={t("vacancyName")}
                    placeholder={t("vacancyNameLabel")}
                    component={Input}
                  />
                  <InputError visible={errors.title} label={errors.title} />
                </div>
                {/* <div
                  className={cn([styles.form__input, styles.form__seniority])}
                >
                  <span className={styles.form__input__title}>
                    Seniority Level
                  </span>
                  <div className={styles.form__seniority__inner}>
                    {levels.map((level) => (
                      <CheckboxCard label={level} />
                    ))}
                  </div>
                </div> */}
                {/* <div className={styles.form__input}>
                  <Field
                    name="location"
                    label="Job Location"
                    placeholder="Enter your job location"
                    component={Input}
                  />
                  {errors.location && touched.location ? (
                    <div>{errors.location}</div>
                  ) : null}
                </div> */}
                <div className={styles.form__input}>
                  <span className={styles.form__input__title}>
                    {t("selectHotel")}
                  </span>
                  <Select
                    icon={HotelIcon}
                    options={[
                      { title: t("selectHotel"), value: "" },
                      ...hotelOptions,
                    ]}
                    name="hotel"
                    value={data?.business_entity?.name || ""}
                    onChange={selectOption}
                  />
                  <InputError
                    visible={errors.business_entity_id}
                    label={errors.business_entity_id}
                  />
                </div>
                <div
                  className={cn([styles.form__input, styles.form__input__cost])}
                >
                  <span className={styles.form__input__title}>
                    {t("roomCost")}
                  </span>
                  <CostCard value={customForm.cost} onChange={setCost} />
                  <InputError
                    visible={errors.room_daily_cost}
                    label={errors.room_daily_cost}
                  />
                </div>
                <div className={styles.form__mark}>
                  <Checkbox
                    label={t("negotiablePrice") || undefined}
                    checked={isNegotiableChecked}
                    onChange={setNegotiable}
                  />
                </div>
                <div className={styles.form__mark}>
                  <Checkbox
                    label={t("startChat") || undefined}
                    checked={isStartChatChecked}
                    onChange={setCanStartChat}
                  />
                </div>
              </div>
              <div className={styles.form__selects}>
                {/* <div className={styles.form__input}>
                  <span className={styles.form__input__title}>
                    Employment Type
                  </span>
                  <Select icon={ClockIcon} />
                </div>
                <div className={styles.form__input}>
                  <span className={styles.form__input__title}>
                    Working Schedule
                  </span>
                  <Select icon={NoteIcon} />
                </div>
                <div className={styles.form__input}>
                  <span className={styles.form__input__title}>
                    Payment Type
                  </span>
                  <Select icon={RadarIcon} />
                </div> */}
                <div className={styles.form__input}>
                  {/* <span className={styles.form__input__title}>Salary Rate</span> */}
                  <Field
                    name="salary"
                    label={t("salaryRate")}
                    placeholder={t("enterSalaryRate")}
                    component={Input}
                  />
                  <InputError
                    visible={errors.hourly_rate}
                    label={errors.hourly_rate}
                  />
                </div>
                <div
                  className={cn([styles.form__input, styles.form__input__date])}
                >
                  <span className={styles.form__input__title}>
                    {t("expiration")}
                  </span>
                  <DatePickerCard
                    dateValue={expirationDate}
                    onChange={setDate}
                  />
                  <InputError
                    visible={errors.expiration_date}
                    label={errors.expiration_date}
                  />
                </div>
              </div>
            </div>
            <div className={styles.form__input}>
              <span className={styles.form__input__title}>
                {t("description")}
              </span>
              <div className={styles.form__editor}>
                <RichTextEditor
                  contentValue={data?.description}
                  onChange={setDescription}
                />
              </div>
              <InputError
                visible={errors.description}
                label={errors.description}
              />
            </div>
            <div className={styles.form__uploader}>
              <span className={styles.form__uploader__title}>
                {t("addPhotos")}
              </span>
              <div className={styles.form__uploader__inner}>
                <ImageUploader
                  value={data.images}
                  onDeleteChange={(image) => setRemovedImages(image)}
                  onChange={setImages}
                />
                {/* <InputError */}
                {/*   visible={customErrors.images} */}
                {/*   label="At least one image should be uploaded" */}
                {/* /> */}
              </div>
            </div>
            <div className={styles.form__end}>
              <div className={styles.form__checkboxes}>
                {/* <Checkbox label="Mark this if you are hiring several candidates for this particular job." />
                <Checkbox label="Mark this if you offer extra benefits with the room." /> */}
              </div>
              <div className={styles.form__publish}>
                <Button variant="primary" submit>
                  {id ? t("updateListing") : t("publishListing")}
                </Button>
              </div>
            </div>

            <div className={styles.error}>{t(error)}</div>
          </Form>
        )}
      </Formik>
    </div>
  ) : (
    <div />
  );
};

export default ListingForm;
