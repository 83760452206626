import { del, get, post, put } from "..";
import { HotelType } from "../../types/employer/hotel.type";
import { ContentType } from "../../types/content.type";

export const getHotels = async () => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");

  return get<ContentType<HotelType[]>>(
    `/employer/business-entity/${user?.companyId}`,
  );
};

export const postAddHotel = async (companyId: number, data: any) => {
  return post<any>(`/employer/business-entity/${companyId}/store`, data);
};

export const postUpdateHotel = async (id: number, data: any) => {
  return put<any>(`/employer/business-entity/${id}/update`, data);
};

export const deleteHotel = async (id: number) => {
  return del<any>(`/employer/business-entity/${id}/destroy`);
};
