import React from "react";
import styles from "./SignUpModal.module.scss";
import Modal from "../../common/Modal";
import SignUpCard from "../../cards/SignUpCard";
import { useAppDispatch } from "../../../hooks";
import { closeModals } from "../../../store/slices/uiSlice";
import { setAuthError } from "../../../store/slices/authSlice";

export type Props = {
  visible: boolean;
};

const SignUpModal: React.FC<Props> = ({ visible }) => {
  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(setAuthError(null));
    dispatch(closeModals());
  };

  return (
    <Modal className={styles.root} visible={visible} onClose={() => onClose()}>
      <SignUpCard />
    </Modal>
  );
};

export default SignUpModal;
