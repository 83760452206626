import { useEffect, useState } from "react";
import { getJobs } from "api/employer/jobs";
import { JobType } from "types/employer/job.type";
import ProfileListingCard from "../../common/ProfileListingCard";
import JobCard from "../../common/JobCard";
import styles from "./ReceivedApplication.module.scss";

const RecievedApplications = () => {
  const [data, setData] = useState<JobType[]>([]);

  const fetchData = () => {
    getJobs().then((response) => setData(response.data.items));
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (!data) return <p>loading</p>;

  return (
    <>
      <h1 className={styles.header}>Received Applications</h1>
      <ProfileListingCard>
        {data?.map((item) => (
          <JobCard
            data={item}
            title={item?.vacancy_data?.title}
            fullName={`${item?.job_seeker_data?.first_name} ${item?.job_seeker_data?.last_name}`}
            dateApplied={item?.created_at}
            datePosted={item?.vacancy_data?.created_at}
            dateExpiration={item?.vacancy_data?.expiration_date}
            onFetch={fetchData}
          />
        ))}
        {!data.length && "Records not found"}
      </ProfileListingCard>
    </>
  );
};

export default RecievedApplications;
