import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import CameraIcon from "../../../assets/icons/camera.svg";
import styles from "./AddImageCard.module.scss";

type Props = {
  onChange?: ({ file, url }: { file: File; url: string }) => void;
};

const defaultProps: Props = {
  onChange: () => {},
};

const AddImageCard = ({ onChange }: Props) => {
  const fileRef = useRef<HTMLInputElement>(null);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      fileRef?.current?.click();
    }
  };

  const chooseImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        onChange?.({ file, url: fileReader.result as string });
      };
      fileReader.readAsDataURL(file);
    }
  };
  const { t } = useTranslation();
  return (
    <>
      <input type="file" hidden ref={fileRef} onChange={chooseImage} />
      <div
        tabIndex={0}
        role="button"
        className={styles.image}
        onClick={() => fileRef?.current?.click()}
        onKeyDown={handleKeyDown}
      >
        <div className={styles.image__inner}>
          <img src={CameraIcon} alt="camera" />
          <span>{t("addPhoto")}</span>
        </div>
      </div>
    </>
  );
};

AddImageCard.defaultProps = defaultProps;

export default AddImageCard;
