import { useState, useEffect } from "react";
import { getAppliedJobs } from "../../../api/jobSeeker/jobs";
import JobCard from "../../common/JobCard/JobCard";
import ProfileListingCard from "../../common/ProfileListingCard";
import { JobType } from "../../../types/employer/job.type";
import Link from "../../common/Link";
import styles from "../SavedJobs/SavedJobs.module.scss";

const AppliedJobs = () => {
  const [jobs, setJobs] = useState<JobType[]>([]);

  const fetchData = () => {
    getAppliedJobs().then((response) => setJobs(response.data.items));
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <ProfileListingCard>
      {jobs.map((job) => (
        <Link to={`/vacancy/${job.id}`} className={styles.item}>
          <JobCard
            title={job?.vacancy_data?.title}
            fullName={job?.vacancy_data?.title}
            hasHotel
            dateApplied={job?.vacancy_data?.business_entity?.name}
            images={job?.vacancy_data?.images?.[0]?.url}
            key={job.id}
            data={job}
            datePosted={job?.vacancy_data?.created_at}
            dateExpiration={job?.vacancy_data?.expiration_date}
            onFetch={fetchData}
          />
        </Link>
      ))}
    </ProfileListingCard>
  );
};

export default AppliedJobs;
