import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./ApplyJobCard.module.scss";
import Card from "../../common/Card";
import ApplyJobForm from "../../forms/ApplyJobForm/ApplyJobForm";
import { postApplyJob } from "../../../api/job-seeker/vacancies";
import { singleFileUpload } from "../../../helpers/file";
import { useAppSelector } from "../../../hooks";
import Success from "../../common/Success";
import Error from "../../common/Error";

const ApplyJobCard = () => {
  const modalData = useAppSelector((state: any) => state.ui.modalData);
  const [status, setStatus] = useState<{ success: boolean; error: boolean }>({
    success: false,
    error: false,
  });

  const onSubmit = async (values: any) => {
    try {
      await postApplyJob(modalData?.id, singleFileUpload(values.file));

      setStatus({
        success: true,
        error: false,
      });
    } catch (error: any) {
      setStatus({
        success: false,
        error: true,
      });
    }
  };
  const { t } = useTranslation();
  return (
    <Card
      className={styles.root}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      withClose
    >
      <div className={styles.header}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {!status.success && !status.error ? (
          <>
            <div className={styles.subtitle}>{modalData?.subTitle}</div>
            <div className={styles.title}>{modalData?.title}</div>
          </>
        ) : status.success ? (
          <Success title={t("appliedSuccessfully") || undefined} />
        ) : (
          <Error title={t("didNotAppliedSuccessfully") || undefined} />
        )}
      </div>
      {/* <div className={styles.error}>{error}</div> */}

      {!status.success && !status.error && <ApplyJobForm onSubmit={onSubmit} />}
      {!status.success && !status.error && (
        <div className={styles.additional} />
      )}
    </Card>
  );
};

export default ApplyJobCard;
