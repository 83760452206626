import { useEffect, useRef, useState } from "react";
import {
  getAppliedApplication,
  getMessages,
  getVacancyApplication,
} from "api/chat/messages";
import { useParams } from "react-router-dom";
import styles from "./Conversation.module.scss";
import MessageCard from "./MessageCard";
import UserCard from "../../common/UserCard";
import DetailsIcon from "../../../assets/icons/details.svg";
import JobItem from "./JobItem";
import UserAvatar from "../../common/UserAvatar";
import TextEditor from "./TextEditor";
import { useAppSelector } from "../../../hooks";
import Link from "../../common/Link";
import Button from "../../common/Button";

const Conversation = () => {
  const { applicationId, chatId } = useParams();
  const isBusiness = useAppSelector((state: any) => state.auth.isBusiness);
  const [vacancy, setVacancy] = useState<any>(null);
  const [messages, setMessages] = useState<any>(null);
  const chatContainerRef = useRef<HTMLInputElement>(null);

  const fetchData = async () => {
    if (isBusiness) {
      await getVacancyApplication(applicationId).then(
        async (vacancyResponse) => {
          setVacancy(vacancyResponse?.data);
          await getMessages(chatId).then((messagesResponse) =>
            setMessages(messagesResponse.data.items.reverse()),
          );
        },
      );
    } else {
      await getAppliedApplication(applicationId).then(
        async (vacancyResponse) => {
          setVacancy(vacancyResponse?.data);
          await getMessages(chatId).then((messagesResponse) => {
            setMessages(messagesResponse.data.items?.reverse());
          });
        },
      );
    }
  };

  const handleMessage = (message: any) => {
    setMessages([
      ...messages,
      {
        content: message?.content,
        title: message?.title,
        created_at: "",
        id: messages.length,
        capabilities_meta: {
          is_owner: true,
        },
      },
    ]);

    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <>
      <div className={styles.conversation}>
        <div className={styles.conversation__left}>
          <div className={styles.conversation__messages} ref={chatContainerRef}>
            {messages &&
              messages.map((message: any) => (
                <MessageCard
                  key={message.id}
                  isGray={message?.capabilities_meta?.is_owner}
                  message={message}
                />
              ))}
          </div>
          <div className={styles.conversation__editor}>
            <TextEditor
              fullName={`${
                isBusiness
                  ? `${vacancy?.job_seeker_data?.first_name} ${vacancy?.job_seeker_data?.last_name}`
                  : `${vacancy?.vacancy_data?.title}`
              }`}
              chatId={chatId}
              handleMessage={handleMessage}
            />
          </div>
        </div>
        <div className={styles.conversation__info}>
          <div>
            <div
              className={styles.conversation__details}
              style={{ display: "none" }}
            >
              <img src={DetailsIcon} alt="details" />
            </div>
            <div className={styles.conversation__avatar}>
              <UserCard
                date={vacancy?.created_at}
                shownElements="appliedOnly"
                arrangement="vertically"
              />
            </div>
            {isBusiness ? (
              <ul className={styles.list}>
                <li>
                  Full Name:{" "}
                  {`${vacancy?.job_seeker_data?.first_name}
                ${vacancy?.job_seeker_data?.last_name}`}
                </li>
                <li>E-mail: {vacancy?.job_seeker_data?.email}</li>
                <li>Phone: {vacancy?.job_seeker_data?.phone_number}</li>
                {vacancy?.job_seeker_data?.address && (
                  <li>Address: {vacancy?.job_seeker_data?.address}</li>
                )}
              </ul>
            ) : (
              <ul className={styles.list}>
                <li>
                  <Link to={`/vacancy/${vacancy?.vacancy_data?.id}`}>
                    <Button variant="transparent" className={styles.link}>
                      {vacancy?.vacancy_data?.title}
                    </Button>
                  </Link>
                </li>
              </ul>
            )}
            {!isBusiness && false && (
              <>
                <div className={styles.conversation__jobs}>
                  <span className={styles.conversation__jobs__title}>
                    Applied Jobs
                  </span>
                  <div className={styles.conversation__jobs__inner}>
                    {[1, 2, 3, 4, 5, 6, 7].map((i) => (
                      <JobItem key={i} />
                    ))}
                  </div>
                </div>
                <div className={styles.conversation__hotel}>
                  <UserAvatar size="md" />
                  <span className={styles.conversation__hotel__name}>
                    Hotel Mercure Plaza Hamburg Inn
                  </span>
                </div>
              </>
            )}
          </div>
          {/* <div className={styles.conversation__end}> */}
          {/*   <Link to="/profile/applications">End Conversation</Link> */}
          {/* </div> */}
        </div>
      </div>
      {/* <div className={cs(styles.conversation__end, styles.disN)}> */}
      {/*   <Link to="/profile/applications">End Conversation</Link> */}
      {/* </div> */}
    </>
  );
};

export default Conversation;
