import React, { ButtonHTMLAttributes } from "react";
import cn from "classnames";
import styles from "./Button.module.scss";
import Icon from "../Icon";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?:
    | "primary"
    | "secondary"
    | "warning"
    | "dark"
    | "light"
    | "transparent"
    | "success";
  submit?: boolean;
  size?: "lg" | undefined;
  block?: boolean;
  outline?: boolean;
  icon?: React.ReactNode;
  iconSize?: number;
  disabled?: boolean;
  className?: string;
};

const defaultProps: Partial<ButtonProps> = {
  variant: undefined,
  submit: false,
  size: undefined,
  block: false,
  outline: false,
  icon: undefined,
  iconSize: 28,
  disabled: false,
  className: undefined,
};

const Button = ({
  children,
  variant = defaultProps.variant,
  submit = defaultProps.submit,
  size = defaultProps.size,
  block = defaultProps.block,
  outline = defaultProps.outline,
  icon = defaultProps.icon,
  iconSize = defaultProps.iconSize,
  disabled = defaultProps.disabled,
  className = defaultProps.className,
  onClick,
}: ButtonProps) => {
  const classNames = cn(styles.root, className, {
    [variant !== undefined ? styles[variant] : ""]: variant,
    [size !== undefined ? styles[size] : ""]: size,
    [styles.block]: block,
    [styles.outline]: outline,
  });

  return (
    <button
      type={submit ? "submit" : "button"}
      className={classNames}
      disabled={disabled}
      onClick={onClick}
    >
      {icon && (
        <Icon height={iconSize} width={iconSize}>
          {icon}
        </Icon>
      )}
      {children}
    </button>
  );
};

Button.defaultProps = defaultProps;

export default Button;
