import React from "react";
import styles from "./ForgotPasswordModal.module.scss";
import Modal from "../../common/Modal";
import ForgotPasswordCard from "../../cards/ForgotPasswordCard/ForgotPasswordCard";
import { useAppDispatch } from "../../../hooks";
import { closeModals } from "../../../store/slices/uiSlice";
import { setAuthError } from "../../../store/slices/authSlice";

export type Props = {
  visible: boolean;
};

const ForgotPasswordModal: React.FC<Props> = ({ visible }) => {
  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(setAuthError(null));
    dispatch(closeModals());
  };

  return (
    <Modal className={styles.root} visible={visible} onClose={() => onClose()}>
      <ForgotPasswordCard />
    </Modal>
  );
};

export default ForgotPasswordModal;
