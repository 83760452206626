import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import styles from "./HeaderRight.module.scss";
import HeaderAccount from "../HeaderAccount";
// import HeaderListing from "../HeaderListing";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import Button from "../../common/Button";
import BackdropDropdown from "../../common/BackdropDropdown";
import Link from "../../common/Link";
import { authOut } from "../../../store/slices/authSlice";
import UserIcon from "../../../assets/icons/user-red.svg";
import SignOutIcon from "../../../assets/icons/sign-out.svg";

type Props = {
  isBusiness: boolean;
  user: any;
};

const HeaderRight: React.FC<Props> = ({ isBusiness, user }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [visible, setVisible] = useState<boolean>(false);
  const isAuthenticated = useAppSelector(
    (state: any) => state.auth.isAuthenticated,
  );

  const onClickButton = () => {
    setVisible(!visible);
  };

  const onClickSignOut = () => {
    dispatch(authOut());
    setVisible(!visible);
    localStorage.removeItem("token");
    localStorage.removeItem("isBusiness");
    localStorage.removeItem("user");
  };

  useEffect(() => {
    setVisible(false);
  }, [location]);

  return (
    <div className={styles.root}>
      {/* <div className={styles.language}> */}
      {/*   <HeaderLanguage isBusiness={isBusiness} /> */}
      {/* </div> */}
      {isAuthenticated && (
        <BackdropDropdown
          visible={visible}
          bodyClassName={styles.dropdown}
          button={
            <Button
              variant="transparent"
              iconSize={20}
              className={cn(styles.button, { [styles.dark]: isBusiness })}
              onClick={onClickButton}
            >
              {t("hello")}, {user?.firstName}
            </Button>
          }
          onClose={setVisible}
        >
          <ul className={styles.list}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
            <li>
              <Link to="/profile" onClick={onClickButton}>
                <img src={UserIcon} alt="user" />
                <span>Profil</span>
              </Link>
            </li>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
            <li onClick={onClickSignOut}>
              <img src={SignOutIcon} alt="sign out" />
              <span>Ausloggen</span>
            </li>
          </ul>
        </BackdropDropdown>
      )}
      {!isAuthenticated && <HeaderAccount />}
    </div>
  );
};

export default HeaderRight;
