import FaqContent from "components/faq/FaqContent";
import StudentsLandingCover from "components/sections/StudentsLanding/StudentsLandingCover/StudentsLandingSection";
import React, { useEffect, useState } from "react";
import JobReel from "../components/jobs/JobReel";
import { useAppSelector } from "../hooks";
import { VacanciesDataItemType } from "../types/job-seeker/vacancies.type";
import { getJobSeekerVacancies } from "../api/job-seeker/vacancies";

function StudentsLandingPage() {
  const isAuthenticated = useAppSelector(
    (state: any) => state.auth.isAuthenticated,
  );
  const [vacancies, setVacancies] = useState<VacanciesDataItemType[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getJobSeekerVacancies();
        setVacancies(response?.data?.items?.slice(0, 4) || []);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [isAuthenticated]);

  return (
    <>
      <StudentsLandingCover />
      <JobReel items={vacancies} />
      <FaqContent />
    </>
  );
}

export default StudentsLandingPage;
